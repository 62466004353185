
const REGEX = {
    ANY_NOT_EMPTY: {
        strict: /.+/
    },
    PHONE_NUMBER: {
        onWrite: /^[+]?[0-9]{0,20}$/,
        strict: /^[+]?[0-9]{9,20}$/
    },
    MAIL: {
        onWrite: /^[a-z0-9@!#$%&'*+/=.?^_`{|}~-]{0,320}$/,
        /* Regex copy from https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression adapted by @Damien FLores*/
        strict: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

    }
};

export default REGEX;