import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "Redux/poolSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import {
    ActionBox,
    Header,
    Modal,
    ModalContent,
} from "components/Reusable/Modals/ModalParts";
import LoadSpinner from "components/Reusable/LoadSpinner/LoadSpinner";
import {
    Autocomplete,
    Box, Button, Chip, CircularProgress, createFilterOptions,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import ApiSyncManager from "utilities/ApiSyncManager";
import Normalize from "utilities/Normalize";
import REGEX from "utilities/regex";

import styles from './Participant.module.scss';

/**
 * ADD USER FORM
 */
export default function AddParticipantForm({dismissModal, addAtPosition}) {
    const dispatch = useDispatch();
    const { report_details } = useSelector(state => state.pool.reportData);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const handleAddButtonClick = async () => {
        if(selectedRole && selectedUser) {
            try {
                const newParticipant = await ApiSyncManager.fetchJson(`/report/${report_details.report_id}/participant/${selectedUser.id}`, {
                    method: 'POST',
                    body: {
                        roleId: selectedRole.id,
                        displayOrder: addAtPosition,
                        enterpriseId: selectedUser.enterprises.length > 0 ? selectedUser.enterprises[0].id : null
                    }
                })
                dispatch(actions.addInReportParticipantList({object: newParticipant}));
            }
            catch (error) {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Échec de la création',
                    message: 'L\'utilisateur n\'a pas pu être ajouté',
                    log: error.stack.toString()
                }));
            }
            finally {
                dismissModal();
            }
        }
        else {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Formulaire incomplet',
                message: 'Vous devez saisir la totalité des champs du formulaire',
                log: ''
            }));
        }
    }

    return (
        <Modal>
            <Header title={'Ajouter un participant à la réunion'}/>
            <ModalContent>
                <div style={{flexGrow: 1}}>
                    {selectedRole
                        ? <RoleResume selectedRole={selectedRole} setSelectedRole={setSelectedRole}/>
                        : <RoleSelectInput setSelectedRole={setSelectedRole} dismissModal={dismissModal}/>
                    }

                    {!selectedUser && <UserSelectInput setSelectedUser={setSelectedUser} dismissModal={dismissModal}/>}
                    {selectedUser && selectedUser.inputValue && <UserForm setCreatedUser={setSelectedUser} dismissModal={() => setSelectedUser(null)}/>}
                    {selectedUser && selectedUser.id && <UserResume selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
                </div>
            </ModalContent>
            <ActionBox>
                <Button variant="outlined" color="secondary" onClick={dismissModal} sx={{width: 120}}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" sx={{width: 120, fontWeight: 'bold'}} onClick={handleAddButtonClick} >
                    Ajouter
                </Button>
            </ActionBox>
        </Modal>
    )
}

function RoleSelectInput({setSelectedRole, dismissModal}) {
    const dispatch = useDispatch();
    const [localValue, setLocalValue] = useState('');
    const [wait, setWait] = useState(true);
    const [roleList, setRoleList] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(() => {
        ApiSyncManager.fetchJson('/participantRole/list', {method: 'GET'})
            .then(response => {
                const sorted = response.sort((a, b) => a.name.localeCompare(b.name));
                setRoleList(sorted);
                setWait(false);
            })
            .catch(error => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Impossible d\'ajouter un participant',
                    message: 'La liste des roles n\'a pas pu être récupéré, veuillez réessayer',
                    log: error.stack.toString()
                }));
                dismissModal();
            });
    }, []);

    const handleOnChange = async (event, newValue) => {
        console.log(newValue)
        if(newValue === null) {
            setError(true);
            setLocalValue(newValue);
            setSelectedRole(newValue);
        }
        else  if(newValue && newValue.inputValue) {
            setError(false);
            setWait(true);
            try {
                const newRole = await ApiSyncManager.fetchJson('/participantRole/create', {
                    method: 'POST',
                    body: {name: newValue.inputValue}
                });
                setLocalValue(newRole);
                setSelectedRole(newRole);
                setWait(false);
            }
            catch (error) {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.WARNING,
                    title: `La création de ${newValue.inputValue} à échoué`,
                    message: '',
                    log: error.stack.toString()
                }));
            }
        }
        else {
            setError(false);
            setLocalValue(newValue);
            setSelectedRole(newValue);
        }
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => Normalize.string(inputValue) === Normalize.string(option.name));
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                name: `Créer "${inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.name;
    }

    const renderOption = (props, option) => <li {...props}>{option.name}</li>

    const renderTags = (value, getTagProps) => (
        value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} /> )
    );

    const renderInput = (params) => (
        <TextField
            {...params}
            onBlur={() => localValue === '' && setError(true)}
            onFocus={() => setError(false)}
            label="Fonction du participant"
            error={isError}
            helperText={isError && 'Vous devez associer une fonction au participant'}
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                    <>
                        <InputAdornment position="start">
                            <div className={`material-icons`}>groups</div>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                    </>
                ),
                endAdornment: (
                    <>
                        {wait && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                    </>
                ),
            }}
        />
    );

    return (
        <Autocomplete
            freeSolo
            value={localValue}
            onChange={handleOnChange}
            sx={{ m: 1 }}
            disabled={wait}
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="role"
            options={roleList || []}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderTags={renderTags}
            renderInput={renderInput}
        />
    )
}

function UserSelectInput({setSelectedUser, dismissModal}) {
    const dispatch = useDispatch();
    const [localValue, setLocalValue] = useState('');
    const [wait, setWait] = useState(true);
    const [userList, setUserList] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(() => {
        ApiSyncManager.fetchJson('/participant/list', {method: 'GET'})
            .then(response => {
                const sorted = response.sort((a, b) =>
                    a.lastname.localeCompare(b.lastname)
                    || a.firstname.localeCompare(b.firstname)
                );
                setUserList(sorted);
                setWait(false);
            })
            .catch(error => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Impossible d\'ajouter un participant',
                    message: 'La liste des participant n\'a pas pu être récupéré, veuillez réessayer',
                    log: error.stack.toString()
                }));
                dismissModal();
            });
    }, []);

    const handleOnChange = async (event, newValue) => {
        if(newValue === null) {
            setError(true);
            setLocalValue(newValue);
            setSelectedUser(newValue);
        }
        else {
            setError(false);
            setLocalValue(newValue);
            setSelectedUser(newValue);
        }
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => Normalize.string(inputValue) === Normalize.string(option.lastname));
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                lastname: `+ Créer un nouvel utilisateur`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return `${option.id} ${option.lastname} ${option.firstname} ${option.mail} ${option.phone}`;
    }

    const renderOption = (props, option) => <li {...props}>{option.lastname.toUpperCase()} {option.firstname}</li>;

    const renderInput = (params) => (
        <TextField
            {...params}
            onBlur={() => localValue === '' && setError(true)}
            onFocus={() => setError(false)}
            label="Participant"
            error={isError}
            helperText={isError && 'Vous devez choisir un participant'}
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                    <>
                        <InputAdornment position="start">
                            <div className={`material-icons`}>person_add</div>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                    </>
                ),
                endAdornment: (
                    <>
                        {wait && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                    </>
                ),
            }}
        />
    );

    return (
        <Autocomplete
            freeSolo
            value={localValue}
            onChange={handleOnChange}
            sx={{ m: 1 }}
            disabled={wait}
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="user"
            options={userList || []}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderInput={renderInput}
        />
    )
}

function UserResume({selectedUser, setSelectedUser}) {
    const cancelUserSelect = () => {
        setSelectedUser(null);
    }
    return(
        <div style={{display: "flex", justifyContent: "stretch", alignItems: "center", gap: "20px", paddingLeft: 50}}>
            <div className={styles.UserDetailsBox}>
                <div className={styles.UserName}>
                    <span>{selectedUser.firstname}</span>
                    <span className={styles.Lastname}>{selectedUser.lastname ? selectedUser.lastname.toUpperCase() : ''}</span>
                </div>
            </div>
            <div style={{flexGrow: 1}}>
                <UserDetail value={selectedUser.phone} iconName='phone'/>
                <UserDetail value={selectedUser.mail} iconName='mail'/>
            </div>
            <div className={styles.ActionBar}>
                <div className={`material-icons ${styles.ButtonIcon}`} onClick={cancelUserSelect}>close</div>
            </div>
        </div>
    )
}

function UserDetail({value, iconName}) {
    return value && (
        <div className={styles.UserDetail}>
            <div className={`material-icons ${styles.Icon}`}>{iconName}</div>
            <div className={styles.Value}>{value}</div>
        </div>
    )
}

function RoleResume({selectedRole, setSelectedRole}) {
    const cancelUserSelect = () => {
        setSelectedRole(null);
    };

    return (
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px"}}>
            <div className='Flex'>
                {selectedRole.id === 'new' && <LoadSpinner size={36}/>}
                <div className={styles.RoleBox}>
                    <span className={styles.RoleName}>{selectedRole.name}</span>
                </div>
            </div>
            <div className={styles.ActionBar}>
                <div className={`material-icons ${styles.ButtonIcon}`} onClick={cancelUserSelect}>close</div>
            </div>
        </div>
    )
}

/**
 * CREATE USER FORM
 */
const genderOptions = [
    {label: 'M', id: 'male'},
    {label: 'Mme', id: 'female'}
]
const validateField = (field, regex) => {
    if (regex.test(field.value)) {
        return true;
    }
    else {
        field.focus();
        return false;
    }
}
const filter = createFilterOptions();

export function UserForm({setCreatedUser, dismissModal, updateUser}) {
    const dispatch = useDispatch();
    const [affectedEnterprise, setAffectedEnterprises] = useState(updateUser ? updateUser.enterprises : null);
    const thisForm = useRef();

    const createNewUser = async () => {
        try {
            const gender = thisForm.current.gender;
            const lastname = thisForm.current.lastname;
            const firstname = thisForm.current.firstname;
            const mail = thisForm.current.mail;
            const phone = thisForm.current.phone;
            const newUser = await ApiSyncManager.fetchJson('/participant/', {
                method: 'POST',
                body: {
                    gender: gender.value === '' ? null : genderOptions.find(option => option.label === gender.value).id,
                    firstname: firstname.value,
                    lastname: lastname.value,
                    mail: mail.value,
                    phone: phone.value === '' ? null : phone.value,
                    enterprises: affectedEnterprise
                }
            });
            setCreatedUser ? setCreatedUser(newUser) : dismissModal();
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'La création de l\'utilisateur a échoué',
                message: '',
                log: ''
            }));
        }
    }

    const updateExistingUser = async () => {
        try {
            const gender = thisForm.current.gender;
            const lastname = thisForm.current.lastname;
            const firstname = thisForm.current.firstname;
            const mail = thisForm.current.mail;
            const phone = thisForm.current.phone;
            const updatedUser = await ApiSyncManager.fetchJson('/participant/', {
                method: 'PUT',
                body: {
                    id: updateUser.participant_id,
                    gender: gender.value === '' ? null : genderOptions.find(option => option.label === gender.value).id,
                    firstname: firstname.value,
                    lastname: lastname.value,
                    mail: mail.value,
                    phone: phone.value === '' ? null : phone.value,
                    enterprises: affectedEnterprise
                }
            });
            setCreatedUser ? setCreatedUser(updatedUser) : dismissModal();
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'La misa à jour de l\'utilisateur a échoué',
                message: error.toString(),
                log: ''
            }));
        }
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        const gender = thisForm.current.gender;
        const lastname = thisForm.current.lastname;
        const firstname = thisForm.current.firstname;
        const mail = thisForm.current.mail;
        const phone = thisForm.current.phone;

        if( !validateField(lastname, REGEX.ANY_NOT_EMPTY.strict) ) return;
        if( !validateField(firstname, REGEX.ANY_NOT_EMPTY.strict) ) return;
        if( !validateField(mail, REGEX.MAIL.strict) ) return;
        if( phone.value !== '' && !validateField(phone, REGEX.PHONE_NUMBER.strict)) return;
        if( gender.value !== '' && !genderOptions.some(option => option.label === gender.value)) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Civilité invalide',
                message: 'Seule Mr et Mme sont accepté',
                log: ''
            }));
            return;
        }
        if( !affectedEnterprise || affectedEnterprise.length === 0) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Entreprise requise',
                message: 'Vous devez affecter au moin 1 entreprise à l\'utilisateur',
                log: ''
            }));
            return;
        }

        updateUser ? await updateExistingUser() : await createNewUser();
    }

    return (
            <Modal>
                <form ref={thisForm} onSubmit={handleOnSubmit}>
                    <Header title={updateUser ? 'Mise à jour d\'un participant' : 'Création d\'un participant'}/>
                    <ModalContent>
                        <Stack>
                            <Typography sx={{ mx: 1, fontSize: '1.2rem', fontWeight: 'bold' }} color='secondary'>
                                Identité
                            </Typography>
                            <Stack direction='row'>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={updateUser && updateUser.gender ? genderOptions.find(o => updateUser.gender === o.id) : null}
                                    id="gender"
                                    options={genderOptions}
                                    sx={{ m: 1, width: 120 }}
                                    renderInput={(params) =>
                                        <TextField {...params} name='gender' label="Civilité"/>
                                }
                                />
                                <NameInput id="lastname" name="lastname" label="Nom" initialValue={updateUser ? updateUser.lastname : null}/>
                                <NameInput id="firstname" name="firstname" label="Prénom" initialValue={updateUser ? updateUser.firstname : null}/>
                            </Stack>
                            <Typography sx={{ mx: 1, fontSize: '1.2rem', fontWeight: 'bold' }} color='secondary'>
                                Contact
                            </Typography>

                            <MailInput initialValue={updateUser ? updateUser.mail : null} />
                            <PhoneNumberInput initialValue={updateUser ? updateUser.phone : null}/>
                            <Typography sx={{ mx: 1, fontSize: '1.2rem', fontWeight: 'bold' }} color='secondary'>
                                Entreprise(s)
                            </Typography>
                            <EnterpriseSelectInput
                                setAffectedEnterprises={setAffectedEnterprises}
                                dismissModal={dismissModal}
                                initialValue={updateUser ? updateUser.enterprises : null}
                            />
                        </Stack>
                    </ModalContent>
                    <ActionBox>
                        <Button variant="outlined" color="secondary" onClick={dismissModal} sx={{width: 120}}>
                            Annuler
                        </Button>
                        <Button type='submit' variant="contained" color="primary" sx={{width: 120, fontWeight: 'bold'}} >
                            {updateUser ? 'Enregistrer' : 'Créer'}
                        </Button>
                    </ActionBox>
                </form>
            </Modal>

    )
}

function NameInput({initialValue, ...props}) {
    const [localValue, setLocalValue] = useState(initialValue || '');
    const [isError, setError] = useState(false);

    const handleOnChange = (event) => {
        setLocalValue(event.target.value);
        if(event.target.value !== '') {
            setError(false);
        }
        else {
            setError(true);
        }
    }

    return (
        <TextField
            required
            value={localValue}
            onChange={handleOnChange}
            onBlur={() => localValue === '' && setError(true)}
            sx={{ m: 1 }}
            error={isError}
            helperText={isError && 'Ce champ est obligatoire'}
            {...props}
        />
    )
}

function PhoneNumberInput({initialValue}) {
    const [localValue, setLocalValue] = useState(initialValue || '');
    const [isError, setError] = useState(false);
    const [isValid, setValid] = useState(false);
    const [helperText, setHelperText] = useState('');

    const handleOnChange = (event) => {
        let nValue = event.target.value;
        nValue = nValue.replace(' ', '');
        if(REGEX.PHONE_NUMBER.onWrite.test(nValue)) {
            setLocalValue(nValue);
            setError(false);
            setHelperText('');
        }
        else {
            setError(true);
            if(nValue.length > 20) {
                setHelperText('Le numéro de téléphone ne peut pas être plus long')
            }
            else {
                setHelperText('Le numéro de téléphone peut seulement contenir des chiffres (0-9) et commencer par un +.')
            }
        }
    }

    const validateInput = () => {
        if (REGEX.PHONE_NUMBER.strict.test(localValue)) {
            setValid(true);
        }
        else if (localValue !== ''){
            setError(true);
            setHelperText('Le numéro saisie n\'est pas valide');
        }
    }

    return (
        <TextField
            value={localValue}
            onChange={handleOnChange}
            sx={{ m: 1 }}
            error={isError}
            helperText={helperText}
            id="phone"
            name="phone"
            label="Téléphone"
            onBlur={validateInput}
            onFocus={() => setValid(false)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <div className={`material-icons`}>phone</div>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="start">
                        {isValid && <Box className={`material-icons`} sx={{color: 'success.main'}}>check</Box>}
                    </InputAdornment>
                )
            }}
        />
    )
}

function MailInput({initialValue}) {
    const [localValue, setLocalValue] = useState(initialValue || '');
    const [isError, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [isValid, setValid] = useState(false)

    const handleOnChange = (event) => {
        let nValue = event.target.value;
        nValue = nValue.replace(' ', '');
        nValue = nValue.toLowerCase();

        if (REGEX.MAIL.onWrite.test(nValue)) {
            setLocalValue(nValue);
            setError(false);
            setHelperText('');
        }
        else if(nValue.length > 320) {
            setError(true);
            setHelperText('L\'adresse mail est trop longue');
        }
        else {
            setError(true);
            setHelperText('Seul les lettres ( a-z ), les chiffres ( 0-9 ) et les caractères spéciaux ( @!#$%&\'*+/=?^_`{|}~- ) sont acceptés');
        }
    }

    const validateInput = () => {
        if (localValue === '') {
            setError(true);
            setHelperText('Ce champ ne peut être vide')
        }
        else if (REGEX.MAIL.strict.test(localValue)) {
            setValid(true);
        }
        else {
            setError(true);
            setHelperText('L\'adresse mail est invalide')
        }
    }

    return (
        <TextField
            required
            id="mail"
            name="mail"
            type='email'
            label="e-mail"
            value={localValue}
            onChange={handleOnChange}
            onBlur={validateInput}
            onFocus={() => setValid(false)}
            error={isError}
            helperText={helperText}
            sx={{ m: 1 }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Box className={`material-icons`}>mail</Box>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="start">
                        {isValid && <Box className={`material-icons`} sx={{color: 'success.main'}}>check</Box>}
                    </InputAdornment>
                )
            }}
        />
    )
}

function EnterpriseSelectInput({setAffectedEnterprises, dismissModal, initialValue}) {
    const dispatch = useDispatch();
    const [localValue, setLocalValue] = useState(initialValue || []);
    const [wait, setWait] = useState(true);
    const [enterpriseList, setEnterpriseList] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(() => {
        ApiSyncManager.fetchJson('/enterprise/list', {method: 'GET'})
            .then(response => {
                const sorted = response.sort((a, b) => a.name.localeCompare(b.name));
                sorted.splice(0, 1); //Remove the " TOUS LOT" exception as it's not an enterprise
                setEnterpriseList(sorted);
                setWait(false);
            })
            .catch(error => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Impossible de créer un utilisateur',
                    message: 'La liste des entreprises n\'a pas pu être récupéré, veuillez réessayer',
                    log: error.stack.toString()
                }));
                dismissModal();
            });
    }, []);

    const handleOnChange = async (event, newValue) => {
        const lastEntry = newValue[newValue.length - 1];
        console.log(lastEntry, event.key)
        if(newValue.length === 0) {
            setError(true);
            setLocalValue(newValue);
            setAffectedEnterprises(newValue);
        }
        else if(lastEntry && (lastEntry.inputValue || event.key)) {
            setError(false);
            setWait(true);
            const newEnterpriseName = event.key ? lastEntry.toUpperCase() : lastEntry.inputValue.toUpperCase();
            try {
                const newEnterprise = await ApiSyncManager.fetchJson('/enterprise/create', {
                    method: 'POST',
                    body: {name: newEnterpriseName}
                });
                newValue.splice(-1, 1, newEnterprise);
                setLocalValue(newValue);
                setAffectedEnterprises(newValue);
                setWait(false);
            }
            catch (error) {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.WARNING,
                    title: `La création de ${newEnterpriseName} à échoué`,
                    message: '',
                    log: error.stack.toString()
                }));
            }
        }
        else {
            setError(false);
            setLocalValue(newValue);
            setAffectedEnterprises(newValue);
        }
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => Normalize.string(inputValue) === Normalize.string(option.name));
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                name: `Créer "${inputValue}"`,
            });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.name;
    }

    const renderOption = (props, option) => <li {...props}>{option.name}</li>

    const renderTags = (value, getTagProps) => (
        value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} /> )
    );

    const renderInput = (params) => (
        <TextField
            {...params}
            onBlur={() => localValue.length === 0 && setError(true)}
            onFocus={() => setError(false)}
            label="Affecter une entreprise"
            error={isError}
            helperText={isError && 'Vous devez associer au moins une entreprise à l\'utilisateur'}
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                    <>
                        <InputAdornment position="start">
                            <div className={`material-icons`}>apartment</div>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                    </>
                ),
                endAdornment: (
                    <>
                        {wait && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                    </>
                ),
            }}
        />
    );

    return (
        <Autocomplete
            freeSolo
            multiple
            value={localValue}
            onChange={handleOnChange}
            sx={{ m: 1 }}
            disabled={wait}
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="enterprises"
            options={enterpriseList || []}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderTags={renderTags}
            renderInput={renderInput}
        />
    )
}