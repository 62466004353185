import {useMemo, useState} from "react";
import useToggle from "customHook/useToggle";
import {useDispatch, useSelector} from "react-redux";
import {actions, getElement} from "Redux/poolSlice";

import TaskForms, {FORM_STEP} from "./TaskForms";
import ConfirmModal from "components/Reusable/Modals/ConfirmModal";
import InputText, {parseText} from "feature/InputText/InputText";
import DateExtended, {DATE_MODE} from "utilities/DateExtended";
import ApiSyncManager from "utilities/ApiSyncManager";
import areSameObject from "utilities/areSameObject";

import {Button, Stack, Typography} from "@mui/material";
import {Add, AddCircle, DomainAddRounded, ExpandMoreRounded, PlaylistAdd} from "@mui/icons-material";

import styles from './Modules.module.scss';

/**
 * Display task component with all values
 * @param path {Array<String|Number>} Tasks path inside Redux pool reportData
 */
export default function Task({ path }) {
    const {fullView} = useSelector(state => state.page.data);
    const [selectedEnterprise, setSelectedEnterprise] = useState({index: null, id: null, name: null});
    const enterpriseList = useSelector(state => getElement(state.pool.reportData, path));
    const sortedEnterprise = useMemo(
        () => [...enterpriseList].sort((a, b) =>  a.enterprise_name.localeCompare(b.enterprise_name)),
        [enterpriseList]);
    const data = useMemo(
        () => enterpriseList.find(ets => ets.enterprise_id === selectedEnterprise.id),
        [enterpriseList, selectedEnterprise]);

    const handleOnEnterpriseClick = (enterprise_id, enterprise_name) => {
        const enterpriseIndex = enterpriseList.findIndex(enterprise => enterprise.enterprise_id === enterprise_id);
        setSelectedEnterprise({index: enterpriseIndex, id: enterprise_id, name: enterprise_name});
    };

    return (
        fullView
            ? (
                <Stack gap={2}>
                    <Stack>
                        {sortedEnterprise.map(({enterprise_name, enterprise_id}) => (
                            <>
                                <Stack my={2} flexDirection={'row'} justifyContent={'flex-start'} gap={3} flexWrap={'wrap'} key={enterprise_name}>
                                    <EnterpriseTitle key={enterprise_id} name={enterprise_name}/>
                                    <AddButton icon={<DomainAddRounded />} path={path} name='Ajouter une Entreprise' formStep={FORM_STEP.ADD_ENTERPRISE}/>
                                </Stack>
                                <FullViewCategoryList path={path} enterprise_name={enterprise_name} enterprise_id={enterprise_id}/>
                            </>
                        ))}
                    </Stack>
                    <AddButton icon={<DomainAddRounded />} path={path} name='Ajouter une Entreprise' formStep={FORM_STEP.ADD_ENTERPRISE}/>
                </Stack>
            )


            :
                <Stack gap={2} alignItems={'start'}>
                    <div className={styles.TaskEnterpriseBox}>
                        {sortedEnterprise.map(({enterprise_name, enterprise_id}) =>
                            <EnterpriseTitle
                                key={enterprise_id}
                                name={enterprise_name}
                                selected={selectedEnterprise.id === enterprise_id}
                                onClick={() => handleOnEnterpriseClick(enterprise_id, enterprise_name)}
                            />
                        )}
                    </div>
                    <AddButton icon={<DomainAddRounded />} path={path} name='Ajouter une Entreprise' formStep={FORM_STEP.ADD_ENTERPRISE}/>
                    {data && <CategoryList path={path} enterprise={selectedEnterprise} />}
                </Stack>
    )
}

/**
 * Show the enterprise name
 * @param name {String} Enterprise Name
 * @param selected {Boolean} true if this enterprise is the selected one
 * @param onClick {Function} Function prototype to handle on enterprise selection
 */
function EnterpriseTitle({ name, selected, onClick }) {
    const { fullView } = useSelector(state => state.page.data);

    return (
        <Stack alignItems={'center'} justifyContent={'center'} className={styles.TaskEnterprise} onClick={onClick}>
            <Typography fontWeight={selected && 'bold'} color={selected ? 'primary.main' : 'inherit'}>{name ? name.toUpperCase() : ''}</Typography>
            {!fullView && <ExpandMoreRounded sx={{color: selected ? 'primary.main' : 'transparent'}} />}
        </Stack>
    )
}

/**
 * Build Tasks block and categories
 * @param path {Array<String|Number>} Category list path inside Redux pool reportData
 * @param enterprise {{index: {Number}, id: {Number}, name: {String}}} Selected enterprise values used to build tasks
 */
function CategoryList({ path, enterprise }) {
    const categories = useSelector(state => getElement(state.pool.reportData, [...path, enterprise.index, 'categories']));
    const sortedCategories = useMemo(() =>
        [...categories].sort((a, b) => (a.category_name === null) ? -1 : (a.category_name.localeCompare(b.category_name))),
        [categories]
    );

    const getCategoryIndex = (category_id) => categories.findIndex(enterprise => enterprise.category_id === category_id);

    return (
        <div className={styles.Table}>
            {sortedCategories.map(({category_name, category_id}) =>
                <Category
                    key={category_id}
                    enterprise={enterprise}
                    id={category_id}
                    name={category_name}
                    path={[...path, enterprise.index, 'categories', getCategoryIndex(category_id)]} />
            )}

            <AddButton
                icon={<PlaylistAdd />}
                path={path}
                name='Ajouter une Catégorie'
                formStep={FORM_STEP.ADD_CATEGORY}
                initialData={{
                    enterprise: {
                        id: enterprise.id,
                        name: enterprise.name
                    }
                }}
            />
        </div>
    )
}

/**
 * Build Tasks block and categories on full view
 * @param path {Array<String|Number>} Category list path inside Redux pool reportData
 * @param enterprise_name {String} Selected of name enterprise used to build tasks
 * @param enterprise_id {Number} Selected of id enterprise used to build tasks
 */
function FullViewCategoryList({ path, enterprise_name, enterprise_id }) {
    const enterpriseList = useSelector(state => getElement(state.pool.reportData, path));
    const enterprise = useMemo(() => {
        const enterpriseIndex = enterpriseList.findIndex(enterprise => enterprise.enterprise_id === enterprise_id);
        return {index: enterpriseIndex, id: enterprise_id, name: enterprise_name};
    }, []);
    const categories = useSelector(state => getElement(state.pool.reportData, [...path, enterprise.index, 'categories']));
    const sortedCategories = useMemo(() =>
            [...categories].sort((a, b) => (a.category_name === null) ? -1 : (a.category_name.localeCompare(b.category_name))),
        [categories]
    );
    const getCategoryIndex = (category_id) => categories.findIndex(enterprise => enterprise.category_id === category_id);

    return (
        <div className={styles.Table}>
            {sortedCategories.map(({category_name, category_id}) =>
                <Category
                    key={category_id}
                    enterprise={enterprise}
                    id={category_id}
                    name={category_name}
                    path={[...path, enterprise.index, 'categories', getCategoryIndex(category_id)]}
                />
            )}
            <AddButton
                icon={<PlaylistAdd />}
                path={path}
                name='Ajouter une Catégorie'
                formStep={FORM_STEP.ADD_CATEGORY}
                initialData={{
                    enterprise: {
                        id: enterprise.id,
                        name: enterprise.name
                    }
                }}
            />
        </div>
    );
}

/**
 * Build a specific category content
 * @param id {Number} Category id
 * @param name {String} Category Name
 * @param enterprise {{index: {Number}, id: {Number}, name: {String}}} Selected enterprise values used to build tasks
 * @param path {Array<String|Number>} Category path inside Redux pool reportData
 */
function Category({ id, name, enterprise, path }) {
    const {report_is_closed, report_is_published} = useSelector(state => state.pool.reportData.report_details);
    const taskList = useSelector(state => getElement(state.pool.reportData, [...path, 'tasks']));
    const sortedTask = useMemo(() => [...taskList].sort((a, b) => DateExtended.sortAscendant(a.created_at, b.created_at)), [taskList]);
    const formInitialData = useMemo(() => {
        return {
            container_id: path[1],
            enterprise: {
                id: enterprise.id,
                name: enterprise.name
            },
            category: {
                id: id,
                name: name
            }
        };
    }, [enterprise, id, name]);
    const getTaskIndex = task_id => taskList.findIndex(task => task.id === task_id);

    return (
        <div className={styles.TaskCategory}>
            <Stack flexDirection={'row'} justifyContent={'start'} alignItems={'center'} gap={2} className={styles.TaskCategoryHead}>
                {name && <Typography fontWeight={'bold'} color={'primary'}>{name.toUpperCase()}</Typography>}
                <AddButton
                    icon={<Add />}
                    path={path}
                    name='Ajouter une Consigne'
                    formStep={FORM_STEP.ADD_TASK}
                    initialData={formInitialData}
                />
            </Stack>
            <table>
                <thead>
                    <tr>
                        {(report_is_closed && !report_is_published) && <th>n°</th>}
                        <th className={styles.DateWidth}>DATE</th>
                        <th>CONSIGNE</th>
                        <th className={styles.DateWidth}>OBJECTIF</th>
                        <th className={styles.DateWidth}>RÉALISÉ</th>
                        <th className={styles.CommentWidth}>REMARQUES</th>
                        <ActionColumnHead />
                    </tr>
                </thead>
                <tbody>
                    {sortedTask.map((task, index) =>
                        <TaskRow
                            taskIndex={index}
                            key={task.id}
                            path={[...path, 'tasks' ,getTaskIndex(task.id)]}
                            formInitialData={formInitialData}
                        />
                    )}
                </tbody>
            </table>
            <AddButton
                icon={<AddCircle />}
                path={path}
                formStep={FORM_STEP.ADD_TASK}
                initialData={formInitialData}
            />
        </div>
    );
}

/**
 * Build a specific task
 * @param taskIndex {Number} Task index
 * @param path {Array<String|Number>} Task path inside Redux pool reportData
 * @param formInitialData {
 *             container_id: {Number},
 *             enterprise: {
 *                 id: {Number},
 *                 name: {String}
 *             },
 *             category : {
 *                 id: {Number},
 *                 name: {String}
 *             }}; Related task information required by form to update or add task
 */
function TaskRow({ taskIndex, path, formInitialData }) {
    const {report_is_closed, report_is_published} = useSelector(state => state.pool.reportData.report_details);
    const task = useSelector(state => {
        const task = getElement(state.pool.reportData, path);
        return {
            ...task,
            isNew: task.parent === null,
            textHasChanged: task.parent === null || !areSameObject(task.parent.text, task.text),
            dueDateHasChanged: task.parent === null || (task.parent.due_date !== task.due_date),
            completedAtHasChanged: task.parent === null || (task.parent.completed_at !== task.completed_at),
            commentHasChanged: task.parent === null || !areSameObject(task.parent.comment, task.comment),
        }
    });
    const text = useMemo(() => <InputText
        style={{wordBreak: 'break-word'}}
        inputStyle={{padding: '0px'}}
        textValue={task.text}
        textValueHasChanged={task.textHasChanged}
        withTags
    />, [task.text, task.textHasChanged])

    return (
        <tr className={task.is_critical ? 'isCritical' : ''}>
            {(report_is_closed && !report_is_published) &&
                <td style={{fontWeight: 'bold'}} >{taskIndex + 1}</td>
            }
            <td className={`${task.isNew ? 'hasChanged' : ''}`}>
                {DateExtended.displayDate(task.created_at, DATE_MODE.DAY)}
            </td>
            <td>
                {text}
            </td>
            <td className={`${task.dueDateHasChanged ? 'hasChanged' : ''}`}>
                {DateExtended.displayDate(task.due_date, task.due_date_type)}
            </td>
            <td className={`${task.completedAtHasChanged ? 'hasChanged' : ''}`}>
                {DateExtended.displayDate(task.completed_at, DATE_MODE.DAY)}
            </td>
            <td>
                <InputText
                    style={{wordBreak: 'break-word'}}
                    inputStyle={{padding: '0px'}}
                    textValue={task.comment}
                    textValueHasChanged={task.commentHasChanged}
                    withTags
                />
            </td>
            <ActionColumn formInitialData={{...formInitialData, task: task}} task={task} path={path}/>
        </tr>
    )
}

/**
 * Generic Add button for task component
 * @param name {String} Button name
 * @param formStep {Number} the form step to load on click
 * @param path {Array<String|Number>} The path inside Redux pool reportData where created data will be added
 * @param initialData {{
 *     enterprise: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     category: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     task: {
 *         id: {Number},
 *         created_at: {String},
 *         text: {String},
 *         due_date: {String},
 *         due_date_type: {DATE_MODE},
 *         completed_at: {String},
 *         comment: {String}
 *     }
 * }} related enterprise, category and task information required by form to perform required step
 * @param icon {SvgIconComponent} The Icon for add button
 */
function AddButton({ name, formStep, path, initialData, icon }) {
    const isEditing = useSelector(state => state.pool.isEditing);
    const [isFormDisplay, toggle] = useToggle(false);

    return isEditing &&
        <>
            <Button
                variant={'text'}
                color={'secondary'}
                startIcon={icon}
                onClick={toggle}
            >
                {name}
            </Button>
            {isFormDisplay && <TaskForms path={path} dismissModal={toggle} formStep={formStep} initialData={initialData}/>}
        </>
}

/**
 * Build an empty column head while in edit mode
 */
function ActionColumnHead() {
    const isEditing = useSelector(state => state.pool.isEditing);

    return isEditing &&
        <th></th>
}

/**
 * Builds user available actions on edit mode
 * @param formInitialData {{
 *     enterprise: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     category: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     task: {
 *         id: {Number},
 *         created_at: {String},
 *         text: {String},
 *         due_date: {String},
 *         due_date_type: {DATE_MODE},
 *         completed_at: {String},
 *         comment: {String}
 *     }
 * }}
 * @param task {{
 *     id: {Number},
 *     text: {String},
 *     is_critical: {Boolean}
 * }} task details
 * @param path {Array<String|Number>} Task path inside Redux pool reportData
 */
function ActionColumn({ formInitialData, task, path }) {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const [isFormDisplay, toggleForm] = useToggle(false);
    const [isConfirmDeleteDisplay, toggleConfirmDelete] = useToggle(false);

    const setCritical = async () => {
        const action = task.is_critical ? 'removeCritical' : 'setCritical';
        try {
            await ApiSyncManager.fetchJson(`/task/${action}/${task.id}`, {method: 'PUT'});
            dispatch(actions.updateReportData({
                path: path,
                component: 'task',
                updates: [
                    {key: 'id', value: task.id},
                    {key: 'is_critical', value: !task.is_critical}
                ]
            }));
        }
        catch (error) {
            window.alert(error);
        }
    };

    const deleteTask = () => {
        const cpPath = [...path];
        dispatch(actions.deleteFromReportData({
            deleteIndex: cpPath.splice(-1),
            path: cpPath,
            component: 'task'
        }));
        toggleConfirmDelete();
    };

    return isEditing &&
        <td className={styles.ActionsCol}>
            <div className='material-icons' onClick={toggleForm}>edit</div>
            <div className='material-icons' onClick={setCritical}>priority_high</div>
            <div className='material-icons' onClick={toggleConfirmDelete}>delete</div>
            {isFormDisplay &&
                <TaskForms
                    dismissModal={toggleForm}
                    formStep={FORM_STEP.ADD_TASK}
                    initialData={formInitialData}
                />
            }
            {isConfirmDeleteDisplay &&
                <ConfirmModal
                    title={'Confirmer la suppression de la consigne ?'}
                    acceptButtonText={'Supprimer'}
                    onCancelClick={toggleConfirmDelete}
                    onAcceptClick={deleteTask}
                >
                    <h5>
                        <span className='material-icons' style={{transform: 'translateY(-3px)', color: 'var(--primaryValeco)'}}>info</span>
                        <span>Cette action est irreversible:</span>
                    </h5>
                    <p>Êtes-vous sûr de vouloir supprimer la consigne <span className='code'>{parseText(task.text)}</span></p>
                </ConfirmModal>
            }
        </td>
}
