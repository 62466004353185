import {MaxInputYearOffset} from "../config";

export const DATE_MODE = {
    DAY: 0,
    WEEK: 1,
    MONTH: 2,
    QUARTER: 3,
    HALF: 4,
    YEAR: 5
};

/**
 * Extend of Date class
 * Provides additional methods for handling a date
 * Provides static properties to facilitate understanding of date manipulation arithmetic operations
 * Provides static methods facilitating interactions with inputs
 * @extends Date
 * @constructor Use Date constructors
 */
export default class DateExtended extends Date{
    static INVALID_DATE = 'Invalid Date';

    static SECOND = 1000;
    static MINUTE = 60 * this.SECOND;
    static HOUR = 60 * this.MINUTE;
    static DAY = 24 * this.HOUR;

    static   JANUARY = 0;
    static  FEBRUARY = 1;
    static     MARCH = 2;
    static     APRIL = 3;
    static       MAY = 4;
    static      JUNE = 5;
    static      JULY = 6;
    static    AUGUST = 7;
    static SEPTEMBER = 8;
    static   OCTOBER = 9;
    static  NOVEMBER = 10;
    static  DECEMBER = 11;

    static    MONDAY = 1;
    static   TUESDAY = 2;
    static WEDNESDAY = 3;
    static  THURSDAY = 4;
    static    FRIDAY = 5;
    static  SATURDAY = 6;
    static    SUNDAY = 7;

    /**
     * Returns the value of an input type date formatted according to the French standard (DD/MM/YYYY)
     * @param {String} inputValue - The value of the input type date
     * @returns - A string representing the date in French format (DD/MM/AAAA)
     */
    static formatInputDateToFRString(inputValue) {
        const dateParts = inputValue.split('-');
        return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0];
    }

    /**
     * Convert date to requested mode format
     * @param date {DateExtended} the date to convert
     * @param mode {DATE_MODE} the requested display mode
     * @returns {string} The date in the requested mode
     */
    static displayDate(date, mode) {
        if (date) {
            const dateObject = new DateExtended(date);
            const [year, month, day] = date.split('-');

            switch (mode) {
                case DATE_MODE.YEAR:
                    return `${year}`;
                case DATE_MODE.HALF:
                    return `Semestre ${Math.ceil(month/6)} ${year}`;
                case DATE_MODE.QUARTER:
                    return `Trimestre ${Math.ceil(month/3)} ${year}`;
                case DATE_MODE.MONTH:
                    return `${month}/${year}`;
                case DATE_MODE.WEEK:
                    return `S${dateObject.getWeekNumber()}/${year}`;
                default:
                    return `${day}/${month}/${year}`;
            }
        }
        else {
            return '';
        }
    }

    /**
     * Return true if today's date is in the requested period date
     * eg: Today is 25/10/2022 in week 43. if the passed date is 24/10/2022 and periodMode DATE_MODE.DAY then
     * isTodayWithinDatePeriod return false as we are looking for the same date. But if the period mode DATE_MODE.WEEK,
     * as the 24th is also in week 43 then isTodayWithinDatePeriod reruns true
     * @param date {DateExtended} the date to evaluate
     * @param periodMode {DATE_MODE} The period mode in witch we are looking for
     * @returns {boolean} True if today is within date period
     */
    static isTodayWithinDatePeriod(date, periodMode) {
        if (date) {
            const dateObject = new DateExtended(date);
            const now = new DateExtended();

            switch (periodMode) {
                case DATE_MODE.YEAR:
                    return (dateObject.getFullYear() === now.getFullYear());
                case DATE_MODE.HALF:
                    return (dateObject.getFullYear() === now.getFullYear())
                        && (Math.ceil(dateObject.getMonth() / 6) === Math.ceil(now.getMonth() / 6));
                case DATE_MODE.QUARTER:
                    return (dateObject.getFullYear() === now.getFullYear())
                        && (Math.ceil(dateObject.getMonth() / 3) === Math.ceil(now.getMonth() / 3));
                case DATE_MODE.MONTH:
                    return (dateObject.getFullYear() === now.getFullYear())
                        && (dateObject.getMonth() === now.getMonth());
                case DATE_MODE.WEEK:
                    return (dateObject.getFullYear() === now.getFullYear())
                        && (dateObject.getWeekNumber() === now.getWeekNumber());
                default:
                    return (dateObject.getFullYear() === now.getFullYear())
                        && (dateObject.getMonth() === now.getMonth())
                        && (dateObject.getDate() === now.getDate());
            }
        }
        else {
            return false;
        }
    }

    /**
     * Return true if the passed date is in the requested period date
     * eg: Passed date is 25/10/2022 in week 43. if the periodDate is 24/10/2022 and periodMode DATE_MODE.DAY then
     * isDateWithinDatePeriod return false as we are looking for the same date. But if the period mode DATE_MODE.WEEK,
     * as the 24th is also in week 43 then isDateWithinDatePeriod reruns true
     * @param date {DateExtended} the date use as reference
     * @param periodDate {DateExtended} the period date to evaluate
     * @param periodMode {DATE_MODE} The period mode in witch we are looking for
     * @returns {boolean} True if today is within date period
     */
    static isDateWithinDatePeriod(date, periodDate, periodMode) {
        if (date && periodDate) {
            const periodDateObject = new DateExtended(periodDate);
            const dateObject = new DateExtended(date);

            switch (periodMode) {
                case DATE_MODE.YEAR:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear());
                case DATE_MODE.HALF:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear())
                        && (Math.ceil(periodDateObject.getMonth() / 6) === Math.ceil(dateObject.getMonth() / 6));
                case DATE_MODE.QUARTER:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear())
                        && (Math.ceil(periodDateObject.getMonth() / 3) === Math.ceil(dateObject.getMonth() / 3));
                case DATE_MODE.MONTH:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear())
                        && (periodDateObject.getMonth() === dateObject.getMonth());
                case DATE_MODE.WEEK:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear())
                        && (periodDateObject.getWeekNumber() === dateObject.getWeekNumber());
                default:
                    return (periodDateObject.getFullYear() === dateObject.getFullYear())
                        && (periodDateObject.getMonth() === dateObject.getMonth())
                        && (periodDateObject.getDate() === dateObject.getDate());
            }
        }
        else {
            return false;
        }
    }

    /**
     * Return True if dateMode exists
     * @param dateMode {Number} Value to test
     * @returns {boolean}
     */
    static isDateModeValid(dateMode) {
        return Object.keys(DATE_MODE).findIndex(key => DATE_MODE[key] === dateMode) > -1
    }

    /**
     * Returns the date in the format expected by an input's value attribute
     * @returns the date in YYYY-MM-DD format
     */
    toHTMLDateString() {
        return this.getFullYear() + '-' + DateExtended.formatTwoDigit(this.getMonth() + 1) + '-' + DateExtended.formatTwoDigit(this.getDate());
    }

    /**
     * Returns the date in French format (DD/MM/YYYY)
     * @returns the date in DD/MM/YYYY format
     */
    toFRDateString() {
        return  DateExtended.formatTwoDigit(this.getDate()) + '/' + DateExtended.formatTwoDigit(this.getMonth() + 1) + '/' + this.getFullYear();
    }

    /**
     * Returns a date representing the Monday of the first ISO week of the year of the date
     * @returns a date representing the Monday of the first week of the year
     */
    getFirstWeekDateOfYear() {
        const date = new Date(this.getFullYear(), DateExtended.JANUARY, 1);
        if (date.getDay() <= DateExtended.THURSDAY) {
            //First January Belongs to week 52 or 53
            date.setDate(date.getDate() - date.getDay() + 1);
        }
        else {
            //First January Belongs to first week
            date.setDate(date.getDate() + (7 - date.getDay()) + 1);
        }
        return date;
    }

    /**
     * Returns a date representing the Sunday of the last ISO week of the year of the date
     * @returns a date representing the Sunday of the last week of the year
     */
    getLastWeekDateOfYear() {
        const date = new Date(this.getFullYear(), DateExtended.DECEMBER, 31);
        if (date.getDay() >= DateExtended.THURSDAY) {
            //31 of december Belongs to week 52 or 53
            date.setDate(date.getDate() + (7 - date.getDay()));
        }
        else {
            //31 of december Belongs to week 01
            date.setDate(date.getDate() - date.getDay());
        }
        return date;
    }

    /**
     * Returns an object containing the week number and year to which the date belongs
     * @returns an object in the format {week, year}
     */
    getWeekNumber() {
        /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

        let weekNumber;
        const dowOffset = 1; //Week start on monday
        const newYear = new Date(this.getFullYear(),0,1);
        let day = newYear.getDay() - dowOffset; //the day of week the year begins on
        day = (day >= 0 ? day : day + 7);
        const dayNumber = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;

        //if the year starts before the middle of a week
        if(day < 4) {
            weekNumber = Math.floor((dayNumber+day-1)/7) + 1;
            if(weekNumber > 52) {
                const nYear = new Date(this.getFullYear() + 1,0,1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                   the week, it is week #1 of that year*/
                weekNumber = nday < 4 ? 1 : 53;
            }
        }
        else {
            weekNumber = Math.floor((dayNumber+day-1)/7);
        }
        return weekNumber < 10 ? `0${weekNumber}` : weekNumber;
    }

    /**
     * Calculates the number of days between the current date and the date passed in argument
     * @param {Date} date - the compared date
     * @returns the difference in number of days between the two dates
     */
    getDaysFromDate(date) {
        return Math.floor((this - date) / DateExtended.DAY);
    }

    /**
     * Returns the value formatted with 2 digits minimum
     * @param {Number} value - the value to format
     * @returns a character string representing the value passed as argument with 2 digits minimum
     */
    static formatTwoDigit(value) {
        return value < 10 ? '0' + value : value;
    }

    /**
     * Checks the date validity and returns the result
     * @returns a boolean
     */
    isDateValid() {
        return this instanceof Date && !isNaN(this);
    }

    /**
     * Checks if the entered date is not greater than the current date + 20 years
     * @returns un boolean
     */
    static isDateBeforeMax(value) {
        const currentYear = new DateExtended();
        const maxYear = currentYear.getFullYear() + 20;
        const date = new DateExtended(value);
        const entryDate = date.getFullYear();
        return entryDate <= maxYear;
    }

    /**
     * Return true if date is valid
     * @param date {String | Date}
     * @returns {boolean} true if date is valid
     */
    static isDateFormatValid(date) {
        const dateObject = new DateExtended(date);
        return dateObject.toString() !== DateExtended.INVALID_DATE;
    }

    /**
     * Get the maximum allowed date configured in config file
     * @returns {DateExtended} the maximum allowed date
     */
    static getMaxValidDate() {
        const maxDate = new DateExtended();
        maxDate.setFullYear(maxDate.getFullYear() + MaxInputYearOffset);
        return maxDate;
    }

    /**
     * Return true if date is valid and between minimumAllowedDate and Maximum allowed system date
     * @param date {String | Date}
     * @param [minimumAllowedDate] {String | Date}
     * @returns {boolean} true if date is valid and within range
     */
    static isDateValid(date, minimumAllowedDate) {
        const dateObject = new DateExtended(date);
        const minDate = new DateExtended(minimumAllowedDate || 0);
        const maxDate = DateExtended.getMaxValidDate();

        if (!DateExtended.isDateFormatValid(minDate)) { throw new Error('Invalid minimumAllowedDate')}

        return DateExtended.isDateFormatValid(date) && dateObject <= maxDate && dateObject >= minDate;
    }

    /**
     * A callback predicates to use with a sort method of an array ordering by ascendant dates
     * @param dateA {DateExtended} first predicate argument
     * @param dateB {DateExtended} second predicate argument
     * @returns {number} used to make ordering in a sort method
     */
    static sortAscendant(dateA, dateB) {
        const dA = new DateExtended(dateA);
        const dB = new DateExtended(dateB);

        if(!DateExtended.isDateFormatValid(dA)) { throw new Error(`DateExtended.sortAscendant dateA invalid format (received argument: '${dateA}')`)}
        if(!DateExtended.isDateFormatValid(dB)) { throw new Error(`DateExtended.sortAscendant dateB invalid format (received argument: '${dateB}')`)}

        return dA - dB;
    }

    /**
     * A callback predicates to use with a sort method of an array ordering by descendant dates
     * @param dateA {DateExtended} first predicate argument
     * @param dateB {DateExtended} second predicate argument
     * @returns {number} used to make ordering in a sort method
     */
    static sortDescendant(dateA, dateB) {
        const dA = new DateExtended(dateA);
        const dB = new DateExtended(dateB);

        if(!DateExtended.isDateFormatValid(dA)) { throw new Error(`DateExtended.sortDescendant dateA invalid format (received argument: '${dateA}')`)}
        if(!DateExtended.isDateFormatValid(dB)) { throw new Error(`DateExtended.sortDescendant dateB invalid format (received argument: '${dateB}')`)}

        return dB - dA;
    }
}