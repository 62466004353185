import {useDispatch} from "react-redux";
import {useState} from "react";
import {actions} from "Redux/poolSlice";

import DateExtended from "utilities/DateExtended";

/**
 * Create standardize date input with update handler and default styling
 * @param valuePath {Array<String|Number>} Value path inside Redux pool reportData
 * @param valueKey {String} attribute name storing date value
 * @param currentValue {String} the current date value
 * @param className {String} Any class name to add or modify default input styling
 * @param props {React.ComponentProps} Any other props accepted by an input will be dispatch to internal input
 */
export function DateInput({valuePath, valueKey, currentValue, className, ...props}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(currentValue);
    const handleChange = event => {
        if (DateExtended.isDateBeforeMax(event.target.value)) {
            setValue(event.target.value);
            dispatch(actions.updateReportData({
                path: valuePath,
                updates: [
                    {key: valueKey, value: value === '' ? null : event.target.value}
                ]
            }));
        }
    };

    return (
        <input className={`InputDate ${className}`} type='date' value={value ? value : ''} onChange={handleChange} {...props} />
    )
}

/**
 * Return a database structured date to local french format
 * @param currentValue {String} the database structured date value
 */
export function DateDisplay({currentValue}) {
    return (
        currentValue ? DateExtended.formatInputDateToFRString(currentValue) : ''
    )
}

/**
 * Manage the display date input according to the isEditing state
 * @param isEditing {Boolean} While true, component display standardize date input
 * @param valuePath {Array<String|Number>} Value path inside Redux pool reportData
 * @param valueKey {String} attribute name storing date value
 * @param currentValue {String} the current date value
 * @param className {String} Any class name to add or modify default input styling
 * @param props {React.ComponentProps} Any other props accepted by an input will be dispatch to internal input
 */
export function Date({isEditing, valuePath, valueKey, currentValue, className, ...props}) {
    return isEditing
        ? <DateInput className={className} currentValue={currentValue} valuePath={valuePath} valueKey={valueKey} {...props}/>
        : <DateDisplay currentValue={currentValue}/>
}