import { createSlice } from '@reduxjs/toolkit';

export const NOTIFICATION_TYPE = {
    API_UNREACHABLE: 'unavailableApi',
    FATAL_ERROR: 'fatalError',
    ERROR: 'error',
    WARNING: 'warning',
    INFORMATION: 'information',
    SUCCESS: 'success'
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        unavailableApiList: [],
        fatalErrorList: [],
        errorList: [],
        warningList: [],
        informationList: [],
        successList: []
    },
    reducers: {
        createNotification: (state, actions) => {
            const {type, title, message, log} = actions.payload;
            const notificationListName = log.includes('Failed to fetch') || log.includes('status code 503') ? 'unavailableApiList' : `${type}List`;
            const listCopy = [...state[notificationListName]];
            listCopy.push({id: Date.now(), title, message, log});
            state[notificationListName] = listCopy;
        },
        dismissNotification: (state,actions) => {
            const {type, id} = actions.payload;
            const notificationListName = `${type}List`;
            state[notificationListName] = state[notificationListName].filter(el => el.id !== id);
        }
    }
});

export const {createNotification, dismissNotification} = notificationSlice.actions;

export default notificationSlice.reducer;