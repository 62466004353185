import {useMemo, useState} from "react";
import {
    Alert,
    Backdrop, Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, IconButton,
    Portal, Snackbar,
    Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import FilePreview from "./FilePreview";
import {Delete} from "@mui/icons-material";
import {getHashFromFile} from "utilities/HashBlob";


export default function FileUploadModal({name, supTitle, fileList, setFileList, acceptedFormat, onSubmit, dismiss}) {
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);

    const disableSubmit = useMemo(() => (fileList.length === 0 || isLoading), [fileList, isLoading]);

    const handleSelectedFiles = async (event) => {
        setIsLoading(true);
        const cpFiles = [...fileList];
        const newFiles = [];
        const unsupportedFiles = []
        for(const file of event.target.files) {
            const splitName = file.name.split('.');
            const fileExtension = splitName[splitName.length - 1].toLowerCase();
            if(acceptedFormat.includes(fileExtension)) {
                file.sha256 = await getHashFromFile(file);
                file.renamed = file.name?.split('.')[0] || '';
                file.description = '';
                newFiles.push(file);
            }
            else {
                unsupportedFiles.push(file.name);
            }
        }
        const validNewFiles = newFiles.filter(file => !cpFiles.find(f => f.sha256 === file.sha256));
        setFileList(cpFiles.concat(validNewFiles));
        setRejectedFiles(unsupportedFiles);
        setIsLoading(false);
    };

    const handleRejectedFileNotificationOnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setRejectedFiles([])
    }

    const submit = (event) => {
        event.preventDefault();
        const emptyFilename = fileList.find(file => file.renamed === '');
        if(emptyFilename) {
            setSubmitError(true);
        }
        else {
            setIsLoading(true);
            setSubmitError(false);
            onSubmit();
        }

    }

    function deleteFile(identifier) {
        const fileListCopy = [...fileList];
        const deleteIndex = fileListCopy.findIndex(file => file.sha256 === identifier);
        fileListCopy.splice(deleteIndex, 1);
        setFileList(fileListCopy);
    }

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'md'} fullWidth>
                <DialogTitle flexDirection='column' sx={{gap: 0, alignItems: 'start'}}>
                    <div style={{fontWeight: 'lighter', color: 'grey', fontSize: '0.8rem'}}>
                        {supTitle?.toUpperCase()}
                    </div>
                    <div>{name?.toUpperCase()}</div>
                </DialogTitle>
                <DialogContent>
                    <Stack>
                        {fileList.map(
                            file => <FileDetailsForm
                                key={file.sha256}
                                file={file}
                                onRemove={() => deleteFile(file.sha256)}
                            />
                        )}
                        {fileList.length === 0 &&
                            <Typography variant='overline' textAlign='center'>
                                Ajouter des pièces jointes au projet en cliquant sur le bouton ci-dessous
                            </Typography>
                        }
                    </Stack>
                    <Button sx={{mt: 2}} variant="outlined" color='secondary' size='large' component="label" fullWidth>
                        Ajouter des fichiers
                        <input type='file' hidden multiple onChange={handleSelectedFiles} accept={acceptedFormat}/>
                    </Button>
                    <Snackbar
                        open={rejectedFiles.length > 0}
                        autoHideDuration={10000}
                        onClose={handleRejectedFileNotificationOnClose}
                    >
                        <Alert severity="warning" onClose={handleRejectedFileNotificationOnClose}>
                            {rejectedFiles.join(', ')} n'ont pas été chargé, ces types de fichiers ne sont pas supportés
                        </Alert>
                    </Snackbar>
                    {isLoading && <Loading />}
                    {submitError && <SubmitErrorMessage close={() => setSubmitError(false)}/>}
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={dismiss}>
                        Annuler
                    </Button>
                    <Button variant='outlined' color='primary' disabled={disableSubmit} onClick={submit}>
                        Envoyer
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

function FileDetailsForm({file, onRemove}) {
    const [filename, setFilename] = useState(file.renamed);
    const [description, setDescription] = useState(file.description);

    return (
        <>
            <Stack direction='row' alignItems='center' gap={2} sx={{my: 2}}>
                <Box maxWidth={'80px'}>
                    <FilePreview source={file}/>
                </Box>
                <TextField
                    sx={{minWidth: '33%'}}
                    required
                    id={`${file.sha256}-name`}
                    label="Nom"
                    value={filename}
                    onChange={(event) => {
                        file.renamed = event.target.value;
                        setFilename(event.target.value);
                    }}
                    error={file.renamed === ''}
                    helperText={file.renamed === '' ? 'Ce champ est obligatoire' : 'Ce nom sera celui affiché par les tags'}
                />
                <TextField
                    sx={{flexGrow: 1}}
                    id={`${file.sha256}-description`}
                    label="Description"
                    multiline
                    value={description}
                    onChange={(event) => {
                        file.description = event.target.value;
                        setDescription(event.target.value);
                    }}
                    helperText={`Vous pouvez notamment définir des mots clé`}
                />
                <Tooltip title='Supprimer' placement='left'>
                    <IconButton color='error' size='small' onClick={onRemove}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Divider />
        </>
    )
}

function Loading() {
    return (
            <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
    )
}

function SubmitErrorMessage({close}) {
    return (
        <Snackbar open onClose={close}>
            <Alert onClose={close} severity="error" sx={{ width: '100%' }}>
                Au moins un fichier ne possède pas de nom
            </Alert>
        </Snackbar>
    )
}