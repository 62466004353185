import React from "react";
import {useSelector} from "react-redux";
import {Stack, Typography} from "@mui/material";
import AttachmentGrid from "../../../../feature/FileExplorer/AttachmentGrid";


/**
 * The page for handling attachments
 * @returns {JSX.Element}
 */
function AttachmentManager() {
    const {project} = useSelector(state => state.page.data);

    return (
        <Stack flexGrow={1} gap={2}>
            <Stack bgcolor={'primary.main'} px={6} pt={2} pb={1}>
                <Typography
                    variant={'h2'}
                    color={'white'}
                    lineHeight={'3rem'}
                >
                    GESTION DES FICHIERS
                </Typography>
                <Typography
                    variant={'h6'}
                    color={'secondary.main'}
                    ml={4}
                >
                    {project.name}
                </Typography>
            </Stack>
            <Stack px={2} pb={2}>
                <AttachmentGrid projectId={project.id}/>
            </Stack>
        </Stack>

    )
}

export default AttachmentManager;
