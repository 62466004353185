import {useMemo} from "react";
import useToggle from "customHook/useToggle";


const ENV_INFO_POSITION = [
    {top: 'auto',bottom: 0, right: 'auto', left:0},
    {top: 0,bottom: 'auto', right: 0, left:'auto'}
];

/**
 * Create a frame indicating environment of current website
 */
function EnvironmentFrame() {
    const showEnvInfo = useMemo(() => process.env.REACT_APP_BUILD_ENV.toLowerCase() !== 'prod', []);
    const [envInfoAtLeft, toggle] = useToggle(false);

    return showEnvInfo &&
        <div className={`EnvBox ${process.env.REACT_APP_BUILD_ENV}`} style={ENV_INFO_POSITION[envInfoAtLeft ? 0 : 1]} >
            <div className='EnvInfo' onClick={toggle}>
                {process.env.REACT_APP_BUILD_ENV.toUpperCase()}
            </div>
        </div>
}

export default EnvironmentFrame;