
import styles from './UnloadedContent.module.css';

function UnloadedContent() {

    return (
        <div className={styles.UnloadedContent}>
            <h2>Bienvenue</h2>
            <p>Pour commencer, choisissez un Compte-Rendu ou Créer en un nouveau</p>
        </div>
    )
}

export default UnloadedContent;