import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {acceptedFiles} from "../../config";
import moment from "moment/moment";
import ApiSyncManager from "../../utilities/ApiSyncManager";
import {createNotification, NOTIFICATION_TYPE} from "../../Redux/Notification/notificationSlice";
import FileUploadModal from "./FileUploadModal";

export default function AttachementUploadModal({projectId, dismiss}) {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const acceptedFile = '.' + acceptedFiles.image.concat(acceptedFiles.other).join(' ,.');

    async function onSubmitHandler() {
        const data = new FormData();
        fileList.forEach(file => {
            data.append(file.sha256, file);
            data.append(`${file.sha256}-name`, file.renamed);
            data.append(`${file.sha256}-description`, file.description);
            data.append(
                `${file.sha256}-last-modified`, moment(file.lastModified).format('YYYY-MM-DD kk:mm:ssZ')
            );
        });
        data.append('project_id', projectId);
        data.append('fileCount', fileList.length);

        ApiSyncManager.sendMultipart("/attachment/", {
            method: 'POST',
            body: data
        })
            .then((res) => {
                const created = res.filter(el => el.status === 'fulfilled');
                const createdCount = created.length;
                const ignoreCount = fileList.length - createdCount;
                if(createdCount === fileList.length) {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.SUCCESS,
                        title: `
                        ${createdCount} 
                        fichier${createdCount > 1 ? 's' : ''} 
                        ajouté${createdCount > 1 ? 's' : ''}`,
                        message: '',
                        log: ''
                    }));
                }
                else {
                    const addedFiles = created.map(file => file.value);
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.WARNING,
                        title: `
                        ${ignoreCount} 
                        fichier${ignoreCount > 1 ? 's n\'ont' : 'n\'a'} 
                        pas pu être ajouté${ignoreCount > 1 ? 's' : ''}'`,
                        message: `
                        Seul 
                        ${addedFiles.join(', ')}
                        ${ignoreCount > 1 ? 'ont été' : 'a été'} 
                        ajouté${ignoreCount > 1 ? 's' : ''} au projet`,
                        log: ''
                    }));
                }

                dismiss();
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Aucun fichier n\a pu être téléchargé',
                    message: 'L\'enregistrement des pièces-jointes a échoué',
                    log: error.stack.toString()
                }));
                dismiss();
            });
    }

    return (
        <FileUploadModal
            name={'Charger des pièces jointes'}
            supTitle={''}
            acceptedFormat={acceptedFile}
            fileList={fileList}
            setFileList={setFileList}
            dismiss={dismiss}
            onSubmit={onSubmitHandler}
        />
    )
}