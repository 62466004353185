import {useRef} from "react";
import {useDispatch} from "react-redux";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import ApiSyncManager from "utilities/ApiSyncManager";

import FilePreview from "./FilePreview";
import {Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";

/**
 * Allows the writer to add attachment(s) to a project
 * @param {Function} close - Setter for close the modal
 * @param file {{
 *     filename: String,
 *     title: String,
 *     description: String|Null
 * }} - Attachment's Data use to build components
 * @param {Function} setAttachmentList - Setter for update the attachment list
 * @param {Array} attachmentList {{
 *      filename: String,
 *     title: String,
 *     description: String|Null
 * }} - Guetter of the attachment list
 * @returns {JSX.Element}
 */
function FileUpdateModal({ file, close, attachmentList, setAttachmentList }) {
    const dispatch = useDispatch();
    const form = useRef(null);

    function handleUpdate(event) {
        event.preventDefault();
        const data = {
            title: form.current.title.value,
            description: form.current.description.value,
            fileId: file.id
        };
        close(null);
        ApiSyncManager.fetchJson(`/attachment/`, {
            method: 'PUT',
            body: data
        })
            .then(() => {
                const cpAttachmentList = [...attachmentList];
                const fileIndex = attachmentList.findIndex(element => file.id === element.id);
                cpAttachmentList[fileIndex] = {
                    ...cpAttachmentList[fileIndex],
                    title: data.title,
                    description: data.description
                }
                setAttachmentList(cpAttachmentList);
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.SUCCESS,
                    title: 'Modifications enregistrées',
                    message: '',
                    log: ''
                }));
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Échec d\'enregistrement',
                    message: 'Les modifications n\'ont pas été prise en compte',
                    log: error.stack.toString()
                }));
            });
    }

    return (
        <Dialog open={true} fullWidth maxWidth={'lg'}>
            <DialogTitle>{"Modification d'une pièce-jointe"}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
                <Card variant="outlined" sx={{flexBasis: '30%', margin: 'auto'}}>
                    <FilePreview file={file}/>
                </Card>
                <Stack flexGrow={1}>
                    <form ref={form} style={{minHeight: '100%'}}>
                        <Stack gap={2} minHeight={'100%'}>
                            <TextField
                                label={'Titre'}
                                name={'title'}
                                defaultValue={file.title}
                                fullWidth
                            />
                            <TextField
                                label={'Description'}
                                name={'description'}
                                placeholder={'Utiliser pour la recherche par mot clé'}
                                defaultValue={file.description}
                                multiline
                                fullWidth
                                InputProps={{
                                    sx:{flexGrow: 1}

                                }}
                                sx={{flexGrow: 1}}
                            />
                        </Stack>
                    </form>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} color={'secondary'} onClick={() => close(null)}>
                    Annuler
                </Button>
                <Button variant={'outlined'} onClick={handleUpdate}>
                    Enregistrer les modifications
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FileUpdateModal;