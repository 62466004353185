import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import './Footer.css'


/**
 * Create a footer component
 */
function Footer() {
    const waitingChanges = useSelector(state => {
        const {creates, updates, deletes} = state.pool.buffer;
        const createsCount = creates ? creates.length : 0;
        const updatesCount = updates ? updates.length : 0;
        const deletesCount = deletes ? deletes.length : 0;
        return createsCount +updatesCount + deletesCount;
    });

    const statusMessage = useMemo(
        () => waitingChanges > 0 ? `${waitingChanges} modifications en attente de synchronisation` : 'A jour',
        [waitingChanges]
    );

    return (
        <div className='footerBoite'>
            <div className='footerNav'>
                <a href={window.location.href}>Notice Utilisateur</a>
                <a href={window.location.href}>Assistance</a>
            </div>
            <div className='footerSync'>
                <span>{statusMessage}</span>
            </div>
        </div>
    );
}

export default Footer;