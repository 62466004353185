import {createElement, useContext, useEffect, useMemo, useRef, useState} from "react";
import useFetchData from "customHook/useFetchData";
import {useDispatch, useSelector} from "react-redux";
import {actions, getElement} from "Redux/poolSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import Normalize from "utilities/Normalize";
import ApiSyncManager from "utilities/ApiSyncManager";
import DateExtended, {DATE_MODE} from "utilities/DateExtended";
import {ContainerContext} from "./Container";
import SearchInput from "components/Reusable/SearchInput/SearchInput";
import {Modal, Header, ActionBox, PrimaryButton, SecondaryButton} from "components/Reusable/Modals/ModalParts";
import InputText from "feature/InputText/InputText";

import styles from './TaskForms.module.scss'

export const FORM_STEP = {
    ADD_ENTERPRISE: 0,
    CONFIRM_CREATE_ENTERPRISE: 1,
    CREATE_ENTERPRISE: 2,
    ADD_CATEGORY: 3,
    CONFIRM_CREATE_CATEGORY: 4,
    CREATE_CATEGORY: 5,
    ADD_TASK: 6
};

const STEP_MODAL = [
    AddEnterpriseForm,
    ConfirmEnterpriseCreation,
    EnterpriseCreation,
    AddCategoryForm,
    ConfirmCategoryCreation,
    CategoryCreation,
    TaskForm
];

/**
 * Build and manage task form modal
 * @param path {Array<String|Number>} Value path inside Redux pool reportData
 * @param dismissModal {Function} Function prototype allowing component to dismiss modal
 * @param formStep {Number} Define the step to initially run on modal call
 * @param initialData {{
 *     enterprise: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     category: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     task: {
 *         id: {Number},
 *         created_at: {String},
 *         text: {String},
 *         due_date: {String},
 *         due_date_type: {DATE_MODE},
 *         completed_at: {String},
 *         comment: {String}
 *     }
 * }} Pass initial data required for skipped step
 */
export default function TaskForms({path, dismissModal, formStep, initialData}) {
    const [step, setStep] = useState(formStep);
    const [formData, setFormData] = useState(initialData || {});

    return createElement(STEP_MODAL[step], {
        path, dismissModal, setStep, formData, setFormData
    });
}

/**
 * Modal allowing user to create or insert existing enterprise to tasks list
 * This modal is shown on **FORM_STEP.ADD_ENTERPRISE**
 * @param path {Array<String|Number>} tasks path inside Redux pool reportData
 * @param dismissModal {Function} Function prototype allowing component to dismiss modal
 * @param setStep {Function}  Function prototype selecting step to display
 * @param setFormData {Function} Pass new value to task form
 */
function AddEnterpriseForm({ path, dismissModal, setStep, setFormData }) {
    const loadingManager = useFetchData({
        modalTitle: 'RÉCUPÉRATION DES ENTREPRISES',
        steps: [{
            do: async () => {
                const apiSyncManager = new ApiSyncManager();
                await apiSyncManager.getEnterpriseList(dispatch);
            },
            name: 'Veuillez patienter',
            msgOnError: 'Échec de récupération des entreprises',
            successProgress: 100
        }]
    });
    const dispatch = useDispatch();
    const enterpriseList = useSelector(state => state.pool.enterpriseList);
    const alreadyInsertedEnterprises = useSelector(state => getElement(state.pool.reportData, path));
    const [searchValue, setSearchValue] = useState('');
    const [isInvalid, setIsInvalid] = useState('');
    const [existingEnterprise, setExistingEnterprise] = useState(false);
    const addableEnterprise = useMemo(() => {
        return enterpriseList.filter(ets =>
            alreadyInsertedEnterprises.find(iEts => ets.id === iEts.enterprise_id) === undefined
        ).sort((a, b) => a.name.localeCompare(b.name))
    },[enterpriseList, alreadyInsertedEnterprises]);
    const filteredEnterprise = useMemo(() => {
        if (addableEnterprise.find(ets => ets.name.toUpperCase() === searchValue.toUpperCase())) {
            return [];
        }
        else if(searchValue.length > 2) {

            return addableEnterprise.filter(ets => (
                Normalize.string(ets.name).includes(Normalize.string(searchValue))
            )).sort((a, b) => a.name.localeCompare(b.name));
        }
        else {
            return [];
        }
    }, [addableEnterprise, searchValue]);

    const handleSearchChange = (value) => {
        const existingEts = addableEnterprise.find(ets => ets.name.toUpperCase() === value.toUpperCase());
        setSearchValue(value);
        existingEts ? setExistingEnterprise(existingEts) : setExistingEnterprise(null);
    };

    const isEnterpriseInputValid = () => {
        const alreadyInserted = alreadyInsertedEnterprises.find(
            ets => ets.enterprise_name.toUpperCase() === searchValue.toUpperCase()
        );

        if(alreadyInserted) {
            setIsInvalid('L\'entreprise est déjà présente dans le compte rendu!');
            return false;
        }
        else if (searchValue.length === 0) {
            setIsInvalid('Choisissez l\'entreprise à ajouter au compte rendu');
            return false;
        }
        else if (searchValue.length <= 3) {
            setIsInvalid('Nom d\'entreprise trop court! Le nom de l\'entreprise doit avoir au moins 3 caractères');
            return false;
        }
        else {
            return true;
        }
    }

    const nextMiddleware = event => {
        event.preventDefault();
        if(isEnterpriseInputValid()) {
            if (existingEnterprise) {
                setFormData({
                    enterprise: {
                        id: existingEnterprise.id,
                        name: existingEnterprise.name
                    }
                })
                setStep(FORM_STEP.ADD_CATEGORY);
            }
            else {
                setFormData({
                    enterprise: {
                        id: null,
                        name: searchValue
                    }
                })
                setStep(FORM_STEP.CONFIRM_CREATE_ENTERPRISE);
            }
        }
    };

    return (
        <Modal>
            <form onSubmit={nextMiddleware}>
                <Header title='AJOUTER UNE ENTREPRISE' />
                <div className={styles.TaskFormBody}>
                    <SearchInput
                        searchValue={searchValue}
                        setSearchValue={handleSearchChange}
                        filteredList={filteredEnterprise}
                        inputPlaceholder='Rechercher une entreprise'
                        cardComponent={EnterpriseCard}
                        customCardProps={{setSearchValue: handleSearchChange}}
                    />
                    {isInvalid && <div className='InputInvalidMessage'>{isInvalid}</div>}
                </div>
                <ActionBox>
                    <SecondaryButton
                        buttonType='button'
                        onClick={dismissModal}>
                        Annuler
                    </SecondaryButton>
                    <PrimaryButton
                        buttonType='submit'
                        onClick={nextMiddleware}
                        disabled={searchValue.length < 3}>
                        {searchValue.length < 3 || existingEnterprise ? 'Ajouter' : 'Créer'}
                    </PrimaryButton>
                </ActionBox>
            </form>
            {loadingManager}
        </Modal>
    )
}

/**
 * Modal used to force user to confirm enterprise creation
 * This modal is shown on **FORM_STEP.CONFIRM_CREATE_ENTERPRISE**
 * @param formData { {enterprise: {id: {Number}, name: {String} }} } Enterprise data to create in database
 * @param setStep  {Function}  Function prototype selecting step to display
 */
function ConfirmEnterpriseCreation ({setStep, formData})  {
    return (
        <Modal>
            <Header title='CRÉER UNE NOUVELLE ENTREPRISE' />
            <div className={styles.TaskFormBody}>
                L'entreprise <span className={'bold'}>{formData.enterprise.name.toUpperCase()}</span> va être créée,
                souhaitez vous continuer ?
            </div>
            <ActionBox>
                <SecondaryButton
                    buttonType='button'
                    onClick={() => setStep(FORM_STEP.ADD_ENTERPRISE)}>
                    Non
                </SecondaryButton>
                <PrimaryButton
                    buttonType='submit'
                    onClick={() => setStep(FORM_STEP.CREATE_ENTERPRISE)}>
                    Oui
                </PrimaryButton>
            </ActionBox>
        </Modal>
    )
}

/**
 * Modal used to make user waiting while creating enterprise
 * This modal is shown on **FORM_STEP.CREATE_ENTERPRISE**
 * @param setStep {Function}  Function prototype selecting step to display
 * @param formData { {enterprise: {id: {Number}, name: {String} }} } Enterprise data to create in database
 * @param setFormData {Function} Pass new value to task form
 */
function EnterpriseCreation ({setStep, formData, setFormData})  {
    const dispatch = useDispatch();
    useEffect(() => {
        try {
            ApiSyncManager.fetchJson('/enterprise/create',
                {
                    method: 'POST',
                    body: {name: formData.enterprise.name, alias: null, siret_number: null}
                })
                .then(({id}) => {
                    const cpFormData = {...formData};
                    cpFormData.enterprise.id = id;
                    setFormData(cpFormData);
                    setStep(FORM_STEP.ADD_CATEGORY);
                });
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Échec de la création',
                message: 'L\'entreprise n\'a pas pu être créée',
                log: error.stack.toString()
            }));
        }
    }, []);

    return (
        <Modal>
            <Header title={`CRÉATION DE ${formData.enterprise.name.toUpperCase()}`} />
            <div className={styles.TaskFormBody}>
                Veuillez patienter...
            </div>
        </Modal>
    )
}

/**
 * Modal allowing user to create or insert existing category to tasks list
 * This modal is shown on **FORM_STEP.ADD_CATEGORY**
 * @param path {Array<String|Number>} tasks path inside Redux pool reportData
 * @param dismissModal {Function} Function prototype allowing component to dismiss modal
 * @param setStep {Function}  Function prototype selecting step to display
 * @param formData { {enterprise: {id: {Number}, name: {String} }} } Enterprise data to create in database
 * @param setFormData {Function} Pass new value to task form
 */
function AddCategoryForm ({path, dismissModal, setStep, formData, setFormData})  {
    const loadingManager = useFetchData({
        modalTitle: 'RÉCUPÉRATION DES CATÉGORIES',
        steps: [{
            do: async () => {
                const apiSyncManager = new ApiSyncManager();
                await apiSyncManager.getTaskCategoryList(dispatch);
            },
            name: 'Veuillez patienter',
            msgOnError: 'Échec de récupération des catégories',
            successProgress: 100
        }]
    });

    const dispatch = useDispatch();
    const categoryList = useSelector(state => {
        const categories = [...state.pool.taskCategoryList];
        return categories.length > 0 ? categories.sort((a, b) => a.name.localeCompare(b.name)) : [];
    });
    const [searchValue, setSearchValue] = useState('');
    const [existingCategory, setExistingCategory] = useState(false);
    const filteredCategories = useMemo(() => {
        if (categoryList.find(cat => cat.name.toUpperCase() === searchValue.toUpperCase())) {
            return [];
        }
        else if(searchValue.length > 2) {

            return categoryList.filter(ets => (
                Normalize.string(ets.name).includes(Normalize.string(searchValue))
            )).sort((a, b) => a.name.localeCompare(b.name));
        }
        else {
            return [];
        }
    }, [categoryList, searchValue]);


    const handleSearchChange = (value) => {
        const existingCat = categoryList.find(cat => cat.name && (cat.name.toUpperCase() === value.toUpperCase()));
        setSearchValue(value);
        existingCat ? setExistingCategory(existingCat) : setExistingCategory(null);
    };

    const nextMiddleware = event => {
        event.preventDefault();
        const cpFormData = {...formData};
        if (searchValue === '') {
            setFormData({
                ...cpFormData,
                category: {
                    id: null,
                    name: null
                }
            });
            setStep(FORM_STEP.ADD_TASK);
        }
        else if (existingCategory) {
            setFormData({
                ...cpFormData,
                category: {
                    id: existingCategory.id,
                    name: existingCategory.name
                }
            });
            setStep(FORM_STEP.ADD_TASK);
        }
        else {
            setFormData({
                ...cpFormData,
                category: {
                    id: null,
                    name: searchValue
                }
            });
            setStep(FORM_STEP.CONFIRM_CREATE_CATEGORY);
        }

    };

    return (
        <Modal>
            <form onSubmit={nextMiddleware}>
                <Header title='AJOUTER UNE CATÉGORIE' />
                <div className={styles.TaskFormBody}>
                    <SearchInput
                        searchValue={searchValue}
                        setSearchValue={handleSearchChange}
                        filteredList={filteredCategories}
                        inputPlaceholder='SANS CATÉGORIE ou Rechercher une catégorie'
                        cardComponent={EnterpriseCard}
                        customCardProps={{setSearchValue: handleSearchChange}}
                    />
                </div>
                <ActionBox>
                    <SecondaryButton
                        buttonType='button'
                        onClick={dismissModal}>
                        Annuler
                    </SecondaryButton>
                    <PrimaryButton
                        buttonType='submit'
                        onClick={nextMiddleware}>
                        {existingCategory ? 'Ajouter' : 'Créer'}
                    </PrimaryButton>
                </ActionBox>
            </form>
            {loadingManager}
        </Modal>
    )
}

/**
 * Modal used to force user to confirm category creation
 * This modal is shown on **FORM_STEP.CONFIRM_CREATE_CATEGORY**
 * @param formData {{
 *     enterprise: {id: {Number}, name: {String} },
 *     category: {id: {Number}, name: {String} }
 *     }} Enterprise data to create in database
 * @param setStep  {Function}  Function prototype selecting step to display
 */
function ConfirmCategoryCreation ({setStep, formData})  {
    return (
        <Modal>
            <Header title='CRÉER UNE NOUVELLE CATÉGORIE' />
            <div className={styles.TaskFormBody}>
                La catégorie <span className={'bold'}>{formData.category.name.toUpperCase()}</span> va être créée,
                souhaitez vous continuer ?
            </div>
            <ActionBox>
                <SecondaryButton
                    buttonType='button'
                    onClick={() => setStep(FORM_STEP.ADD_CATEGORY)}>
                    Non
                </SecondaryButton>
                <PrimaryButton
                    buttonType='submit'
                    onClick={() => setStep(FORM_STEP.CREATE_CATEGORY)}>
                    Oui
                </PrimaryButton>
            </ActionBox>
        </Modal>
    )
}

/**
 * Modal used to make user waiting while creating enterprise
 * This modal is shown on **FORM_STEP.CREATE_CATEGORY**
 * @param setStep {Function}  Function prototype selecting step to display
 * @param formData { {enterprise: {id: {Number}, name: {String} }} } Enterprise data to create in database
 * @param setFormData {Function} Pass new value to task form
 */
function CategoryCreation ({setStep, formData, setFormData})  {
    useEffect(() => {
        try {
            ApiSyncManager.fetchJson('/taskCategory/create',
                {
                    method: 'POST',
                    body: {name: formData.category.name}
                })
                .then(newCategory => {
                    const cpFormData = {...formData};
                    cpFormData.category.id = newCategory.id;
                    setFormData(cpFormData);
                    setStep(FORM_STEP.ADD_TASK);
                });
        }
        catch (error) {
            window.alert('taskCategory Creation failed');
        }
    }, []);

    return (
        <Modal>
            <Header title={`CRÉATION DE ${formData.category.name.toUpperCase()}`} />
            <div className={styles.TaskFormBody}>
                Veuillez patienter...
            </div>
        </Modal>
    );
}

/**
 * Modal used to create and update task data
 * This modal is shown on **FORM_STEP.ADD_TASK**
 * @param formData {{
 *     enterprise: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     category: {
 *         id: {Number},
 *         name: {String}
 *     },
 *     task: {
 *         id: {Number},
 *         created_at: {String},
 *         text: {String},
 *         due_date: {String},
 *         due_date_type: {DATE_MODE},
 *         completed_at: {String},
 *         comment: {String}
 *     }
 * }} The data form gather in previous modals or provided by taskFroms
 * @param dismissModal {Function} Function prototype allowing component to dismiss modal
 */
function TaskForm({formData, dismissModal}) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const context = useContext(ContainerContext);
    const { writing_date, report_id } = useSelector(state => state.pool.reportData.report_details)
    const containerData = useSelector(state => getElement(state.pool.reportData, context.reportDataPath));
    const initialFormData = useMemo(() => {
        return {
            task: {
                created_at: writing_date || new DateExtended().toHTMLDateString(),
                text: null,
                due_date: '',
                due_date_type: DATE_MODE.DAY,
                completed_at: '',
                comment: null
            },
            ...formData
        }
    }, [])

    const [dueDate, setDueDate] = useState(initialFormData.task.due_date);
    const [taskText, setTaskText] = useState(initialFormData.task.text);
    const [commentText, setCommentText] = useState(initialFormData.task.comment);
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState({
        created: '',
        text: '',
        due: '',
        dueType: '',
        completed: ''
    });

    const validateInputs = () => {
        const fields = {
            id: initialFormData.task.id,
            created_at: formRef.current.created_at.value,
            text: taskText,
            due_date: formRef.current.due_date.value,
            due_date_type: formRef.current.due_date_type.value,
            completed_at: formRef.current.completed_at.value,
            comment: commentText
        };
        const cpInvalidMsg = {...invalidMsg};

        if(fields.created_at.trim() === '') {
            cpInvalidMsg.created = 'Ce champ est obligatoire';
        }
        else if(!DateExtended.isDateFormatValid(fields.created_at)) {
            cpInvalidMsg.created = 'Le format de date n\'est pas valide: JJ/MM/AAAA';
        }
        else if(!DateExtended.isDateValid(fields.created_at)) {
            cpInvalidMsg.created = `La date n'est pas valide. 
                Vous ne pouvez pas saisir une date après le ${DateExtended.getMaxValidDate().toFRDateString()}`;
        }
        else {
            cpInvalidMsg.created = '';
        }

        if(fields.due_date !== '' && !DateExtended.isDateFormatValid(fields.due_date)) {
            cpInvalidMsg.due = 'Le format de date n\'est pas valide: JJ/MM/AAAA';
        }
        else if(fields.due_date !== '' && !DateExtended.isDateValid(fields.due_date)) {
            cpInvalidMsg.due = `La date n'est pas valide: \n 
                Vous ne pouvez pas saisir une date après le ${DateExtended.getMaxValidDate().toFRDateString()}`;
        }
        else if(fields.due_date !== '' && !DateExtended.isDateValid(fields.due_date, fields.created_at)) {
            cpInvalidMsg.due = `La date n'est pas valide: \n
                Vous ne pouvez pas saisir une date d'objectif avant la date de création`;
        }
        else {
            cpInvalidMsg.due = '';
        }

        if(fields.completed_at !== '' && !DateExtended.isDateFormatValid(fields.completed_at)) {
                cpInvalidMsg.completed = 'Le format de date n\'est pas valide: JJ/MM/AAAA';
        }
        else if(fields.completed_at !== '' && !DateExtended.isDateValid(fields.completed_at)) {
            cpInvalidMsg.completed = `La date n'est pas valide: \n 
                Vous ne pouvez pas saisir une date après le ${DateExtended.getMaxValidDate().toFRDateString()}`;
        }
        else if(fields.completed_at !== '' && !DateExtended.isDateValid(fields.completed_at, fields.created_at)) {
            cpInvalidMsg.completed = `La date n'est pas valide: \n
                Vous ne pouvez pas saisir une date de réalisation avant la date de création de la consigne`;
        }
        else {
            cpInvalidMsg.completed = '';
        }

        if (fields.text === '') {
            cpInvalidMsg.text = 'Ce champ est obligatoire'
        }
        else {
            cpInvalidMsg.text = ''
        }

        setInvalidMsg(cpInvalidMsg);
        setFirstSubmitFailed(true);
        return (cpInvalidMsg.created === ''
            && cpInvalidMsg.due === ''
            && cpInvalidMsg.completed === ''
            && cpInvalidMsg.text === '')
    };

    const createTask = async () => {
        try {
            const newTask = await ApiSyncManager.fetchJson(`/task/create`, {
                method: 'POST',
                body: {
                    id: initialFormData.task.id,
                    created_at: formRef.current.created_at.value,
                    text: taskText,
                    due_date: Normalize.nullEmptyString(formRef.current.due_date.value),
                    due_date_type: formRef.current.due_date_type.value,
                    completed_at: Normalize.nullEmptyString(formRef.current.completed_at.value),
                    comment: commentText,
                    container_id: context.container.id,
                    category_id: initialFormData.category.id,
                    enterprise_id: initialFormData.enterprise.id,
                    report_id: report_id,
                }
            });
            createInReportData(newTask);
            dismissModal();
        }
        catch (error) {
            window.alert(error);
        }
    };

    const createInReportData = (data) => {
        const enterprise = {
            enterprise_id: data.enterprise_id,
            enterprise_name: data.enterprise_name
        };
        const category = {
            category_id: data.category_id,
            category_name: data.category_name
        };
        const task = {
            id: data.id,
            created_at: data.created_at,
            text: data.text,
            due_date: data.due_date,
            due_date_type: data.due_date_type,
            completed_at: data.completed_at,
            comment: data.comment,
            parent: null
        };

        const enterpriseIndex = containerData.content.findIndex(el =>
            el.enterprise_id === data.enterprise_id);
        if(enterpriseIndex >= 0) {

            const categoryIndex = containerData.content[enterpriseIndex].categories.findIndex(el =>
                el.category_id === data.category_id);
            if(categoryIndex >= 0) {

                dispatch(actions.createInReportData({
                    path: [...context.reportDataPath, 'content', enterpriseIndex, 'categories', categoryIndex, 'tasks'],
                    object: task
                }))
            }
            else {
                dispatch(actions.createInReportData({
                    path: [...context.reportDataPath, 'content', enterpriseIndex, 'categories'],
                    object: {
                        ...category,
                        tasks: [task]
                    }
                }))
            }

        }
        else {
            dispatch(actions.createInReportData({
                path: [...context.reportDataPath, 'content'],
                object: {
                    ...enterprise,
                    categories: [{
                        ...category,
                        tasks: [task]
                    }]
                }
            }))
        }
    };

    const updateInReportData = () => {
        const enterpriseIndex = containerData.content.findIndex(el =>
            el.enterprise_id === initialFormData.enterprise.id);
        const categoryIndex = containerData.content[enterpriseIndex].categories.findIndex(el =>
            el.category_id === initialFormData.category.id);
        const taskIndex = containerData.content[enterpriseIndex].categories[categoryIndex].tasks.findIndex(el =>
            el.id === initialFormData.task.id);
        console.log('Update')
        dispatch(actions.updateReportData({
            path: [...context.reportDataPath, 'content', enterpriseIndex, 'categories', categoryIndex, 'tasks', taskIndex],
            component: 'task',
            updates: [
                {key: 'id', value: initialFormData.task.id},
                {key: 'created_at', value: formRef.current.created_at.value},
                {key: 'text', value: taskText},
                {key: 'due_date', value: Normalize.nullEmptyString(formRef.current.due_date.value)},
                {key: 'due_date_type', value: parseInt(formRef.current.due_date_type.value)},
                {key: 'completed_at', value: Normalize.nullEmptyString(formRef.current.completed_at.value)},
                {key: 'comment', value: commentText}
            ]
        }));
        console.log('done')
        dismissModal();
    };

    const handleSave = (event) => {
        event.preventDefault();
        if (validateInputs()) {
            for(const input of formRef.current) {
                input.readOnly = true;
                input.disabled = true;
            }
            initialFormData.task.id ? updateInReportData() : createTask();
        }
    };

    return (
        <Modal>
            <form ref={formRef} className={styles.TaskForm} onSubmit={handleSave} onChange={firstSubmitFailed ? validateInputs : undefined}>
                <Header title='AJOUTER UNE CONSIGNE' />
                <div className={styles.TaskFormBody}>
                    <div className={styles.Head}>
                        <div>
                            <label htmlFor='created_at'>Date de rédaction</label>
                            <input className={`InputDate ${invalidMsg.created ? 'InputInvalid' : ''}`} name='created_at' type='date' defaultValue={initialFormData.task.created_at || ''}/>
                            <div className='InputInvalidMessage'>{invalidMsg.created}</div>
                        </div>
                        <div>
                            <label htmlFor='due_date'>Date d'Objectif</label>
                            <input
                                className={`InputDate ${invalidMsg.due ? 'InputInvalid' : ''}`}
                                name='due_date'
                                type='date'
                                value={dueDate}
                                onChange={event => setDueDate(event.target.value)}
                            />
                            <div className='InputInvalidMessage'>{invalidMsg.due}</div>

                            <SelectDateMode date={dueDate} defaultValue={initialFormData.task.due_date_type}/>
                        </div>
                        <div>
                            <label htmlFor='completed_at'>Date de Réalisation</label>
                            <input
                                className={`InputDate ${invalidMsg.completed ? 'InputInvalid' : ''}`}
                                name='completed_at'
                                type='date'
                                defaultValue={initialFormData.task.completed_at || ''}
                            />
                            <div className='InputInvalidMessage'>{invalidMsg.completed}</div>
                        </div>
                    </div>


                    <label htmlFor='text'>Consigne</label>
                    <InputText
                        textValue={taskText}
                        updateTextValueCallback={(newValue) => setTaskText(newValue)}
                        withTags
                        placeholder={'Décrivez la consigne'}
                        error={invalidMsg.text !== ''}
                    />
                    <div className='InputInvalidMessage'>{invalidMsg.text}</div>


                    <label htmlFor='comment'>Commentaire</label>
                    <InputText
                        textValue={commentText}
                        updateTextValueCallback={(newValue) => setCommentText(newValue)}
                        withTags
                        placeholder={'Commentaire'}
                    />
                </div>
                <ActionBox>
                    <SecondaryButton
                        buttonType='button'
                        onClick={dismissModal}>
                        Annuler
                    </SecondaryButton>
                    <PrimaryButton
                        buttonType='submit'
                        onClick={handleSave}>
                        Enregistrer
                    </PrimaryButton>
                </ActionBox>
            </form>
        </Modal>
    )
}

/**
 * The DateMode Selection input
 * @param date {String} Selected due date
 * @param defaultValue {Number} Actual due date mode
 */
function SelectDateMode({ date, defaultValue }) {
    const demoDate = useMemo(() => date || new DateExtended().toHTMLDateString(), [date]);

    return (
        <div  className={styles.DueDateMode}>
            <label htmlFor='due_date_type'>Mode d'affichage</label>
            <select name='due_date_type' defaultValue={defaultValue}>
                <option value={DATE_MODE.DAY}>
                    Jour ( {DateExtended.displayDate(demoDate, DATE_MODE.DAY)} )
                </option>
                <option value={DATE_MODE.WEEK}>
                    Semaine ( {DateExtended.displayDate(demoDate, DATE_MODE.WEEK)} )
                </option>
                <option value={DATE_MODE.MONTH}>
                    Mois ( {DateExtended.displayDate(demoDate, DATE_MODE.MONTH)} )
                </option>
                <option value={DATE_MODE.QUARTER}>
                    Trimestre ( {DateExtended.displayDate(demoDate, DATE_MODE.QUARTER)} )
                </option>
                <option value={DATE_MODE.HALF}>
                    Semestre ( {DateExtended.displayDate(demoDate, DATE_MODE.HALF)} )
                </option>
                <option value={DATE_MODE.YEAR}>
                    Année ( {DateExtended.displayDate(demoDate, DATE_MODE.YEAR)} )
                </option>
            </select>
        </div>
    )
}

/**
 * Custom search element list used
 * @param object {{name: {String}}} The element to display
 * @param setSearchValue {Function} set search value with clicked value card
 */
function EnterpriseCard({object, setSearchValue}) {
    const enterpriseName = useMemo(() => object.name ? object.name.toUpperCase() : '', [object]);

    return (
        <div className={styles.EnterpriseCard} onClick={() => setSearchValue(enterpriseName)}>
            {enterpriseName}
        </div>
    )
}