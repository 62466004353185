import {useRef} from "react";

import './ModalContainer.css';


/**
 * The modal container template obfuscating background and building a centered modal box
 * @param props {Object} Any allowed div props. Override default modal behaviour and style
 * @returns {JSX.Element} The Modal Container Component
 */
function ModalContainer({onBackgroundClick, children, ...modalprops}) {
    const ObfuscateBackground = useRef(null);
    return (
        <div ref={ObfuscateBackground} className={'ObfuscateBackground'} onClick={(event) => {
            if (event.target === ObfuscateBackground.current) {
                onBackgroundClick && onBackgroundClick();
            }
        }}>
            <div className={'ModalPosition ModalStyle'} {...modalprops}>
                {children}
            </div>
        </div>
    );
}

export default ModalContainer;