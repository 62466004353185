import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Portal,
    Stack,
} from "@mui/material";
import React, {useMemo, useState} from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PhotoIcon from "@mui/icons-material/Photo";
import ImageViewer from "./ImageViewer";
import AttachmentGrid from "./AttachmentGrid";


export default function AttachmentTaggerModal({projectId, dismiss, onSelect: onSubmit}) {
    const [fileList, setFileList] = useState([]);
    const [rowSelection, setRowSelection] = useState([]);

    const selectedFiles = useMemo(() => (
        fileList.filter((file => rowSelection.includes(file.id)))
    ), [fileList, rowSelection]);

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    CHOISIR UNE PIÈCE JOINTE À TAGUER
                </DialogTitle>
                <DialogContent>
                   <AttachmentGrid projectId={projectId} selectionHook={{value: rowSelection, set: setRowSelection}} extractDataInto={setFileList} />
                </DialogContent>
                <DialogActions sx={{p: 0}}>
                    <Stack flexDirection={'row'} gap={2} alignItems={'center'} sx={{ overflow: 'hidden', maxHeight: '130px', py: 1, px: 2}} >
                        <Stack
                            flexDirection={'row'}
                            gap={1}
                            justifyContent={'flex-end'}
                            flexWrap={'wrap'}
                            sx={{ overflowY: 'auto', maxHeight: '130px', p: 1 }}
                        >
                            {selectedFiles.map((file) => (
                                <Tag key={file.id} file={file} />
                            ))}
                        </Stack>
                        <Button variant={'outlined'} color={'secondary'} onClick={dismiss}>
                            Annuler
                        </Button>
                        <Button
                            variant={'outlined'}
                            disabled={selectedFiles.length === 0}
                            onClick={() => onSubmit(selectedFiles)}
                        >
                            Taguer
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

function Tag({file}) {
    const [showPreview, setShowPreview] = useState(false);

    return (
        <>
            <Chip
                label={`${file.title}`}
                contentEditable={false}
                component='span'
                clickable
                icon={file.type === 'pdf'
                    ? <InsertDriveFileIcon fontSize='inherit'/>
                    : <PhotoIcon  fontSize='inherit'/>
                }
                onClick={() => setShowPreview(true)}
                sx={{
                    fontFamily: '"DinPro", sans-serif',
                    fontSize: '0.7em',
                    fontStyle: 'italic',
                }}
            />
            {showPreview &&
                <ImageViewer
                    file={file}
                    fileRequestPath={`/attachment/${file.filename}`}
                    dismiss={() => setShowPreview(false)}
                />
            }
        </>
    )
}