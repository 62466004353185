import {useEffect} from "react";
import { Provider } from 'react-redux';
import {ErrorBoundary} from "react-error-boundary";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import store from 'Redux/store';
import {FatalError} from "Redux/Notification/Notification";
import Dashboard from 'components/Dashboard/Dashboard';
import HomePage from "components/HomePage/HomePage";
import EnvironmentFrame from "utilities/EnvironmentFrame";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";

import 'assets/css/global.css';
import 'assets/css/button.css';
import 'assets/css/checkbox.css';
import 'assets/css/materialIcons.css';
import 'assets/css/Input.scss';
import ValecoTheme from "assets/muiTheme";

function App() {
    const { accounts, instance } = useMsal();

    useEffect(() => {
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
        }
    }, [accounts])


    return (
        <ErrorBoundary fallbackRender={({error}) => <FatalError data={{
            message: 'Une erreur grave lors du rendu de l\'application est survenu.',
            log: error.stack.toString()}} />
        }>
            <UnauthenticatedTemplate>
                <HomePage />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Provider store={store}>
                    <ValecoTheme>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
                            <Dashboard />
                        </LocalizationProvider>
                    </ValecoTheme>
                </Provider>
            </AuthenticatedTemplate>
            <EnvironmentFrame />
        </ErrorBoundary>
    );
}

export default App;