import {useEffect, useMemo, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import {loginRequest, apiConfig} from "config";

import { ReactComponent as ValecoSvg } from 'assets/img/Otis-logo.svg';
import './HomePage.css';

/**
 * Build the welcome page for non-logged-in user
 */
function HomePage() {
    const { instance } = useMsal();

    return (
        <div className='HomePage'>
            <div className='BlueSide'>
                <div className='ValecoBox'>
                    <div className='FirstLine'>
                        <h2></h2>
                        <ValecoSvg className='ValecoSvg'/>
                    </div>
                    <h2>COMPTES-RENDUS</h2>
                </div>
                <CheckService handleLogin={() => instance.loginRedirect(loginRequest)}/>
            </div>
        </div>
    )
}

/**
 * Manage display according to api status
 * @param handleLogin {Function} A function prototype to run on login click
 */
function CheckService({handleLogin}) {
    const [isApiAvailable, setIsApiAvailable] = useState(false);
    const [isApiOnCall, setIsApiOnCall] = useState(true);
    const [apiCallCount, setApiCallCount] = useState(0);
    const nextTryInSeconds = useMemo(() => Math.max(10, Math.min(60, Math.abs(apiCallCount * 10))), [apiCallCount]);

    useEffect(() => {
        if (isApiAvailable < 1) {
            setIsApiOnCall(true);
            checkApiAvailability()
                .then(avail => {
                    if(avail) {
                        setIsApiAvailable(true);
                    }
                    else {
                        setTimeout(() => setApiCallCount(apiCallCount + 1), nextTryInSeconds * 1000);
                    }
                    setIsApiOnCall(false);
                });
        }

    }, [isApiAvailable, apiCallCount]);

    const checkApiAvailability = async () => {
        try {
            const {status} = await fetch(`${apiConfig.host}/check`)
            return status === 200;
        }
        catch (_) {
            return false;
        }
    };

    return (
        <>
            {isApiAvailable && <LoginButton handleLogin={handleLogin} />}
            {isApiOnCall && <ServicesCheckMessage />}
            {(!isApiAvailable && !isApiOnCall) && <ServicesUnavailable nextCheckInSeconds={nextTryInSeconds}/>}
        </>
    )
}

/**
 * The login button displayed on homepage
 * @param handleLogin {Function} A function prototype to run on login click
 */
function LoginButton({handleLogin}) {
    return (
        <button className='LoginBtn' onClick={handleLogin}>
            <span className='material-icons'>login</span>
            Connexion
        </button>
    )
}

/**
 * The box displayed when app is checking for api availability
 */
function ServicesCheckMessage() {
    return (
        <div className='CheckServices Fetching bold'>
            <span className='material-icons'>rotate_right</span>
            <span>Vérification de la disponibilité de l'application</span>
        </div>
    )
}

/**
 * The box displayed when app is idling waiting for next try
 * @param nextCheckInSeconds {Number} The initial time to wait before new request to api
 */
function ServicesUnavailable({nextCheckInSeconds}) {
    const [timeoutId, setTimeoutId] = useState(null);
    const [countdown, setCountdown] = useState(nextCheckInSeconds);
    useEffect(() => {
        if(countdown > 0) {
            const id = setTimeout(() => setCountdown(countdown - 1), 1000);
            setTimeoutId(id);
        }
        return () => clearTimeout(timeoutId);
    }, [countdown]);

    return (
        <div className='CheckServices Unavailable'>
            <div className='material-icons'>warning</div>
            <div>
                <div className='bold'>L'application n'est pas disponible pour le moment</div>
                <div>Nouvelle vérification dans {countdown} secondes</div>
            </div>

        </div>
    )
}

export default HomePage;