import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from 'customHook/useFetchData';
import {actions as myActions} from 'Redux/meSlice'

import ApiSyncManager from "utilities/ApiSyncManager";
import Header from './Header/Header';
import NavigationBar from './NavigationBar/NavigationBar';
import Footer from './Footer/Footer';
import Notification from "Redux/Notification/Notification";

import AttachmentManager from "./Pages/AttachmentManager/AttachmentManager";
import UnloadedContent from './Pages/UnloadedContent/UnloadedContent';
import Loading from './Pages/Loading/Loading';
import Report from './Pages/Report/Report';
import RightManager from "./Pages/RightManager/RightManager";
import Workflow from "./Pages/Workflow/Workflow";

import styles from './Dashboard.module.css'

const Page = {
    AttachmentManager: AttachmentManager,
    UnloadedContent: UnloadedContent,
    Loading: Loading,
    Report: Report,
    RightManager: RightManager,
    Workflow: Workflow
};

/**
 * Dashboard of application,
 * display data about projects, meetings and reports
 * @returns {JSX.Element}
 */
function Dashboard() {
    const activePage = useSelector(state => state.page);
    const dispatch = useDispatch();
    const apiSyncManager = new ApiSyncManager();

    const loadingData = useFetchData({
        modalTitle: 'Chargement des données',
        steps: [
            {
                do: async () => {
                    const favoriteProjects = await ApiSyncManager.fetchJson(
                        `/participant/me/favoriteProjects`,
                        {method: 'GET'});
                    const grantedMeetings = await ApiSyncManager.fetchJson(
                        `/participant/me/grantedMeetings`,
                        {method: 'GET'});
                    const details = await ApiSyncManager.fetchJson(
                        `/participant/me`,
                        {method: 'GET'});
                    dispatch(myActions.init({favoriteProjects, grantedMeetings, details}))
                },
                name: 'Récupération de mes préférences',
                msgOnError: 'La récupération des informations sur mon compte a échouée',
                successProgress: 75
            },
            {
                do: async () => await apiSyncManager.getProjectDetails(dispatch),
                name: 'Chargement du menu...',
                msgOnError: 'La récupération des données a échouée !',
                successProgress: 95
            }
        ]
    });

    return (
        <div className={styles.Dashboard}>
            <Header />
            <div className={styles.DashboardBody}>
                <div className={styles.DashboardNav}>
                    <NavigationBar/>
                </div>
                <div className={styles.DashboardReport}>
                    {React.createElement(Page[activePage.page])}
                </div>
            </div>
            <footer>
                <Footer/>
            </footer>
            <Notification />
            {loadingData}
        </div>
    );
}

export default Dashboard