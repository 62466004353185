import {createElement, useMemo} from "react";

import {ReactComponent as Audit} from './AuditSymbol.svg';
import {ReactComponent as Build} from './BuildSymbol.svg';
import {ReactComponent as Participant} from './ParticipantSymbol.svg';
import {ReactComponent as Planning} from './PlanningSymbol.svg';
import {ReactComponent as Task} from './TaskSymbol.svg';
import {ReactComponent as Weather} from './WeatherSymbol.svg';

const SYMBOL = {
    'audit' : {symbol: Audit, alt: 'Un mur observé par une loupe'},
    'build' : {symbol: Build, alt: 'Une grue'},
    'participant' : {symbol: Participant, alt: 'Trois personnes autours d\'une table de réunion, logo valeco en fond'},
    'planning' : {symbol: Planning, alt: 'Un agenda'},
    'task' : {symbol: Task, alt: 'Une planche avec des cases à cocher'},
    'weather' : {symbol: Weather, alt: 'Un nuage cachant le soleil, un eclair en haut a droite et de la pluie'},
};

/**
 * Create an icon base on an icon name
 * @param iconName  {String} The icon svg to create
 */
function ModulesIcons({iconName}) {
    const iconExist = useMemo(() => SYMBOL[iconName] !== undefined, [iconName]);
    return (
        <div>
            {iconExist ? createElement(SYMBOL[iconName].symbol, {alt: SYMBOL[iconName].alt}) : ''}
        </div>

    );
}

export default ModulesIcons;