import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useDragAndDrop from "customHook/useDragAndDrop";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Portal,
    Typography,
    Stack
} from "@mui/material";
import ApiSyncManager from "utilities/ApiSyncManager";
import FilePreview from "feature/FileExplorer/FilePreview";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";

export default function AttachmentSortModal({open, reportId, supTitle, dismiss}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {
        onDrag,
        onDragEnd,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDragStart,
        onDrop,
        setList,
        list
    } = useDragAndDrop([])

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            ApiSyncManager.fetchJson(`/report/${reportId}/attachments`, {method: 'GET'})
                .then(attachmentList => {
                    setList(attachmentList);
                    setIsLoading(false);
                })
                .catch((error) => {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        title: 'Échec du chargement des pièces jointes',
                        message: '',
                        log: error.stack.toString()
                    }));
                });
        }
    }, [open]);

    const onsubmit = () => {
        setIsLoading(true);
        ApiSyncManager.fetchJson(
            `/report/${reportId}/attachments`,
            {
                method: 'PUT',
                body: list.map((item, index) => ({
                    id: item.id,
                    id_planning: item.id_planning,
                    id_list: item.id_list,
                    id_task: item.id_task,
                    id_text: item.id_text,
                    display_order: index
                }))
            })
            .then(() => {
                setIsLoading(false);
                dismiss();
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'La sauvegarde à échoué',
                    message: '',
                    log: error.stack.toString()
                }));
                setIsLoading(false);
            });
    }

    return(
        <Portal container={window.document.body}>
            <Dialog open={open} maxWidth={'md'} fullWidth>
                <DialogTitle flexDirection='column' sx={{gap: 0, alignItems: 'start'}}>
                    <Typography fontSize={'0.8rem'} fontWeight={'lighter'} color={'grey'} textTransform={'uppercase'}>
                        {supTitle}
                    </Typography>
                    <Typography textTransform={'uppercase'}>Organisation des pièces jointes</Typography>
                </DialogTitle>
                <DialogContent>
                    <Loading show={isLoading} />
                    {(!isLoading && list.length === 0) &&
                        <Typography color={'grey'} fontStyle={'italic'}>
                            Aucune pièce jointe tagué dans le CR
                        </Typography>
                    }
                    <Stack>
                        {list.map((attachment, index) => (
                            <DraggableCard
                                key={attachment.filename}
                                attachment={attachment}
                                position={index}
                                onDrag={onDrag}
                                onDragEnd={onDragEnd}
                                onDragEnter={event => onDragEnter(event, index)}
                                onDragLeave={onDragLeave}
                                onDragOver={onDragOver}
                                onDragStart={event => onDragStart(event, index)}
                                onDrop={onDrop}
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={dismiss}>
                        Annuler
                    </Button>
                    <Button variant='outlined' color='primary' onClick={onsubmit}>
                        Enregistrer les changements
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

function DraggableCard({attachment, position, ...props}) {
    const paperStyle = {
        p: 2,
        background: 'linear-gradient(173deg, rgba(250,250,250,1) 0%, rgba(245,245,245,1) 26%, rgba(239,239,239,1) 100%)',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        cursor: 'grab'
    }

    return (
        <Box
            sx={{py:0.5}}
            draggable
            {...props}
            onDragOver={(event) => event.preventDefault()}
        >
            <Paper sx={paperStyle} elevation={2} onDragEnter={e => e.stopPropagation()}>
                <DragHandleIcon />
                <Box maxWidth={'80px'}>
                    <FilePreview file={attachment}/>
                </Box>
                <Typography sx={{flexGrow: 1}}>{attachment.title}</Typography>
                <Typography
                    variant={'h2'}
                    component={'div'}
                    fontSize={32}
                    sx={{
                        px: 4,
                        fontStyle: position === attachment.display_order ? 'normal' : 'italic'
                    }}
                >
                    {position + 1}
                </Typography>
            </Paper>
        </Box>
    )
}

function Loading({show}) {
    return (
        <Backdrop open={show} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}