import {createElement, useEffect, useRef, useState} from "react";

import styles from './SearchInput.module.css'

/**
 * Reusable search bar with internal display management of autocomplete list
 * @param searchValue {String} The value write inside the search bar
 * @param setSearchValue {Function} The Function prototype to handle search value updates
 * @param inputPlaceholder {String} The search input placeholder
 * @param filteredList {Array<Any>} The datalist of filtered element to display in autocomplete list
 * @param cardComponent {React.Component} The React Component to use in order to build the autocomplete list
 * @param customCardProps {Object} props to pass to the React Component passed by **cardComponent**
 * @param props {React.ComponentProps} Any props accepted by input HTML element
 */
function SearchInput({ searchValue, setSearchValue, inputPlaceholder, filteredList = [], cardComponent, customCardProps, ...props}) {
    const inputComponent = useRef(null);
    const listBlockComponent = useRef(null);
    const [isInputFocus, setIsInputFocus] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (filteredList.length > 0 && isInputFocus) {
            listBlockComponent.current.style.width = `${inputComponent.current.clientWidth}px`;
        }

        return () => {
            timeoutId && clearTimeout(timeoutId)
        }
    }, [filteredList, inputComponent, listBlockComponent, isInputFocus, timeoutId]);

    return (
        <div style={{flexGrow: 1}}>
            <div ref={inputComponent} className={styles.SearchInput}>
                <div className='material-icons'>search</div>
                <input
                    type='text'
                    value={searchValue}
                    onChange={event => setSearchValue(event.target.value)}
                    placeholder={inputPlaceholder}
                    onBlur={() => setTimeoutId(setTimeout(() => {
                        setTimeoutId(null);
                        setIsInputFocus(false);
                    }, 250))}
                    onFocus={() => setIsInputFocus(true)}
                    {...props}
                />
            </div>
            {(filteredList.length > 0 && isInputFocus) &&
                <div ref={listBlockComponent} className={styles.FilteredList}>
                    <div className={styles.FilteredObject}>
                        {filteredList.map((object, index) =>
                            createElement(
                                cardComponent,
                                {
                                    key:index,
                                    object: object,
                                    ...customCardProps
                                }
                            )
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

export default SearchInput;

/**
 * Default card to use with searchInput search element list used
 * @param displayedProperty {String} the property to display in card
 * @param handleOnClick {Function} gives clicked object related card
 */
export function Card({object, displayedProperty, handleOnClick}) {
    return (
        <div className={styles.Card} onClick={() => handleOnClick(object)}>
            {object[displayedProperty]}
        </div>
    )
}