import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Portal,
    Skeleton
} from "@mui/material";
import {useEffect, useState} from "react";
import ApiSyncManager from "utilities/ApiSyncManager";
import {formatFilesSize} from "./utilities";
import {grey} from "@mui/material/colors";
import PdfViewer from "./PdfViewer";

function ImageViewer({file, fileRequestPath, dismiss}) {
    const [fileBlob, setFileBlob] = useState(null);
    useEffect(() => {
        ApiSyncManager.getAttachment(fileRequestPath, {
            method: 'GET'
        }).then(file => setFileBlob(file));
    }, []);

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'xl'} fullWidth>
                <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column'}}>
                    <DialogTitle
                        sx={{
                            bgcolor: 'primary.light',
                            borderBottom: '1px solid black',
                            borderColor: 'primary.dark',
                            display: 'flex',
                            alignItems: 'end',
                            gap: '10px'
                        }}
                    >
                        <Box>{file.title.toUpperCase()} </Box>
                        <Box sx={{paddingBottom: '3px'}}>
                            {fileBlob === null
                                ? <Skeleton sx={{width: '50px', height: '20px'}} component={'span'}/>
                                : <Box sx={{color: 'var(--lightGrey)', fontSize: '0.7rem'}}>{formatFilesSize(fileBlob.size)}</Box>
                            }
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={dismiss}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <span className='material-icons'>close</span>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{padding: '0'}}>
                        <Box sx={{
                            m: '40px',
                            display: 'flex',
                            gap: '10px',
                            flexWrap: 'wrap',
                            height: 'calc(100% - 80px)',
                            justifyContent: 'stretch'
                        }}>
                            {fileBlob === null
                                ? <Skeleton variant='rectangular' height='100%' width='100%' />
                                : <RenderFile file={file} blob={fileBlob}/>
                            }
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            backgroundColor: grey[100],
                            borderTop: '1px solid black',
                            borderColor: grey[500],
                            justifyContent: 'left'
                        }}
                    >
                        {file.description
                            ? file.description
                            : <Box sx={{color: grey[500], fontStyle: 'italic'}}>Aucune Description</Box>
                        }
                    </DialogActions>
                </Box>
            </Dialog>
        </Portal>
    )
}

export default ImageViewer;

function RenderFile({file, blob}) {
    if (file.type === 'pdf') {
        return (
            <PdfViewer blobFile={blob} file={file}/>
        )
    }
    else {
        return <img
            src={URL.createObjectURL(blob)}
            alt={`${file.name}: ${file.description}`}
            style={{objectFit: 'contain'}}
            height='100%'
            width='100%'/>
    }
}