import ModalContainer from "components/Reusable/ModalContainer/ModalContainer";
import './LoadingModal.css';

/**
 * A modal template useful to create synchronous loading bar
 * preventing user to make an action while application needs data in order to work properly
 * @param title {String} The modal displayed title
 * @param progress {Number} The progress level to display. Range {0-100}
 * @param step {String} The short message indicating current step
 * @returns {JSX.Element} The modal component
 */
function LoadingModal({title, progress, step}) {
    return (
        <ModalContainer className={'LoadingModal'}>
            <div className={'ModalTitle'}>{title}</div>
            <div>
                <div className={'LoadingCentered'}>
                    <span className={'LoadingText'}>{progress}%</span>
                    <div className={'LoadingBox'}>
                        <div className={'LoadingBar'} style={{width: `${progress}%`}}></div>
                    </div>
                </div>
                <div className={'StepText'}>{step}</div>
            </div>
        </ModalContainer>
    )
}

export default LoadingModal;