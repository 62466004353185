import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions as poolActions} from "Redux/poolSlice";
import {actions, actions as pageActions, page} from "Redux/pageSlice";

import DateExtended from "utilities/DateExtended";
import ApiSyncManager from "utilities/ApiSyncManager";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";

import styles from './Workflow.module.scss';

/**
 * The page for handling user meeting right
 */
function Workflow() {
    const dispatch = useDispatch();
    const stepName = ['BROUILLON', 'EN COURS DE RÉVISION', 'PUBLIÉ'];
    const {report_id, reportNumber, writingDate, step} = useSelector(state => {
        const reportData = state.pool.reportData;
        return {
            report_id: reportData.report_details.report_id,
            reportNumber: reportData.report_details.report_number,
            writingDate: reportData.report_details.writing_date,
            step: reportData.report_details.report_is_closed + reportData.report_details.report_is_published
        }
    });

    const handleClose = async (event) => {
        try {
            dispatch(pageActions.setPage({page: page.Loading, data: (event.ctrlKey && event.altKey)}));
            const report = await ApiSyncManager.fetchJson(`/report/${report_id}/close`,{method: 'PUT'});
            dispatch(poolActions.setReportData(report));
            const reportList = await ApiSyncManager.fetchJson(`/project/detailedList`, {method: 'GET'});
            dispatch(poolActions.setProjectList(reportList));
            dispatch(actions.setPage({page: page.Report, data:{fullView: true}}));
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Erreur',
                message: 'Le compte-rendu n\'a pas été clos correctement',
                log: error.stack.toString()
            }));
        }
    };

    const handleReOpen = async (event) => {
        try {
            dispatch(pageActions.setPage({page: page.Loading, data: (event.ctrlKey && event.altKey)}));
            const report = await ApiSyncManager.fetchJson(`/report/${report_id}/reOpen`,{method: 'PUT'});
            dispatch(poolActions.setReportData(report));
            const reportList = await ApiSyncManager.fetchJson(`/project/detailedList`, {method: 'GET'});
            dispatch(poolActions.setProjectList(reportList));
            dispatch(actions.setPage({page: page.Report, data:{fullView: false}}));
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Erreur',
                message: 'La réouverture en rédaction du compte rendu ne s\'est pas déroulé correctement',
                log: error.stack.toString()
            }));
        }
    };

    const handlePublish = async (event) => {
        try {
            dispatch(pageActions.setPage({page: page.Loading, data: (event.ctrlKey && event.altKey)}));
            const report = await ApiSyncManager.fetchJson(`/report/${report_id}/publish`,{method: 'PUT'});
            dispatch(poolActions.setReportData(report));
            const reportList = await ApiSyncManager.fetchJson(`/project/detailedList`, {method: 'GET'});
            dispatch(poolActions.setProjectList(reportList));
            dispatch(actions.setPage({page: page.Report, data:{fullView: false}}));
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Échec de publication',
                message: 'Une erreur s\'est produite lors de la publication du compte rendu',
                log: error.stack.toString()
            }));
        }
    };

    return (
        <div className={styles.RightManager}>
            <WorkflowHeader>
                <div className={styles.HeaderStep}>{stepName[step]}</div>
            </WorkflowHeader>
            <div className={styles.Body}>
                <div className={styles.Title}>
                    <span>COMPTE RENDU N°{reportNumber} DU {writingDate ? DateExtended.formatInputDateToFRString(writingDate) : '__/__/____'}</span>
                </div>
                <div className={styles.ListBox}>
                    <div className={styles.WorkFlow}>
                        <StepBox currentStep={step} step={0}>
                            <div className={styles.Step}>{stepName[0]}</div>
                            <div className='material-icons'>expand_more</div>
                        </StepBox>
                        <div className={styles.StepButtonBox}>
                            <StepButton currentStep={step} doneStep={0} doneText='RÉDACTION TERMINÉE' onClick={handleClose}>CLORE LA RÉDACTION</StepButton>
                        </div>
                        <StepBox currentStep={step} step={1}>
                            <div className='material-icons'>expand_more</div>
                            <div className={styles.Step}>{stepName[1]}</div>
                            <div className='material-icons'>expand_more</div>
                        </StepBox>
                        <div className={styles.StepButtonBox}>
                            <CancelStepButton currentStep={step} atStep={1} onClick={handleReOpen}>REFUSER</CancelStepButton>
                            <StepButton currentStep={step} doneStep={1} doneText='PUBLIÉ' onClick={handlePublish}>PUBLIER</StepButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Workflow;

/**
 * Build page header providing option to add element inside the header
 * @param children {[JSX.Element]} children encapsulated in component
 */
function WorkflowHeader({children}) {
    const project = useSelector(state => state.pool.reportData.report_details.project_name);
    const meeting = useSelector(state => state.pool.reportData.report_details.meeting_name);

    return (
        <>
            <div className={styles.Header}>
                <h2>WORKFLOW DE PUBLICATION</h2>
                { children }
            </div>
            <div className={styles.SubHeader}>
                <h3>{project.toUpperCase()}</h3>
                <h3>{meeting}</h3>
            </div>
        </>
    )
}

/**
 * Create standardize Step Button for workflow component
 * @param currentStep {Number} the value of current step
 * @param doneStep {Number} Step value while is done
 * @param doneText {String} Text value while step has been completed
 * @param onClick {Function} Function prototype to execute on button click
 * @param children {String} encapsulated button name to run step
 */
function StepButton({currentStep, doneStep, doneText, onClick, children}) {
    const disabled = useMemo(() => (currentStep < doneStep) ? styles.Disable : '', [currentStep, doneStep]);
    const done = useMemo(() => (currentStep > doneStep) ? styles.Done : '', [currentStep, doneStep]);
    const handleClick = (event) => {
        if(currentStep === doneStep) {
            onClick(event)
        }
    };

    return (
        <div className={`${styles.StepButton} ${disabled} ${done}`} onClick={handleClick}>{done !== '' ? doneText :children}</div>
    )
}

/**
 * Create standardize Cancel Button for workflow component
 * @param currentStep {Number} the value of current step
 * @param atStep {Number} If **currentStep === atStep**, button is displayed, it's hidden otherwise
 * @param onClick {Function} Function prototype to execute on cancel button click
 * @param children {String} encapsulated button name to cancel step
 */
function CancelStepButton({currentStep, atStep, onClick, children}) {
    return atStep === currentStep && (
        <div className={`${styles.StepButton} ${styles.Cancel}`} onClick={onClick}>{children}</div>
    )
}

/**
 * Intermediate component indicating the step Name
 * @param currentStep {Number} the value of current step
 * @param step {Number} the step value of describe by this component
 * @param children {String} The step name encapsulated by component
 */
function StepBox({currentStep, step, children}) {
    const disabled = useMemo(() => (currentStep < step) ? styles.Disable : '', [currentStep, step]);

    return (
        <div className={`${styles.StepBox} ${disabled}`}>{children}</div>
    )
}