import './LoadSpinner.css';

export default function LoadSpinner({size}) {
    return(
        <div className='lds-roller-scale' style={{width: size, height: size}}>
            <div className="lds-roller" style={{transform: `scale(${size/80})`}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    )
}