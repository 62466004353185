import {Button, ButtonGroup, Stack, TextField, Typography} from "@mui/material";
import {Add, Delete, Edit, Search} from "@mui/icons-material";
import {DataGrid, frFR, gridClasses} from "@mui/x-data-grid";
import FilePreview from "./FilePreview";
import moment from "moment/moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ApiSyncManager from "../../utilities/ApiSyncManager";
import Normalize from "../../utilities/Normalize";
import {createNotification, NOTIFICATION_TYPE} from "../../Redux/Notification/notificationSlice";
import {useDispatch} from "react-redux";
import FileUpdateModal from "./FileUpdateModal";
import AttachementUploadModal from "./AttachementUploadModal";


export default function AttachmentGrid({projectId, selectionHook, extractDataInto: exportDataTo}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(null);

    useEffect(() => {
        exportDataTo && exportDataTo(fileList);
    }, [fileList, exportDataTo])

    const refreshData = useCallback(async () => {
        setIsLoading(true);
        const fetchedAttachmentList = await ApiSyncManager.fetchJson(
            `/attachment/list/${projectId}`,
            {
                method: 'GET'
            }
        )

        setFileList(fetchedAttachmentList);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const filteredFileList = useMemo(() => {
        return fileList.filter(
            file => Normalize.string(file.title).includes(Normalize.string(searchValue))
                || Normalize.string(file.description).includes(Normalize.string(searchValue))
        );
    }, [fileList, searchValue])

    function handleDelete(file) {
        ApiSyncManager.fetchJson(`/attachment/${file.filename}`, {
            method: 'DELETE'
        })
            .then(() => {
                const cpAttachmentList = [...fileList];
                const fileIndex = fileList.findIndex(element => file.id === element.id);
                cpAttachmentList.splice(fileIndex, 1);
                setFileList(cpAttachmentList);
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.SUCCESS,
                    title: `${file.title} supprimé`,
                    message: '',
                    log: ''
                }));
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Échec de suppression',
                    message: error.message.slice(-3) === '423'
                        ? 'La pièce jointe est taguée'
                        : 'La suppression n\'a pas été prise en compte',
                    log: error.stack.toString()
                }))
            });
    }

    return (
        <Stack>
            <Stack
                flexDirection={'row'}
                justifyContent={'flex-end'}
                mb={2.5}
                alignItems={'center'}
                gap={2}
            >
                <TextField
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    variant={'filled'}
                    InputProps={{
                        startAdornment: <Search />,
                        placeholder: 'Rechercher un fichier par nom',
                    }}
                    inputProps={{style: {padding: '10px'}}}
                    size={'small'}
                    sx={{minWidth: '300px'}}
                />
                <Button
                    variant={'contained'}
                    size={'large'}
                    color={'secondary'}
                    startIcon={<Add />}
                    onClick={() => setShowUploadModal(true)}
                >
                    Ajouter un fichier
                </Button>
            </Stack>

            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                loading={isLoading}
                columns={[
                    {
                        field: 'filename',
                        headerName: 'Image',
                        width: 90,
                        renderCell: (param) => (
                            <FilePreview file={param.row} />
                        ),
                        sortable: false,
                    },
                    {
                        field: 'last_modified',
                        headerName: 'prise le',
                        width: 90,
                        valueFormatter: (params) => (
                            moment(params.value).format('DD/MM/YYYY')
                        )
                    },
                    {
                        field: 'title',
                        headerName: 'Nom',
                        flex: 0.5,
                        renderCell: (param) => (
                            <Stack>
                                <Typography fontWeight={'bold'}>{param.row.title}</Typography>
                                <Typography variant={'caption'}>{param.row.description}</Typography>
                            </Stack>
                        )
                    },
                    {
                        field: 'uploaded_timestamp',
                        headerName: 'Chargé le',
                        width: 110,
                        valueFormatter: (params) => (
                            moment(params.value).format('DD/MM/YYYY')
                        )
                    },
                    {
                        field: 'usage_sum',
                        headerName: 'Utilisation',
                        width: 100,
                        renderCell: (param) => (
                            <Stack fontSize={'1em'} fontStyle={'italic'}>
                                <Typography fontSize={'inherit'} whiteSpace={'pre-wrap'}>
                                    {param.row.usage_sum > 0
                                        ? `Tagué dans ${param.row.usage_sum} compte rendu`
                                        : `Aucune`
                                    }
                                </Typography>
                            </Stack>
                        ),
                        sortComparator: (a, b) => a - b
                    },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 65,
                        renderCell: (param) => (
                            <ButtonGroup fullWidth color={'secondary'} variant={'contained'} orientation={'vertical'}>
                                <Button onClick={() => setShowUpdateModal(param.row)}>
                                    <Edit />
                                </Button>
                                <Button
                                    color={'error'}
                                    variant={'outlined'}
                                    onClick={() => handleDelete(param.row)}
                                    disabled={param.row.usage_sum > 0}
                                >
                                    <Delete />
                                </Button>
                            </ButtonGroup>
                        ),
                        sortable: false
                    }
                ]}
                rows={filteredFileList}
                rowHeight={100}
                pagination
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                    sorting: {
                        sortModel: [{ field: 'uploaded_timestamp', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection={Boolean(selectionHook)}
                disableRowSelectionOnClick={Boolean(selectionHook)}
                rowSelection={selectionHook ? selectionHook.value : undefined}
                onRowSelectionModelChange={(newSelection) => selectionHook ? selectionHook.set(newSelection) : undefined}
                sx={{
                    mt: 1,
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                    },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                        outline: 'none',
                    },
                    minHeight: 500
                }}
            />
            {showUpdateModal && <FileUpdateModal file={showUpdateModal} close={setShowUpdateModal} attachmentList={fileList} setAttachmentList={setFileList}/>}
            {showUploadModal && (
                <AttachementUploadModal
                    projectId={projectId}
                    dismiss={() => {
                        refreshData();
                        setShowUploadModal(false)
                    }}
                />
            )}
        </Stack>
    )
}