import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {actions as poolActions, actions} from "Redux/poolSlice";
import {actions as pageActions, page} from "Redux/pageSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import Container from './Modules/Container';
import ProjectDetails, { Action } from "./ProjectDetails/ProjectDetails";
import WriterInput from "./Inputs/WriterInput";
import ReaderInput from "./Inputs/ReaderInput";
import ApiSyncManager from "utilities/ApiSyncManager";
import Participant from "./Participant/Participant";
import { DatePicker } from "@mui/x-date-pickers";
import {FormControlLabel, Switch, TextField} from "@mui/material";
import DateExtended from "utilities/DateExtended";
import moment from "moment";

import styles from './Report.module.scss';
import AttachmentSortModal from "feature/AttachmentSortModal/AttachmentSortModal";

/**
 * Create the report container
 */
function Report() {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const { fullView } = useSelector(state => state.page.data);
    const buffer = useSelector(state => state.pool.buffer);
    const reportId = useSelector(state => state.pool.reportData.report_details
        ? state.pool.reportData.report_details.report_id
        : -1);
    const [syncTimeout, setSyncTimeout] = useState();
    const syncCallback = useCallback(() => {
        const data = {report_id: reportId, ...buffer};
        ApiSyncManager.fetchJson(`/bulk/`, {method: 'POST', body : data})
            .then(() => dispatch(poolActions.clearBuffer()))
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.FATAL_ERROR,
                    title: 'Échec de Synchronisation',
                    message: 'La synchronisation des données du compte rendu a échoué. ',
                    log: error.stack.toString()
                }));
            });
    }, [reportId, buffer, dispatch]);

    useEffect(() => {
        clearTimeout(syncTimeout);
        const {creates, updates, deletes} = buffer;
        const createsCount = creates ? creates.length : 0;
        const updatesCount = updates ? updates.length : 0;
        const deletesCount = deletes ? deletes.length : 0;
        const waitingChanges = createsCount +updatesCount + deletesCount;
        if (waitingChanges > 0) {
            setSyncTimeout(setTimeout(syncCallback, 5000));
        }
    }, [buffer, syncCallback]);

    return (
        <section className={`${styles.ReportContainer} ${isEditing ? styles.EditMode: ''}`}>
            <ProjectDetails>
                <ReportUserActions />
            </ProjectDetails>
            <Workflow />
            <Participant />
            <div className={styles.ContainerList} style={{backgroundColor: fullView ? 'inherit' : 'var(--yellowBackground)'}}>
                <Container level={0} path={['content']} />
            </div>
        </section>
    );
}
export default Report;

/**
 * Build all granted action button for logged user
 */
function ReportUserActions() {
    const stepAction = ['Clore la rédaction', 'Validation de Relecture', 'Consulter le flux de validation'];
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const data = useSelector(state => state.page.data);
    const {reportDetails, meetingId, step} = useSelector(state => {
        const report = state.pool.reportData;
        return {
            reportDetails: report.report_details,
            meetingId: report.report_details.meeting_id,
            step: report.report_details.report_is_closed + report.report_details.report_is_published
        };
    });
    const isEditGranted = useSelector(state => {
        const grantedMeetings = state.me.grantedMeetings;
        return grantedMeetings.findIndex(({meeting_id}) => meeting_id === meetingId) > -1;
    });
    const [openAttachmentSortModal, setOpenAttachmentSortModal] = useState(false);

    return (
        <>
            {(isEditGranted && (step === 0))  && (
                <>
                    <Action icon='edit' onClick={() => dispatch(actions.setIsEditing(!isEditing))}>
                        {isEditing ? 'Arrêter la modification' : 'Modifier le compte-rendu'}
                    </Action>
                    {isEditing &&
                        <Action icon='format_list_numbered' onClick={() => setOpenAttachmentSortModal(true)}>
                            Organiser les pièces jointes pour l'impression
                        </Action>
                    }
                    <AttachmentSortModal
                        reportId={reportDetails.report_id}
                        supTitle={
                            `${reportDetails.project_name} 
                                / ${reportDetails.meeting_name} 
                                / compre rendu n°${reportDetails.report_number}`
                        }
                        open={openAttachmentSortModal}
                        dismiss={() => setOpenAttachmentSortModal(false)}
                    />
                </>
            )}
            {isEditGranted &&
                <>
                    <Action
                        icon='published_with_changes'
                        onClick={() => dispatch(pageActions.setPage({page: page.Workflow, data: {fullView: data.fullView}}))}
                    >
                        {stepAction[step]}
                    </Action>
                    <FormControlLabel
                        sx={{ color: 'white' }}
                        control={
                            <Switch
                                checked={data.fullView}
                                onChange={() => dispatch(pageActions.updateData({fullView: !data.fullView}))}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Mode déroulé"
                    />
                </>
            }
            {(step === 2) &&
                <Action
                    icon='picture_as_pdf'
                    onClick={() => ApiSyncManager.fetchPdf(
                        `/pdf/${reportDetails.report_id}.pdf`,
                        {method: 'GET'},
                        `${reportDetails.meeting_name} N°${reportDetails.report_number} du ${reportDetails.writing_date}`
                    )}
                >
                    Télécharger le pdf
                </Action>
            }
        </>
    );
}

/**
 * Display workflow information.
 * /!\ Only speculative as any writer can decide who is set as a writer or a proofreader and
 * component is not linked in any way to the implemented workflow process !
 */
function Workflow() {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const report = useSelector(state => state.pool.reportData);
    const [userList, setUserList] = useState([]);
    const [nextMeetingDate, setNextMeetingDate] = useState(report.report_details.next_meeting_date);

    useEffect(() => {
        ApiSyncManager.fetchJson(`/participant/list`, {
            method: 'GET'
        })
            .then((participants) => {
                const orderedList = participants.sort((a, b) => {
                    const concatA = `${a.firstname} ${a.lastname}`;
                    const concatB = `${b.firstname} ${b.lastname}`;
                    return concatA.localeCompare(concatB);
                }) || [];
                setUserList(orderedList);
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Échec de synchronisation',
                    message: 'La liste utilisateurs n\'ont pas été récupérés',
                    log: error.stack.toString()
                }));
            });
    }, []);

    function updateNextMeetingDate(selectedValue) {
        console.log(selectedValue)
        const value = selectedValue || moment( nextMeetingDate);
        if (value && value.isValid()) {
            const date = value.format('YYYY-MM-DD');
            if (DateExtended.isDateBeforeMax(date)) {
                dispatch(actions.updateReportData({
                    path: ["report_details"],
                    updates: [
                        {key: "next_meeting_date", value: date}
                    ]
                }));
            }
            else {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.WARNING,
                    title: 'Date invalide',
                    message: 'La date dépasse la date maximale autorisé par le système',
                    log: ''
                }));
            }
        }
        else {
            if(report.report_details.next_meeting_date !== null) {
                dispatch(actions.updateReportData({
                    path: ["report_details"],
                    updates: [
                        {key: "next_meeting_date", value: null}
                    ]
                }));
            }
            setNextMeetingDate(null);
        }
    }

    return (
        <div className={styles.Workflow}>
            <WriterInput userList={userList} isEditing={isEditing}/>
            <ReaderInput userList={userList} isEditing={isEditing}/>
            <div>
                {isEditing ?
                    <DatePicker
                        label="Prochaine réunion le"
                        value={nextMeetingDate}
                        onChange={(value) => setNextMeetingDate(value)}
                        onAccept={updateNextMeetingDate}
                        renderInput={(params) => <TextField {...params} onBlur={() => updateNextMeetingDate()}/>}
                    />
                : report.report_details.next_meeting_date &&
                    <>
                        <span>Prochaine Réunion le </span>
                        <span style={{fontWeight: 'bold'}}>
                            <span>{DateExtended.formatInputDateToFRString(report.report_details.next_meeting_date)}</span>
                        </span>
                    </>
                }
            </div>
        </div>
    )
}