import { createSlice } from '@reduxjs/toolkit';

export const poolSlice = createSlice({
    name: 'pool',
    initialState: {
        attachmentList: [],
        enterpriseList: [],
        participantList: [],
        participantRoleList: [],
        projectList: [],
        taskCategoryList: [],
        isEditing: false,
        reportData: {},
        buffer: {},
        reportParticipantList: [],
    },
    reducers: {
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setEnterpriseList: (state, action) => {
            state.enterpriseList = action.payload;
        },
        setAttachmentList: (state, action) => {
            state.attachmentList = action.payload;
        },
        setParticipantList: (state, action) => {
            state.participantList = action.payload;
        },
        setParticipantRoleList: (state, action) => {
            state.participantRoleList = action.payload;
        },
        setProjectList: (state, action) => {
            state.projectList = action.payload;
        },
        setTaskCategoryList: (state, action) => {
            state.taskCategoryList = action.payload;
        },
        setReportParticipantList: (state, action) => {
            state.reportParticipantList = action.payload ? action.payload.sort((a,b) => a.display_order - b.display_order) : [];
        },
        addInReportParticipantList: (state, action) => {
            const { object } = action.payload;
            const createAtIndex = object.display_order;
            const cpState = [...state.reportParticipantList];
            let cpBuffer = {...state.buffer};
            const recalculatedDisplayOrderState = cpState.map((element, index) => {
                if (element.display_order < createAtIndex) {
                    return element
                }
                else {
                    cpBuffer = sendUpdateToBuffer(
                        cpBuffer,
                        ['participants', element.participant_id],
                        'participants',
                        [
                            {key: 'id_participant', value: element.participant_id},
                            {key: 'display_order', value: index + 1}
                        ],
                        state.reportData.report_details.report_id);
                    return {...element, display_order: index + 1};
                }
            })
            recalculatedDisplayOrderState.splice(createAtIndex, 0, object);
            state.buffer = cpBuffer;
            state.reportParticipantList = recalculatedDisplayOrderState;
        },
        updateInReportParticipantList: (state, action) => {
            const { id, updates, options } = action.payload;
            const cpState = [...state.reportParticipantList];
            const participantIndex = cpState.findIndex(user => user.participant_id === id);
            for (const {key, value} of updates) {
                cpState[participantIndex][key] = value;
            }
            if (!options || options.doNotSend == null ) {
                state.buffer = sendUpdateToBuffer(
                    state.buffer,
                    ['participants', id],
                    'participants',
                    [
                        {key: 'id_participant', value: id},
                        ...updates
                    ],
                    state.reportData.report_details.report_id
                );
            }
            state.reportParticipantList = cpState;
        },
        reorderReportParticipantList: (state, action) => {
            const { id, setAtPosition } = action.payload;
            const cpState = [...state.reportParticipantList];
            let cpBuffer = {...state.buffer};

            const reOrderedUserIndex = cpState.findIndex(user => user.participant_id === id);
            const extractedUser = cpState.splice(reOrderedUserIndex, 1);
            cpState.splice(setAtPosition, 0, ...extractedUser);

            const recalculatedDisplayOrderState = cpState.map((element, index) => {
                if (element.display_order === index) {
                    return element
                }
                else {
                    cpBuffer = sendUpdateToBuffer(
                        cpBuffer,
                        ['participants', element.participant_id],
                        'participants',
                        [
                            {key: 'id_participant', value: element.participant_id},
                            {key: 'display_order', value: index}
                        ],
                        state.reportData.report_details.report_id);
                    return {...element, display_order: index};
                }
            })
            state.buffer = cpBuffer;
            state.reportParticipantList = recalculatedDisplayOrderState;
        },
        deleteFromReportParticipantList: (state, action) => {
            const { deleteIndex } = action.payload;
            const cpState = [...state.reportParticipantList];
            let cpBuffer = {...state.buffer};
            const deletedObject = cpState.splice(deleteIndex, 1);
            cpBuffer = sendDeleteToBuffer(cpBuffer, ['participants'], 'participants', deletedObject[0], state.reportData.report_details.report_id);
            const recalculatedDisplayOrderState = cpState.map((element, index) => {
                if (element.display_order === index) {
                    return element
                }
                else {
                    cpBuffer = sendUpdateToBuffer(
                        cpBuffer,
                        ['participants', element.participant_id],
                        'participants',
                        [
                            {key: 'id_participant', value: element.participant_id},
                            {key: 'display_order', value: index}
                        ],
                        state.reportData.report_details.report_id);
                    return {...element, display_order: index};
                }
            })
            state.buffer = cpBuffer;
            state.reportParticipantList = recalculatedDisplayOrderState;
        },
        setReportData: (state, action) => {
            const {report_details, participants, content} = action.payload
            state.isEditing = false;
            const participantList = participants ? participants : [];
            state.reportData = {report_details, participants: participantList, content};
            state.reportParticipantList = participantList.sort((a,b) => a.display_order - b.display_order);
        },
        createInReportData: (state, action) => {
            const path = action.payload.path;
            const object = action.payload.object;
            const cpState = {...state.reportData};
            addEntry(cpState, path, object);
            state.reportData = cpState;
        },
        updateReportData: (state, action) => {
            const path = action.payload.path;
            const updates = action.payload.updates;
            const component = action.payload.component;
            const cpState = {...state.reportData};
            const object = getEntry(cpState, path);
            for(const {key, value} of updates){
                object[key] = value;
            }
            setEntry(cpState, path, object);
            state.buffer = sendUpdateToBuffer(state.buffer, path, component, updates, state.reportData.report_details.report_id);
            state.reportData = cpState;
        },
        deleteFromReportData: (state, action) => {
            const path = action.payload.path;
            const deleteIndex = action.payload.deleteIndex;
            const component = action.payload.component;
            const cpState = {...state.reportData};
            const deletedObject = {...getEntry(cpState, [...path, deleteIndex])};

            deleteEntry(cpState, path, deleteIndex);

            if(component) {
                state.buffer =
                    sendDeleteToBuffer(
                        state.buffer,
                        path,
                        component,
                        deletedObject,
                        state.reportData.report_details.report_id
                    );
            }

            state.reportData = cpState;
        },
        clearReportData: (state) => {
            state.isEditing = false;
            state.reportData = {};
        },
        clearBuffer: (state) => {
            state.buffer = {};
        }
    }
});

/**
 * Récupère l'objet à modifier depuis l'état actuel
 * @param {state} state - l'état actuel
 * @param {Array} path - l'emplacement dans l'état actuel de l'objet à modifier 
 * @returns l'objet à modifier
 */
 function getEntry(state, path) {
    let object = {...state};
    path.forEach(element => {
        object = object[element];
    });
    return object;
}

/**
 * Replace l'état à l'emplacement spécifié par l'objet passé en paramètre
 * @param {state} state - l'état à modifier
 * @param {Object} data - la donnée à insérer
 * @param {Array<String|Int>} path - l'emplacement dans l'état actuel de l'objet à modifier
 * @returns le nouvel état à renvoyer au reducer
 */
function addEntry(state, path, data) {
    let object = {...state};
    path.forEach(element => {
        object = object[element];
    });
    object.push(data);
}

/**
 * Replace l'état à l'emplacement spécifié par l'objet passé en paramètre
 * @param {state} state - l'état à modifier
 * @param {Object} data - la donnée à insérer
 * @param {Array<String|Int>} path - l'emplacement dans l'état actuel de l'objet à modifier
 * @returns le nouvel état à renvoyer au reducer
 */
function setEntry(state, path, data) {
    let object = {...state};
    path.forEach(element => {
        object = object[element];
    });
    // noinspection ReuseOfLocalVariableJS
    object = data;
}

/**
 * Replace l'état à l'emplacement spécifié par l'objet passé en paramètre
 * @param {state} state - l'état à modifier
 * @param {Array<String|Int>} path - l'emplacement dans l'état actuel de l'objet à supprimer
 * @param {Int} index - l'index de l'élément à supprimer
 * @returns le nouvel état à renvoyer au reducer
 */
function deleteEntry(state, path, index) {
    let object = {...state};
    path.forEach(element => {
        object = object[element];
    });
    object.splice(index, 1);
}

/**
 * Ajoute les modifications au Buffer
 * @param {Object} buffer - L'état actuel du Buffer
 * @param {Array<String|Int>} path - Le chemin de l'objet modifié
 * @param {String} component - Component type affected by change
 * @param {Array<Object>} updates - Les modifications faites sur l'objet
 * @param {Int} reportId - L'Id du rapport actuellement chargé
 */
function sendUpdateToBuffer(buffer, path, component, updates, reportId) {
    const cpBuffer = {...buffer};
    const bufferUpdate = cpBuffer.updates ? cpBuffer.updates : [];
    const existingIndex = bufferUpdate.findIndex(item => item.path.toString() === path.toString());

    if (existingIndex >= 0 ){
        for(const {key, value} of updates){
            const keyIndex = bufferUpdate[existingIndex].updates.findIndex(item => item.key === key);
            if(keyIndex >= 0){
                bufferUpdate[existingIndex].updates[keyIndex].value = value;
            }
            else {
                bufferUpdate[existingIndex].updates.push({key: key, value: value})
            }
        }
    }
    else {
        bufferUpdate.push({path: path, component: component, updates: updates, report_id: reportId})
    }

    cpBuffer.updates = bufferUpdate;
    return cpBuffer;
}


/**
 * Ajoute les modifications au Buffer
 * @param {Object} buffer - L'état actuel du Buffer
 * @param {Array<String|Int>} path - Le chemin de l'objet modifié
 * @param {String} component - Component type affected by change
 * @param {Object} object - l'objet supprimé
 * @param {Int} reportId - L'Id du rapport actuellement chargé
 */
function sendDeleteToBuffer(buffer, path, component, object, reportId) {
    const cpBuffer = {...buffer};
    const bufferDelete = cpBuffer.deletes ? cpBuffer.deletes : [];

    bufferDelete.push({path: path, component: component, object: object, report_id: reportId});

    cpBuffer.deletes = bufferDelete;
    return cpBuffer;
}

export function getElement(fromState, toPath) {
    toPath.forEach(element => {
        fromState = fromState[element];
    });
    return fromState;
}

export const actions = poolSlice.actions;

export default poolSlice.reducer;