import {Modal, Header} from "./ModalParts";

import styles from './ConfirmModal.module.scss';


/**
 * ConfirmModal create configurable modal with a cancel button and a confirm or submit button
 * @param title {String} The Modal title
 * @param acceptButtonText {String} The text display inside validate button
 * @param onAcceptClick {Function} Function prototype used to handle onClick on the accept button
 * @param onCancelClick {Function} Function prototype used to handle onClick on the cancel button
 * @param props {React.ComponentProps} Used to pass children props to modal content part. Used for other accepted props
 */
export default function ConfirmModal(
    {title, acceptButtonText, onAcceptClick, onCancelClick, ...props}
) {

    return (
        <Modal>
            <Header title={title}/>
            <div className={styles.ConfirmModalContent}>
                {props.children}
            </div>
            <div className={styles.ConfirmModalButtonBox}>
                <div className={styles.CancelButton} onClick={onCancelClick}>Annuler</div>
                <div className={styles.AcceptButton} onClick={onAcceptClick}>{acceptButtonText}</div>
            </div>
        </Modal>
    )
}