
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useToggle from "customHook/useToggle";
import SearchInput from "components/Reusable/SearchInput/SearchInput.js";
import ProjectNav from "./ProjectNav/ProjectNav.js";
import Normalize from 'utilities/Normalize';

import './NavigationBar.scss';

/**
 * Create a navigation bar
 */
function NavigationBar() {
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, toggle] = useToggle(false);

    return (
        <nav className={`Nav ${isOpen && 'Retracted'}`}>
            <div className={`NavBody`}>
                <div className='NavSearch'>
                    <SearchInput
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        inputPlaceholder='Rechercher un projet par nom ou spv'
                    />
                </div>
                <Projects filter={searchValue} />
            </div>
            <div className={'NavButton'} onClick={toggle}>
                <span className={'NavButtonText'}>Menu</span>
                <span className='material-icons'>{isOpen ? 'chevron_right' :'chevron_left'}</span>
            </div>
        </nav>
    )
}

export default NavigationBar;

/**
 * Build project list and handle special state like empty case and filtering
 * @param filter {String} The text inside the searchBar
 * @returns {JSX.Element}
 */
function Projects({filter}) {
    const projectList = useSelector(state => state.pool.projectList);
    const favoriteList = useSelector(state => state.me.favoriteProjects);

    const filterProjectList = useMemo(() => {
        return projectList.filter(addable => (
            Normalize.string(addable.name).includes(Normalize.string(filter))
            || Normalize.string(addable.spv_name).includes(Normalize.string(filter))
            || Normalize.string(addable.spv_code).includes(Normalize.string(filter))
        ));
    }, [projectList, filter]);

    const sortedProjectList = useMemo(() => {
        if(filterProjectList.length > 0) {
            const favoriteProject = [];
            const notFavoriteProjects = [];
            const cpFilterProjectList = [...filterProjectList];
            const initialSort = cpFilterProjectList.sort((a, b) => a.name.localeCompare(b.name));
            initialSort.forEach(project => {
                (favoriteList.findIndex(({project_id}) => project_id === project.id) > -1)
                    ? favoriteProject.push(project)
                    : notFavoriteProjects.push(project);
            });
            return [...favoriteProject, ...notFavoriteProjects];
        }
        else {
            return [];
        }
    }, [filterProjectList, favoriteList]);

    return (
        <div className={'NavItem'}>
            {sortedProjectList.map(project => <ProjectNav key={project.id} project={project} />)}
        </div>
    )
}