import {useEffect, useState} from "react";
import ApiSyncManager from "utilities/ApiSyncManager";
import Normalize from "utilities/Normalize";
import {acceptedFiles} from "config";

import {Skeleton} from "@mui/material";
import pdfPreview from 'assets/img/pdfPreview.png';
import noImage from 'assets/img/noImage.png';

/**
 * Component for display the attachment preview
 * @param file {{
 *     filename: String,
 *     title: String,
 *     description: String|Null
 * }} - Attachment's Data use to display the file preview
 * @returns {JSX.Element}
 */
export default function FilePreview({ file, source }) {
    const [isLoading, setIsLoading] = useState(true);
    const [preview, setPreview] = useState(null);

    useEffect(() => {

        async function getAttachment(filename)  {

            const attachment = await ApiSyncManager.getAttachment(
                `/attachment/${filename}`,
                { method: 'GET' }
            );

            if(attachment.type !== 'text/html') {
                setPreview(URL.createObjectURL(attachment));
            }
            else {
                setPreview(noImage);
            }

            setIsLoading(false)
        }

        if(source) {
            setPreview(URL.createObjectURL(source));
            setIsLoading(false)
        }
        else if(acceptedFiles.other.includes(Normalize.string(file.type))) {
            setPreview(pdfPreview);
            setIsLoading(false);
        }
        else {
            getAttachment(file.filename);
        }


    }, [file?.filename, file?.type, source]);

    if(isLoading) return (
        <Skeleton height={80} width={70}/>
    )

    return (
        <img
            src={preview}
            alt={`Preview of ${file?.title || source?.renamed}`}
            loading='lazy'
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
            }}
        />
    )
}