import {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {actions, page} from "Redux/pageSlice";
import {actions as myActions} from "Redux/meSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import ApiSyncManager from "utilities/ApiSyncManager";
import useToggle from "customHook/useToggle";
import ReportNav, {CreateReport, ShowMore} from 'components/Dashboard/NavigationBar/ReportNav/ReportNav';
import {ActionBox, Header, Modal, PrimaryButton, SecondaryButton} from "components/Reusable/Modals/ModalParts";

import styles from './MeetingNav.module.scss';

/**
 * Create a meeting in navigation
 * @param meeting {{
 *     id: Number,
 *     name: String,
 *     picture_filename: String|Null,
 *     id_project: Number,
 *     type_name: String|Null,
 *     reportList: [Object]
 * }} Meeting's Data use to build component
 * @returns {JSX.Element}
 */
function MeetingNav({meeting}) {
    const dispatch = useDispatch();
    const [isOpen, toggle] = useToggle(false);
    const grantedMeetings = useSelector(state => state.me.grantedMeetings);
    const isGranted = useMemo(() => {
        return grantedMeetings.findIndex((grant) => grant.meeting_id === meeting.id) > -1;
    }, [grantedMeetings, meeting]);

    const handleClickRightManager = async () => {
        const apiSyncManager = new ApiSyncManager();
        const meetingGrant = await apiSyncManager.getMeetingGrant(meeting.id);
        const valecoUser = await ApiSyncManager.fetchJson('/participant/list/azure', {method: 'GET'});
        dispatch(actions.setPage({page: page.Loading}));
        if (!meetingGrant.error) {
            dispatch(actions.setPage({
                page: page.RightManager, 
                data: {meeting: meeting, grants: meetingGrant, valecoUser: valecoUser}
            }));
        }
        else {
            dispatch(actions.setPage({ page: page.UnloadedContent }));
        }
    };

    return (
        <div>
            <div className={styles.MeetingCard}>
                <div className={styles.BodyCard} onClick={toggle}>
                    <div className={`${styles.MeetingName}  ${isOpen && styles.isOpen}`}>{meeting.name}</div>
                </div>
                {isGranted &&
                    <div className={styles.GrantButton}
                         onClick={handleClickRightManager}><span className='material-icons'>manage_accounts</span></div>
                }
            </div>
            { isOpen && <Reports list={meeting.reportList} isGranted={isGranted}/> }
        </div>
    )
}

export default MeetingNav;

/**
 * The component displayed when no meeting are set into the project
 * @param projectId {Number} Id of project inside witch we are going to create a new meeting
 */
export function CreateMeeting({projectId}) {
    const [isModalDisplayed, toggleModal] = useToggle(false)
    return (
        <>
            <div className={styles.CreateMeeting} onClick={toggleModal}>
                <div className={styles.IconFrame}>
                    <span className={`material-icons ${styles.Icon}`}>add</span>
                </div>
                <div className={styles.ButtonName}>Créer une réunion</div>
            </div>
            {isModalDisplayed && <NewMeetingModal closeModal={toggleModal} projectId={projectId}/>}
        </>
    )
}

/**
 * Modal Form allowing user to create a new meeting
 * @param closeModal
 * @param projectId
 * @returns {JSX.Element}
 * @constructor
 */
function NewMeetingModal({closeModal, projectId}) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const [meetingTypeList, setMeetingTypeList] = useState([]);
    const [validMessage, setValidMessage] = useState({
        name: '',
        type: ''
    })
    useEffect(() => {
        ApiSyncManager.fetchJson('/meeting/listMeetingTypes', {method: 'GET'})
            .then(meetingTypes => setMeetingTypeList(meetingTypes))
            .catch(error => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Impossible de récupérer les types de réunion',
                    message: 'La récupération des types de réunion à échoué',
                    log: error.stack.toString()
                }));
            })
    }, []);

    const createNewMeeting = event => {
        event.preventDefault();
        const requestBody = {
            meeting_name: formRef.current.meeting_name.value,
            picture_filename: '',
            meeting_type_id: parseInt(formRef.current.meeting_type.value),
            project_id: projectId
        }
        const newValidMessage = {
            name: requestBody.meeting_name === '' ? 'Vous devez renseigner un nom de réunion !' : '',
            type: (isNaN(requestBody.meeting_type_id ) || requestBody.meeting_type_id <= 0) ? 'Vous devez choisir un modèle de réunion !' : ''
        }

        if(newValidMessage.name === '' && newValidMessage.type === '') {
            ApiSyncManager.fetchJson('/meeting/create', {method: 'POST', body: requestBody})
                .then(async () => {
                    const apiSyncManager = new ApiSyncManager();
                    const favoriteProjects = await ApiSyncManager.fetchJson(
                            `/participant/me/favoriteProjects`,
                            {method: 'GET'});
                    const grantedMeetings = await ApiSyncManager.fetchJson(
                        `/participant/me/grantedMeetings`,
                        {method: 'GET'});
                    dispatch(myActions.init({favoriteProjects, grantedMeetings}))
                    await apiSyncManager.getProjectDetails(dispatch);
                })
                .then(() => closeModal())
                .catch(error => {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        title: 'Échec',
                        message: 'La création de la réunion a échoué',
                        log: error.stack.toString()
                    }));
                })
        }
        else {
            setValidMessage(newValidMessage);
        }
    }

    return (
        <form ref={formRef}>
            <Modal>
                <Header title={`CRÉATION D'UNE NOUVELLE RÉUNION`} />
                <div className={styles.MeetingForm}>
                    <div>
                        <input type='text' className='Input' name='meeting_name' placeholder='Nom de la Réunion'/>
                        <div className='InputInvalidMessage'>{validMessage.name}</div>
                    </div>
                    <div>
                        <div className={styles.MeetingType}>
                            <label htmlFor='meeting_type'>Modèle de Réunion</label>
                            <select className='Input' name='meeting_type'>
                                {meetingTypeList.length === 0 && <option style={{fontStyle: 'italic'}}>Récupération des modèles...</option>}
                                {meetingTypeList.map(({id, name}) => <option key={id} value={id}>{name}</option>)}
                            </select>
                        </div>
                        <div className='InputInvalidMessage'>{validMessage.type}</div>
                    </div>
                </div>
                <ActionBox>
                    <SecondaryButton buttonType='button' onClick={closeModal}>Annuler</SecondaryButton>
                    <PrimaryButton buttonType='submit' onClick={createNewMeeting}>Créer</PrimaryButton>
                </ActionBox>
            </Modal>
        </form>
    )
}

/**
 * Build the given report list and special state like empty case and other available actions
 * @param list {[
 *     {
 *         id: Number,
 *         title: String|Null,
 *         number: Number,
 *         meeting_place: String|Null,
 *         writing_date: String|Null,
 *         next_meeting_date: String|Null,
 *         is_closed: Boolean,
 *         is_published: Boolean,
 *         pdf_url: String|Null,
 *         id_meeting: Number
 *     }
 * ]}
 * @param isGranted {Boolean} indicated if login user is granted to act on this meeting
 * The data list used to build component
 */
function Reports({list, isGranted}) {
    const DISPLAY_STEP = 5;
    const [itemDisplayCount, setItemDisplayCount] = useState(DISPLAY_STEP);

    const orderedReportList = useMemo(() => {
        const cpReportList = [...list];
        return cpReportList.sort((a, b) => b.number - a.number);
    }, [list]);


    const slicedReportList = useMemo(() => {
        return orderedReportList.slice(0, itemDisplayCount);
    }, [orderedReportList, itemDisplayCount]);

    const lastReport = useMemo(() => orderedReportList[0], [orderedReportList]);
    const displayCreateReport = useMemo(() => {
        return (!lastReport || lastReport.is_published) && isGranted;
    }, [lastReport]);

    return (
        <div className={styles.ReportGroup}>
            {displayCreateReport && <CreateReport fromReport={lastReport}/>}
            {slicedReportList.map(report => <ReportNav key={report.id} report={report}/> )}
            {orderedReportList.length > itemDisplayCount && <ShowMore onClick={() => setItemDisplayCount(itemDisplayCount + DISPLAY_STEP)}/>}
        </div>
    );
}