import { useMemo } from 'react';
import { useMsal } from "@azure/msal-react";
import { ReactComponent as LogoValeco } from 'assets/img/logoValeco.svg';

import styles from './Header.module.css'

/**
 * Create a header component
 */
function Header() {
    const { instance, accounts } = useMsal();
    const username = useMemo(() => accounts[0] && accounts[0].name, [accounts]);
    const handleLogout = () => instance.logoutRedirect();


    return (
        <header className={styles.Header}>
            <div className={styles.headerLogo}>
                <LogoValeco style={{height: '100%'}}/>
            </div>
            <div className={styles.headerTitle}>
                <h2>Comptes-Rendus</h2>
            </div>
            <div className={styles.headerAccount} onClick={handleLogout}>
                <span className={`material-icons ${styles.Icon}`}>logout</span>
                <div>
                    <span>{username}</span>
                    <span className={styles.LogoutText}>Se déconnecter</span>
                </div>
            </div>
        </header>
    );
}

export default Header;