import {useSelector} from "react-redux";

import { Date } from "components/Reusable/Inputs/Inputs";

import valecoSymbol from "assets/img/ValecoSymbolTransparent.png";
import backgroundImage from "assets/img/default-header-bg.png";

import styles from "./ProjectDetails.module.scss";


function ProjectDetails({children}) {
    const isEditing = useSelector(state => state.pool.isEditing);
    const {project, spvName, meeting, reportNumber, writingDate} = useSelector(state => {
        const reportData = state.pool.reportData;
        return {
            project: reportData.report_details.project_name.toUpperCase(),
            spvName: reportData.report_details.spv_name,
            meeting: reportData.report_details.meeting_name.toUpperCase(),
            reportNumber: reportData.report_details.report_number,
            writingDate: reportData.report_details.writing_date
        }
    });

    const background = `url(${valecoSymbol}) 102% / 30% no-repeat, 
                        linear-gradient(#213A8FDD, #213A8FDD), 
                        url(${backgroundImage}) center / cover no-repeat`;

    return (
        <div className={styles.Header} style={{background: background}}>
            <div className={styles.Details}>
                <h2>{spvName && spvName.toUpperCase()}</h2>
                <div className={styles.SpvName}>{project}</div>
                <h3>{meeting}</h3>
                <h5>
                    <span className={`material-icons ${styles.icon}`}>description</span>
                    <span>COMPTE RENDU N°{reportNumber} DU </span>
                    <Date
                        className={styles.CustomDateInputColor}
                        isEditing={isEditing}
                        currentValue={writingDate}
                        valuePath={['report_details']}
                        valueKey={'writing_date'}
                    />
                </h5>
            </div>
            <div className={styles.Actions}>
                {children}
            </div>
        </div>
    )
}

export default ProjectDetails;

export function Action({icon, children, onClick}) {

    return (
        <div className={styles.Action} onClick={onClick}>
            <span className={`material-icons ${styles.icon}`}>{icon}</span>
            <span>{children}</span>
        </div>
    )
}