import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {actions} from "Redux/poolSlice";
import {Autocomplete, TextField} from "@mui/material";

/**
 * Create the reader input
 */
function ReaderInput({ userList }) {
    const dispatch = useDispatch();
    const isReportEditing = useSelector(state => state.pool.isEditing);
    const reportData = useSelector(state => state.pool.reportData);
    const defaultValue = useMemo(() => {
        return userList.find(user => user.id === reportData.report_details.proofreader_id) || null;
    }, [userList, reportData]);

    function setProofreader(event, value) {
        dispatch(actions.updateReportData({
            path: ['report_details'],
            updates: [
                {key: 'proofreader_id', value: value ? value.id : null}
            ]
        }));
    }

    return (
        <div>
                {isReportEditing
                    ? <Autocomplete
                        disablePortal
                        id="reader"
                        value={defaultValue}
                        onChange={setProofreader}
                        options={userList}
                        getOptionLabel={(option) => option.firstname + " " + option.lastname.toUpperCase()}
                        sx={{ m: 1, width: 250 }}
                        renderInput={(params) => <TextField {...params} name='reader' label="Validé par" />}
                    />
                    : defaultValue &&
                    <>
                        <span>Validé par </span>
                        <span style={{fontWeight: 'bold'}}>
                            <span>{defaultValue.firstname + ' ' + defaultValue.lastname.toUpperCase()}</span>
                        </span>
                    </>
                }
        </div>
    );
}

export default ReaderInput;