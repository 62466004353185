import styles from "./ModalParts.module.scss";

/**
 * The default standardize Modal Header
 * @param title {String} The modal title
 */
export function Header({title}) {
    return (
        <div className={styles.Header}>
            <h2>{title ? title.toUpperCase() : ''}</h2>
        </div>
    )
}

/**
 * Create a modal without any content inside
 * @param onBackgroundClick {Function} Function prototype used to handle onClick on background
 * @param props {React.ComponentProps} Used to pass children props to modal content part.
 * Unused for other accepted props
 */
export function Modal({onBackgroundClick, ...props}) {
    return (
        <div className={styles.ScreenBackground} onClick={onBackgroundClick ? onBackgroundClick : undefined}>
            <div className={styles.ModalBackground} >
                {props.children}
            </div>
        </div>
    )
}

/**
 * Frame for content part of modal
 *  embedding content is built inside the frame
 */
export function ModalContent({children}) {
    return (
        <div className={styles.ModalContent}>
            {children}
        </div>
    )
}


export function ActionBox(props) {
    return (
        <div className={styles.ActionBox}>
            {props.children}
        </div>
    )
}

export function Button({buttonType, onClick, className, style, disabled, ...props}) {
    return (
            <button type={buttonType} disabled={disabled} className={`${styles.Button} ${className}`} style={style} onClick={onClick}>{props.children}</button>
        )
}

export function PrimaryButton({buttonType, onClick, disabled, ...props}) {
    return <Button buttonType={buttonType} className={styles.PrimaryButton} disabled={disabled} onClick={onClick}>{props.children}</Button>
}

export function SecondaryButton({buttonType, onClick, disabled, ...props}) {
    return <Button buttonType={buttonType} className={styles.SecondaryButton} disabled={disabled} onClick={onClick}>{props.children}</Button>
}