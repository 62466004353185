import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {createNotification} from "Redux/Notification/notificationSlice";
import LoadingModal from "components/Reusable/LoadingModal/LoadingModal";

/**
 * Custom Hook handling needs to fetch data before allowing user to continue use application
 * @param params {{
 *     modalTitle: String,
 *     steps: [{
 *         do: Function,
 *         name: String,
 *         msgOnError: String,
 *         successProgress: Number
 *      }]
 * }} Object describing step to execute and related data to construct the modal loading bar
 * @returns {JSX.Element} The modal loading bar
 */
function useFetchData(params) {
    const cpParams = {...params};
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState('');

    useEffect(() => {
        const load = async () => {
            try {
                for (const step of cpParams.steps) {
                    setStep(step.name);
                    await step.do();
                    setProgress(step.successProgress);
                }
            } catch (err) {
                dispatch(createNotification({
                    type: 'fatalError',
                    message: 'Une récupération de données nécessaire à l\'application à échoué.',
                    log: err.stack.toString()
                }));
            }
        };
        load().finally(() => setIsLoading(false));
    }, []);

    return isLoading && <LoadingModal title={params.modalTitle} progress={progress} step={step}/>;
}

export default useFetchData;