import { useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';

import './RichTextToolbar.modules.scss';

/**
 * Create the RichTextToolbar for enriched Text
 */
export default function RichTextToolbar() {
    return (
        <div className='Toolbar' >
            <ButtonStyle format='bold'/>
            <ButtonStyle format='italic'/>
            <ButtonStyle format='underline'/>
            <ToolbarSpacer />
            <TextColorButton>
                <ColorOption color="black"/>
                <ColorOption color="red"/>
                <ColorOption color="blue"/>
                <ColorOption color="green"/>
            </TextColorButton>
        </div>
    )
}

/**
 * Créer un bouton pour gérer du style
 * @param {String} format - le style géré par le bouton (bold | italic | underline)
 */
function ButtonStyle({format}) {
    const editor = useSlate();
    return (
        <div 
            className={`ToolbarButton`}
            onClick={() => toggleStyle(editor, format)}
            onMouseDown={event => event.preventDefault()}
        >
            <div className={`StyleButton ${isMarkActive(editor, format) && 'active'}`}>
                <span className='material-icons'>format_{format}</span>
            </div>
        </div>
    );
}


/**
 * Créer le bouton de selection de couleur de texte
 */
function TextColorButton(props) {
    const editor = useSlate();
    const [color, setColor] = useState('black');
    const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(true);

    useEffect(() => {
        Editor.addMark(editor, 'color', color);
    }, [color, editor]);

    const onColorSelect = (event) => {
        event.preventDefault();

        if (event.target.className === 'ColorOption') {
            const newColor = event.target.style.backgroundColor;
            setColor(newColor);
        }
    };

    const toggleColorSelector = () => setIsColorSelectorOpen(!isColorSelectorOpen);

    return (
        <div className='ToolbarButton' onClick={() => Editor.addMark(editor, 'color', color)}>
            <div className='TextColorButtonIcon' >
                <span className='material-icons' style={{color: color}}>format_color_text</span>
            </div>
            <div className='TextColorButtonDropDown' onClick={toggleColorSelector} >
                <span className='material-icons' style={{color: 'black'}}>{isColorSelectorOpen ? 'arrow_drop_down' : 'arrow_right'}t</span>
            </div>
            <div className='ColorSelector' onClick={onColorSelect} hidden={isColorSelectorOpen} >
                {props.children}
            </div>
        </div>
    );
}

/**
 * Céer une option de couleur selectionnable depuis le composant TextColorButton
 * @param {String} color - une chaine de caratère représentant une couleur tel qu'en css
 */
function ColorOption({color}) {
    return (
    <div 
        style={{backgroundColor: color}}
        className='ColorOption' 
        onMouseDown={event => event.preventDefault()}
    ></div>
    )
} 

/**
 * Créer une barre verticale ustilisable dans la Barre d'Outils
 */
function ToolbarSpacer() {
    return <div className="ToolbarSpacer"></div>
}


/**
 * Gère le basculement des styles
 * @param {Editor} editor
 * @param {String} format le style à gérer (bold | italic | underline)
 */
function toggleStyle(editor, format){
    if (isMarkActive(editor, format)){
        Editor.removeMark(editor, format);
    }
    else {
        Editor.addMark(editor, format, true);
    }
}

/**
 * Verifie si un style est actif
 * @param {Editor} editor
 * @param {String} format le style à vérifier (bold | italic | underline)
 * @returns Boolean
 */
function isMarkActive (editor, format){
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false
}