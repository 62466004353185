import {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { actions } from 'Redux/pageSlice';
import { UserCard, ActionButton } from "components/Reusable/UserCard/UserCard";

import styles from './RightManager.module.scss';
import ApiSyncManager from "utilities/ApiSyncManager";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

/**
 * The page for handling user meeting right
 * @returns {JSX.Element}
 */
function RightManager() {
    const meeting = useSelector(state => state.page.data.meeting);

    return (
        <div className={styles.RightManager}>
            <RightManagerHeader />
            <div className={styles.Body}>
                <div className={styles.Title}>RÉDACTEURS</div>
                <p className={styles.Description}>
                    <span>Définissez les utilisateurs pouvant rédiger un compte rendu dans </span>
                    <span className={styles.Meeting}>{meeting.name}</span>
                </p>
                <GrantedUser />
            </div>
        </div>
    )
}

export default RightManager;

/**
 * Build page header providing option to add element inside the header
 * @param children {[JSX.Element]} children encapsulated in component
 * @returns {JSX.Element}
 */
function RightManagerHeader({children}) {
    const meeting = useSelector(state => state.page.data.meeting);

    return (
        <>
            <div className={styles.Header}>
                <h2>GESTION DES RÉDACTEURS</h2>
                { children }
            </div>
            <div className={styles.SubHeader}>
                <h3>{meeting.name}</h3>
            </div>
        </>
    )
}


/**
 * The list of the granted users and revoking action implementation
 * @returns {JSX.Element}
 */
function GrantedUser() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.page.data);
    const {id: myId} = useSelector(state => state.me.details)
    const displayUser = useMemo(() => {
            return [...data.grants].sort((a, b) => a.lastname.localeCompare(b.lastname));
        }, [data]);
    const [inputValue, setInputValue] = useState('');
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const deleteUserGrant = async (user_id) => {
        const apiSyncManager = new ApiSyncManager();
        const newGrantedUsersList = await apiSyncManager.deleteGrant(data.meeting.id, user_id);
        dispatch(actions.updateData({meeting: data.meeting, valecoUser: data.valecoUser, grants: newGrantedUsersList}));
    };

    const handleGrantUser = async (event, user) => {
        setAutocompleteValue(null)
        event.preventDefault();
        setIsFetching(true);
        const newGrantedUsersList = await ApiSyncManager.fetchJson(
            `/meeting/${data.meeting.id}/grant/${user.id}`,
            {method: 'PUT'});
        dispatch(actions.updateData({meeting: data.meeting, valecoUser: data.valecoUser, grants: newGrantedUsersList}));
        setInputValue('');
        setIsFetching(false);
    }

    return (
        <div className={styles.ListBox}>
            <Autocomplete
                disablePortal
                id="grant"
                options={data.valecoUser.filter(user => data.grants.find(grant => grant.id === user.id) === undefined)}
                getOptionLabel={(option) => option.firstname + " " + option.lastname + " " + option.mail}
                renderOption={(props, option) => <li {...props} key={option.id}>{option.firstname} {option.lastname.toUpperCase()}</li>}
                sx={{ m: 1}}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name='writer'
                        label="Ajouter un Rédacteur"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment:
                                <>
                                    {isFetching && <CircularProgress color="inherit" size={20}/>}
                                    {params.InputProps.endAdornment}
                                </>
                        }}
                    />
                }
                disabled={isFetching}
                onChange={handleGrantUser}
                value={autocompleteValue}
                inputValue={inputValue}
                onInputChange={(_, value) => setInputValue(value)}
            />
            {data.grants.length === 0 && <p>Aucun Rédacteur définit</p>}
            {displayUser.map(user => (
                <UserCard key={user.id} user={user}>
                    {(data.grants.length > 1 && user.id !== myId) &&
                        <ActionButton
                            text='Retirer des Rédacteur'
                            icon='delete_forever'
                            backgroundColor='#AC0404'
                            textColor='var(--white)'
                            onClick={() => deleteUserGrant(user.id)}
                        />
                    }
                </UserCard>
            ))}
        </div>
    )
}