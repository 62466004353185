import {
    createTheme,
    ThemeProvider
} from "@mui/material";
import DinProRegular from './font/DinPro/EnBWDINPro-Regular.ttf'

const colorTheme = createTheme({
    palette: {
        primary: {
            light: '#F8F5ED',
            main: '#F7A600'
        },
        secondary: {
            main: '#213A8F'
        },
        grey: {
            main: '#A9A9A9'
        }
    },
    typography: {
        fontFamily: 'DinPro, sans-serif'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'DinPro';
                    font-weight: normal;
                    src: local('DinPro') local('EnBWDINPro-Regular') url(${DinProRegular}) format('truetype');
                }
            `
        }
    }
});

const valecoTheme = createTheme(colorTheme, {
    components: {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'end',
                    gap: '10px',
                    color: colorTheme.palette.secondary.main,
                    backgroundColor: colorTheme.palette.primary.light,
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: colorTheme.palette.primary.dark
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px !important'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'end',
                    backgroundColor: colorTheme.palette.grey.light,
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: colorTheme.palette.grey.main
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: '150px'
                }
            }
        }
    }
});

export default function ValecoTheme(props) {
    return (
            <ThemeProvider theme={valecoTheme}>
                {props.children}
            </ThemeProvider>
    );
}