import { useMemo } from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Divider, Stack } from "@mui/material";
import packageJson from '../../../package.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

/**
 *  Documentation about PDF Viewer @ https://react-pdf-viewer.dev/
 **/
const renderToolbar = (Toolbar) => (
    <Toolbar>
        {(slots) => {
            const {
                CurrentPageInput,
                EnterFullScreen,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ShowSearchPopover,
                Zoom,
                ZoomIn,
                ZoomOut,
            } = slots;
            return (
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    <Stack direction='row' alignItems='center'>
                        <EnterFullScreen />
                        <Divider orientation="vertical" variant="middle" flexItem/>
                        <ZoomOut />
                        <Zoom />
                        <ZoomIn />
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        <ShowSearchPopover />
                        <Divider orientation="vertical" variant="middle" flexItem/>
                        <GoToPreviousPage />
                        <GoToNextPage />
                        <span style={{display: 'inline-block', maxWidth: '50px'}}>
                            <CurrentPageInput/>
                        </span>
                        <span style={{margin: '0 10px'}}>/</span>
                        <span style={{marginRight: '10px'}}>
                            <NumberOfPages />
                        </span>
                    </Stack>
                </Stack>
            );
        }}
    </Toolbar>
);

export default function PdfViewer({blobFile}) {
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist']
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar
    });

    const Url = useMemo(() => URL.createObjectURL(blobFile), []);
    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
            <Viewer
                fileUrl={Url}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
            />
        </Worker>
    );
}

