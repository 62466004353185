import React, { useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions as myActions} from "Redux/meSlice";
import {actions, page} from "Redux/pageSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import ApiSyncManager from "utilities/ApiSyncManager";
import MeetingNav, {CreateMeeting} from 'components/Dashboard/NavigationBar/MeetingNav/MeetingNav';

import styles from './ProjectNav.module.scss';
import {IconButton, Tooltip} from "@mui/material";
import {DashboardCustomize} from "@mui/icons-material";


/**
 * Create a project in navigation
 * @param project {{
 *     id: Number,
 *     name: String,
 *     description: String|Null,
 *     spv_id: String,
 *     spv_name: String,
 *     meetingList: [Object]
 * }} Project's Data use to build components
 */
function ProjectNav({project}) {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const pageSlice = useSelector(state => state.page);

    const isAttachementManagerOpen = pageSlice.page === page.AttachmentManager && pageSlice.data?.project.id === project.id;

    const handleClickAttachmentManager = async () => {
        dispatch(actions.setPage({page: page.Loading}));
        const attachmentList = await ApiSyncManager.fetchJson(
            `/attachment/list/${project.id}`,
            {
                method: 'GET'
            }
        );
        dispatch(actions.setPage({
            page: page.AttachmentManager,
            data: {project: project, fileList: attachmentList}
        }));
    };

    return (
        <div className={styles.ProjectFolder}>
            <div className={`${styles.ProjectCard} ${isOpen ? styles.IsOpen : ''}`}>
                <FavoriteProject projectId={project.id}/>
                <div className={styles.ProjectInfo} onClick={() => setIsOpen(!isOpen)}>
                    <div className={styles.ProjectName}>{project.name}</div>
                </div>
                <Tooltip title={'Gérer les Fichiers du Projet'}>
                    <IconButton
                        onClick={handleClickAttachmentManager}
                        color={isAttachementManagerOpen ? 'primary' : 'default'}
                        sx={{m: 'auto'}}
                    >
                        <DashboardCustomize />
                    </IconButton>
                </Tooltip>
            </div>
            {isOpen && <Meetings projectId={project.id} list={project.meetingList}/>}
        </div>
    )
}

export default ProjectNav;

/**
 * The component displayed when no project are set into the nav
 */
export function CreateProject() {
    return (
        <div className={styles.ImportProject}>
            <div className={styles.IconFrame}>
                <span className={`material-icons ${styles.Icon}`}>add</span>
            </div>
            <div className={styles.ButtonName}>Importer un projet</div>
        </div>
    )
}

/**
 * The favorite component handling changing status
 * @param projectId {Number} The related project id of the button
 */
function FavoriteProject({projectId}) {
    const dispatch = useDispatch();
    const isFavorite = useSelector(state => {
        const favorites = state.me.favoriteProjects;
        return (favorites.findIndex(({project_id}) => project_id === projectId) > -1);
    })
    const [isUpdating, setIsUpdating] = useState(false);

    const handleClick = async () => {
        if(!isUpdating) {
            try {
                setIsUpdating(true);
                const requestPath = `/project/${projectId}/${isFavorite ? 'remove' : 'set'}Favorite`;
                const updatedFavoriteList = await ApiSyncManager.fetchJson(requestPath, {method: 'PUT'});
                dispatch(myActions.updateFavorite(updatedFavoriteList))
            }
            catch (error) {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.WARNING,
                    title: 'La mise en favoris à échoué',
                    message: 'La mise en favoris n\'a pas été prise en compte',
                    log: error.stack.toString()
                }));
            }
            finally {
                setIsUpdating(false);
            }
        }
    }

    return (
        <div className={`${styles.IconFrame} ${styles.ProjectFolderButton}`} onClick={handleClick}>
            {isUpdating
                ? <div className={`material-icons ${styles.ProjectIsUpdating}`}>update</div>
                : <div className={`material-icons ${styles.FavoriteIcon} ${isFavorite && styles.ProjectIsFavorite}`}>star</div>
            }
        </div>
    )
}

/**
 * Build the given Meeting list and special state like empty case
 * @param list {[
 *     {
 *          id: Number,
 *          name: String,
 *          picture_filename: String|Null,
 *          id_project: Number,
 *          type_name: String|Null,
 *          reportList: [Object]
 *     }
 * ]}
 * The data list used to build component
 * @param projectId {Number} Related Project id to the meeting list
 * @returns {JSX.Element}
 */
function Meetings({projectId, list}) {
    const sortedList = useMemo(() => {
        const cpList = [...list];
        return list.length > 0 ? cpList.sort((a, b) => a.name.localeCompare(b.name)) : [];
    }, [list]);

    return (
        <div className={styles.MeetingsGroup}>
            {sortedList.map(meeting => <MeetingNav key={meeting.id} meeting={meeting} />)}
            <CreateMeeting projectId={projectId}/>
        </div>
    )
}