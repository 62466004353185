/**
 * Return SHA-256 hash of the given blob
 * @param file {File} The blob to analyse
 * @returns {Promise<string>} The calculated hash
 */
export async function getHashFromFile(file) {
    const fileBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}