import React, {useMemo, useState, useRef, useEffect} from "react";
import {createEditor, Editor, Range, Transforms, Text} from "slate";
import {Editable, ReactEditor, Slate, withReact} from "slate-react";
import { withHistory } from 'slate-history'
import RichTextToolbar from './RichTextToolbar/RichTextToobar';
import AttachmentTag, {insertAttachmentTag, withAttachmentTag} from "./AttachmentTag/AttachmentTag";
import styles from './InputText.module.scss';
import {useSelector} from "react-redux";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Switch
} from "@mui/material";
import AttachmentTaggerModal from "../FileExplorer/AttachmentTaggerModal";
import useToggle from "customHook/useToggle";
import { createPortal } from "react-dom";
import areSameObject from "../../utilities/areSameObject";

const EMPTY_SLATE_STRUCTURE = [{
    type: 'paragraph',
    children: [{
        text: ''
    }]
}]

/**
 * Transform serialized value into an object readable by Slate
 * @param value
 * @returns {[{children: [{text: (*|string)}], type: string}]|any}
 */
const formatValueForSlate = value => {
    if(value === null || value === undefined) {
        return EMPTY_SLATE_STRUCTURE;
    }
    else if(Array.isArray(value)){
        return value;
    }
    else {
        console.error('Invalid value passed to slate, resetting...');
        return EMPTY_SLATE_STRUCTURE;
    }
};

const renderElement = (props) => {
    switch (props.element.type) {
        case 'AttachmentTag':
            return <AttachmentTag {...props} />
        default:
            return <ParagraphElement {...props} />
    }
}

export function parseText(text) {
    return text.map((item, index) => (
        <div key={index}>
            {item.children.map((span, index) => (span.type
                ? (
                    <AttachmentTag key={index} element={span}/>
                )
                :(
                    <span key={index} style={{
                        color: span.color ? span.color : undefined,
                        textDecoration: span.underline ? 'underline' : undefined,
                        fontStyle: span.italic ? 'italic' : undefined,
                        fontWeight: span.bold ? 'bold' : undefined
                    }}>
                        {span.text}
                    </span>
                )

            ))}
        </div>
    ));
}



/**
 * Build an Input Text allowing writer to styled content or monitor changes between versions
 * @param textValue {String | JSON} - The text value
 * @param updateTextValueCallback {?function(newValue :String) :any} - handler providing new text value after changes
 * @param textValueHasChanged {Boolean} - When text is not styled, indicates true to put text in bold indicating that text changes
 * @param isRichText {Boolean} - When true, allow writer to style text, while false, enable monitoring of textValueHasChanged
 * @param toggleRichTextModeCallback {?function() :any} - Handler to swap rich text mode
 * @param withTags {?Boolean} - While True, allow writer to use tag system
 * @param placeholder {?String} - Input placeholder
 * @param error {?Boolean} - true in input is invalid
 * @param inputStyle {Object} - Override input style
 * @param props {Any}
 */
export default function InputText({
    textValue,
    updateTextValueCallback,
    textValueHasChanged,
    isRichText,
    toggleRichTextModeCallback,
    withTags,
    placeholder,
    error,
    inputStyle,
    ...props}) {

    const isInWritingMode = useSelector(state => state.pool.isEditing);
    const isEditing = useMemo(
        () => updateTextValueCallback !== undefined && isInWritingMode,
        [updateTextValueCallback, isInWritingMode]);
    const editor = useMemo(() => withHistory(withAttachmentTag(withReact(createEditor()))), []);
    const [tagTarget, setTagTarget] = useState(null);
    const [isFirstMount, setIsFirstMount] = useState(true);
    const parsedValue = useMemo(() => parseText(formatValueForSlate(textValue)), [textValue]);
    useEffect(() => {
        isFirstMount
            ? setIsFirstMount(false)
            : Transforms.setNodes(
                editor,
                {
                    bold: false,
                    italic: false,
                    underline: false,
                    color: 'black'
                },
                {
                    at: [],
                    match: (node) => Text.isText(node)
                }
            );
    }, [isRichText])

    const handleOnChange = (newValue) => {
        const isEmpty = areSameObject(newValue, EMPTY_SLATE_STRUCTURE);
        isEditing && updateTextValueCallback(isEmpty ? null : newValue);
        withTags && handleTag();
    }

    const handleTag = () => {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const letterBefore = Editor.before(editor, start, { unit: 'character' });
            const beforeRange = letterBefore && Editor.range(editor, letterBefore, start);
            const beforeText = beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^#$/);

            beforeMatch ? setTagTarget(beforeRange) : setTagTarget(null);
        }
        else {
            setTagTarget(null);
        }
    }

    return (
        <div className={styles.RichText} {...props}>
            {updateTextValueCallback
                ? (
                    <Slate
                        editor={editor}
                        value={formatValueForSlate(textValue)}
                        onChange={handleOnChange}
                    >
                        {isRichText && isEditing && <RichTextToolbar />}
                        <Editable
                            className={`${styles.EditableText} ${isEditing ? styles.isEditing : ''} ${error ? styles.Error : ''}`}
                            style={inputStyle}
                            readOnly={!isEditing}
                            renderElement={renderElement}
                            renderLeaf={props => <Leaf {...props} hasChanged={!isRichText && textValueHasChanged} />}
                            placeholder={placeholder}
                        />
                        {toggleRichTextModeCallback !== undefined && isEditing && <RichModeSelector isRichText={isRichText} toggleRichTextMode={toggleRichTextModeCallback}/>}
                        {tagTarget !== null && <TagUi editor={editor} tagTarget={tagTarget}/>}
                    </Slate>
                )
                : <div style={{fontWeight: textValueHasChanged ? 'bold' : undefined}}>{parsedValue}</div>
            }

        </div>
    );
}

/**
 * Slate use Leaf to render inline style specifications
 * @param props
 */
function Leaf (props) {
    const style = {
        fontWeight: (props.leaf.bold || props.hasChanged) ? 'bold' : 'normal',
        fontStyle: props.leaf.italic ? 'italic' : 'normal',
        textDecoration: props.leaf.underline ? 'underline' : 'none',
        color: props.leaf.color
    };

    return (
        <span {...props.attributes} style={style} >
            {props.children}
        </span>
    )
}

/**
 * Standard Block use by Slate
 */
function ParagraphElement(props) {
    return (
        <p {...props.attributes}>
            {props.children}
        </p>
    )
}

/**
 * Tag user UI
 */
function TagUi({editor, tagTarget}) {
    const ref = useRef();
    const [showFiles, toggleShowFiles] = useToggle(false);
    const { project_id } = useSelector(state => state.pool.reportData.report_details);

    useEffect(() => {
        if (tagTarget) {
            const { style } = ref.current;
            const domRange = ReactEditor.toDOMRange(editor, tagTarget);
            const rect = domRange.getBoundingClientRect();
            style.top = `${rect.top + window.scrollY - 9}px`;
            style.left = `${rect.left + window.scrollX + 24}px`;
        }
    }, [editor, tagTarget])

    return createPortal((
            <>
                <div
                    ref={ref}
                    className={styles.TagContextAction}
                    data-cy="tags-portal"
                >
                    <IconButton aria-label='Add Photo Tag' onClick={toggleShowFiles}>
                        <span className='material-icons'>add_photo_alternate</span>
                    </IconButton>
                </div>
                {showFiles && <AttachmentTaggerModal
                    projectId={project_id}
                    apiFileRequestPath='/attachment/##filename##'
                    dismiss={toggleShowFiles}
                    onSelect={(files) => {
                        files.forEach((file => insertAttachmentTag(editor, tagTarget, file)))
                    }}
                />}
            </>
        ), document.body);
}

function RichModeSelector({isRichText, toggleRichTextMode}) {
    const [open, setOpen] = useState(false);
    return (
        <Stack direction='row' alignItems='center' gap='4px' justifyContent='right'>
            <Box sx={{fontSize: '0.8rem', transform: 'translateY(-2px)', color: 'var(--darkGrey)'}}>
                Texte enrichi
            </Box>
            <Switch size='small'
                    checked={isRichText}
                    onChange={() => isRichText ? setOpen(true) : toggleRichTextMode()}
            />
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Êtes vous sûr de vouloir désactiver le texte enrichi ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        La désactivation du texte enrichi va entrainer la perte du style appliqué
                        et activer la mise en gras automatique en cas de modification,
                        <br />souhaitez-vous continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setOpen(false)}>Non</Button>
                    <Button variant='outlined' onClick={() => {
                        toggleRichTextMode();
                        setOpen(false);
                    }} autoFocus>Oui</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}