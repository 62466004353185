
import styles from "./UserCard.module.scss";
import { useMemo } from "react";

/**
 * A templated user info card
 * @param user {{
 *     id: Number,
 *     firstname: String,
 *     lastname: String,
 *     mail: String,
 * }} user data to display
 * @param children {[JSX.Element]} children encapsulated in component
 * @returns {JSX.Element}
 */
export function UserCard({user, children}) {
    const firstname = useMemo(() => user.firstname ? user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1).toLowerCase() : 'Unknown', [user]);
    const lastname = useMemo(() => user.lastname ? user.lastname.toUpperCase() : 'UNKNOWN', [user]);

    return (
        <div className={styles.UserBox}>
            <div className={styles.Firstname}>{firstname}</div>
            <div className={styles.Lastname}>{lastname}</div>
            <div className={styles.Company}>
                <div className={styles.Secondary}>{user.mail}</div>
            </div>
            {children}
        </div>
    )
}

/**
 * build the action on the side of the card
 * @param text {String} the action text
 * @param icon {String} material icons name
 * @param backgroundColor {String} button background color
 * @param textColor {String} button text and icon color
 * @param onClick {Function} on click handler
 * @returns {JSX.Element}
 */
export function ActionButton({text, icon, backgroundColor, textColor, onClick}) {

    return (
        <div className={styles.ActionBox} onClick={onClick}>
            <div className={styles.ActionButton} style={{backgroundColor: backgroundColor, color: textColor}}>
                <div className='material-icons'>{icon}</div>
                <div>{text}</div>
            </div>
        </div>
    )
}