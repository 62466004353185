import {useState} from "react";

export default function useDragAndDrop(initialDraggableList) {
    const [list, setList] = useState(initialDraggableList);
    const [handlingSort, setHandlingSort] = useState(false);
    const [dragged, setDragged] = useState(null);
    const [hovering, setHovering] = useState(null);

    const sortAttachmentList = (getIndex, setAtPos) => {
        setHandlingSort(true);
        const _attachmentList = [...list];
        const movingAttachment = _attachmentList.splice(getIndex, 1);
        _attachmentList.splice(setAtPos, 0, ...movingAttachment);
        setDragged({element: dragged.element, index: setAtPos})
        setList(_attachmentList);
    }

    const onDrag = (event) => {
        if(handlingSort || hovering === null || hovering.index === dragged.index) return;

        const rect = hovering.element.getBoundingClientRect();
        const height = hovering.element.clientHeight
        const middleOfHoveringElement = rect.top + (height / 2);

        if (event.clientY < middleOfHoveringElement
            && dragged.index !== (hovering.index - 1)) {
            //Position dragged object before hovered one
            sortAttachmentList(dragged.index, (hovering.index));
        }
        else if(event.clientY > middleOfHoveringElement
            && dragged.index !== (hovering.index + 1)) {
            //Position dragged object after hovered one
            sortAttachmentList(dragged.index, (hovering.index));
        }

        setHandlingSort(false);
    }

    const onDragEnd = (event) => {
        event.target.style.opacity = 1;
        setHovering(null);
        setDragged(null)
        window.document.body.removeChild(window.document.getElementById('dragged-element-image'))
    }

    const onDragEnter = (event, index) => {
        setHovering({element: event.target, index: index});
    }

    const onDragLeave = (event) => {
        event.preventDefault()
    }

    const onDragOver = (event) => {
        event.preventDefault()
    }

    const onDragStart = (event, index) => {
        const width = event.target.clientWidth;
        const rect = event.target.getBoundingClientRect();
        const clickedElementCoordinates = {x: event.clientX - rect.left, y: event.clientY - rect.top};
        const _target = event.target.cloneNode(true);
        _target.style.width = width + 'px';
        _target.style.position = 'relative';
        _target.id = 'dragged-element-image';

        window.document.body.appendChild(_target)
        event.dataTransfer.setDragImage(_target, clickedElementCoordinates.x, clickedElementCoordinates.y);
        event.target.style.opacity = 0.2

        setDragged({element: event.target, index: index});
    }

    const onDrop = (event) => {
        event.preventDefault()
    }

    return {
        onDrag,
        onDragEnd,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDragStart,
        onDrop,
        list,
        setList,
        dragged,
        hovering
    }
}