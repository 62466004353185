/**
 * Provides additional methods for normalizing a value
 */
 export default class Normalize {

    /**
     * Returns the value in lowercase and without accent
     * @param {String} value
     * @returns - A character string without accent and in lowercase
     */
    static string(value) {
        if (value) {
            value = value.toLowerCase();
            value = value.replace(/[ÀÁÂÃÄÅàáäâãåª]/g, 'a');
            value = value.replace(/[ÈÉÊËéêëè£]/g,'e');
            value = value.replace(/[ÒÓÔÕÖØóòôöõø¤°ºð]/g, 'o');
            value = value.replace(/[Œœ]/g, 'oe');
            value = value.replace(/[Ææ]/g, 'ae');
            value = value.replace(/[ÌÍÎÏíìîï¡]/g, 'i');
            value = value.replace(/[®]/g, 'r');
            value = value.replace(/[Çç©¢]/g, 'c');
            value = value.replace(/[×]/g, 'x');
            value = value.replace(/[¦]/g, 'l');
            value = value.replace(/[§$]/g, 's');
            value = value.replace(/[¥Ýýÿ]/g, 'y');
            value = value.replace(/[ÙÚÛÜµùúûü]/g, 'u');
            value = value.replace(/[Ññ]/g, 'n');
            value = value.replace(/[ßÞþ]/g, 'b');
            value = value.replace(/[Ð]/g, 'd');
            value = value.replace(/[¶]/g, 'p');
            value = value.replace(/[^a-z0-9]/g, ' ');
            return value;
        }
        else {
            return '';
        }
    }

    /**
     * Replace empty string by null for better semantics inside database.
     * @param string {String} the string to evaluate
     * @returns {null | String} return null for an empty string or the trimmed string in other case
     */
    static nullEmptyString(string) {
        const trimString = string.trim();
        return trimString === "" ? null : trimString;
    }

}