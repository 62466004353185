import {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions, getElement} from "Redux/poolSlice";
import {ContainerContext} from "./Container";
import ApiSyncManager from "utilities/ApiSyncManager";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import InputText from "feature/InputText/InputText";

import styles from './Modules.module.scss';
import {dark} from "@mui/material/styles/createPalette";

/**
 * Display Texts Components
 * @param {Array<String|Int>} path - Indicate path to the display data inside the data object
 */
function Text({ path }) {
    const texts = useSelector(state => getElement(state.pool.reportData, path));

    return (
        <>
            {texts.map(({id}, index) => <TextRow key={id} path={[...path, index]} />)}
            {texts.length === 0 && <AddButton name={'Ajouter un texte'}/>}
        </>
    )
}
export default Text;

function TextRow({path}) {
    const dispatch = useDispatch();
    const {id, text, isRichText, hasChanged} = useSelector(state => {
        const element = getElement(state.pool.reportData, path);
        return {
            id: element.id,
            text: element.text,
            isRichText: element.is_rich_text,
            hasChanged: element.parent && (element.parent.text !== element.text)
        }
    });

    const updateText = (newValue) => {
        dispatch(actions.updateReportData({
            path: path,
            component: 'text',
            updates: [
                {key: 'id', value: id},
                {key: 'text', value: newValue}
            ]
        }));
    }

    const toggleRichTextMode = () => {
        dispatch(actions.updateReportData({
            path: path,
            component: 'text',
            updates: [
                {key: 'id', value: id},
                {key: 'is_rich_text', value: !isRichText},
            ]
        }));
    }


    return <InputText
        inputStyle={{wordBreak: 'break-word'}}
        textValue={text}
        updateTextValueCallback={updateText}
        textValueHasChanged={hasChanged}
        isRichText={isRichText}
        toggleRichTextModeCallback={toggleRichTextMode}
        withTags={true}/>
}

/**
 * Generic Add button for text component
 * @param name {String} Button name
 */
function AddButton({ name }) {
    const isEditing = useSelector(state => state.pool.isEditing);
    const report_id = useSelector(state => state.pool.reportData.report_details.report_id);
    const dispatch = useDispatch();
    const context = useContext(ContainerContext);

    const createText = () => {
        const data = {
            text: null,
            isRichText: false,
            container_id: context.container.id,
            report_id: report_id,
        };

        ApiSyncManager.fetchJson(`/text/create`, {
            method: 'POST',
            body: data
        })
            .then(({id}) => {
                dispatch(actions.createInReportData({
                    path: [...context.reportDataPath, 'content'],
                    object: {
                        id: id,
                        text: null,
                        parent: null,
                        is_rich_text: false
                    }
                }));
            })
            .catch((error) => {
                dispatch(createNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    title: 'Échec d\'enregistrement',
                    message: 'Les modifications n\'ont pas été prise en compte',
                    log: error.stack.toString()
                }));
            });
    }

    return isEditing &&
        <div className={styles.AddBtn} onClick={() => createText()}>
            <span className='material-icons'>add</span>
            <span>{name}</span>
        </div>
}