import {useContext, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions, getElement} from "Redux/poolSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import useToggle from "customHook/useToggle";
import ApiSyncManager from "utilities/ApiSyncManager";
import DateExtended, {DATE_MODE} from "utilities/DateExtended";
import {ContainerContext} from "./Container";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText,
    FormLabel, IconButton, InputLabel, MenuItem, Paper,
    Portal, Select,
    Stack, TextField
} from "@mui/material";
import { grey } from '@mui/material/colors'
import {DatePicker} from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import InputText from "feature/InputText/InputText";

import styles from './Modules.module.scss';
import areSameObject from "utilities/areSameObject";

/**
 * Build a planning component
 * @param path {Array<String|Number>} Planning path inside Redux pool reportData
 */
function Planning({path}) {
    const planning = useSelector(state => getElement(state.pool.reportData, path) || []);
    const orderedList = useMemo(() => {
        return planning.map(({id, schedule}, index) => {
            return {
                schedule: schedule,
                Component : <PlanningRow key={id} path={[...path, index]}/>
            }
        }).sort((a, b) => DateExtended.sortAscendant(a.schedule, b.schedule));
    }, [planning]);

    return (
        <div className={styles.Table}>
            <AddButton />
            <table style={{width: '100%'}}>
                <tbody>
                {orderedList.map(({Component}) => Component)}
                </tbody>
            </table>
        </div>

    );
}

export default Planning;

/**
 * Build a planning row with data values
 * @param path {Array<String|Number>} Planning path inside Redux pool reportData
 */
function PlanningRow({path}) {
    const dispatch = useDispatch();
    const {report_details} = useSelector(state => state.pool.reportData);
    const isEditing = useSelector(state => state.pool.isEditing);

    const planning = useSelector(state => {
        const planning = getElement(state.pool.reportData, path);
        return {
            ...planning,
            dateHasChanged: planning.parent === null || (planning.parent.title !== planning.title),
            textHasChanged: planning.parent === null || !areSameObject(planning.parent.text, planning.text)
        }
    });

    const handleOnChange = (newValue) => {
        if (isEditing) {
            dispatch(actions.updateReportData({
                path: path,
                component: 'planning',
                updates: [
                    {key: 'id', value: planning.id},
                    {key: 'text', value: newValue}
                ]
            }));
        }
    }

    const setRichText = (newState) => {
        if (isEditing) {
            dispatch(actions.updateReportData({
                path: path,
                component: 'list',
                updates: [
                    {key: 'id', value: planning.id},
                    {key: 'is_rich_text', value: newState}
                ]
            }));
        }
    }

    return (
        <tr style={{
            width: '100%',
            backgroundColor: `${DateExtended.isDateWithinDatePeriod(report_details.writing_date, planning.schedule, planning.display_mode) 
                ? '#f6f6fa' 
                : 'inherit'}`
            }}
        >
            <td style={{verticalAlign: 'middle', maxWidth: '30%'}} className={`${planning.dateHasChanged ? 'hasChanged' : ''}`}>
                <div style={{marginBottom: '24px'}}>{DateExtended.displayDate(planning.schedule, planning.display_mode)}</div>
            </td>
            <td>
                <InputText
                    inputStyle={{wordBreak: 'break-word'}}
                    textValue={planning.text}
                    updateTextValueCallback={handleOnChange}
                    textValueHasChanged={planning.textHasChanged}
                    isRichText={planning.is_rich_text}
                    toggleRichTextModeCallback={() => setRichText(!planning.is_rich_text)}
                    placeholder='Aucun texte saisi'
                    withTags
                />
            </td>
            <ActionColumn path={path}/>
        </tr>
    )
}

/**
 * Build an add planning button handling form modal display
 */
function AddButton() {
    const isEditing = useSelector(state => state.pool.isEditing);
    const [isFormDisplay, toggle] = useToggle(false);

    return isEditing &&
        <>
            <div className={styles.AddBtn} onClick={toggle}>
                <span className='material-icons'>add</span>
                <span>Ajouter une ligne</span>
            </div>
            {isFormDisplay && <CreatePlanningForm dismissModal={toggle}/>}
        </>
}

/**
 * The planning creation form
 * @param dismissModal {Function} Function callback usable to dismiss the form modal
 */
function CreatePlanningForm({dismissModal}) {
    const context = useContext(ContainerContext);
    const dispatch = useDispatch();
    const [periodDate, setPeriodDate] = useState(moment.now());
    const [periodDateError, setPeriodDateError] = useState(false);
    const [periodMode, setPeriodMode] = useState(DATE_MODE.DAY);
    const [richText, setRichText] = useState(false);
    const [text, setText] = useState(null);
    const [textError, setTextError] = useState(null);
    const report_id = useSelector(state => state.pool.reportData.report_details.report_id)

    const handleAddButtonClick = (event) => {
        event.preventDefault();
        const textError = text === null || text === '';
        const periodDateError = periodDate === null
        setTextError(textError);
        setPeriodDateError(periodDateError);

        if(!periodDateError && !textError) {
            ApiSyncManager.fetchJson('/planning/create', {
                method: 'POST',
                body: {
                    schedule: moment(periodDate).format('YYYY-MM-DD'),
                    display_mode : periodMode,
                    text: text,
                    is_rich_text: richText,
                    container_id: context.container.id,
                    report_id: report_id,
                }
            })
                .then(({id}) => {
                    dispatch(actions.createInReportData({
                        path: [...context.reportDataPath, 'content'],
                        object: {
                            id: id,
                            schedule: moment(periodDate).format('YYYY-MM-DD'),
                            display_mode : periodMode,
                            text: text,
                            is_rich_text: richText,
                            parent: null
                        }
                    }));
                    dismissModal();
                })
                .catch(error => {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        title: 'Échec de la création',
                        message: 'La ligne de planning n\'a pas pu être créée',
                        log: error.stack.toString()
                    }));
                    dismissModal();
                })
        }
    }

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'lg'} onClose={dismissModal}>
                <DialogTitle>AJOUTER UNE LIGNE DE PLANNING</DialogTitle>
                <DialogContent sx={{minWidth: '1200px'}}>
                    <Stack gap='10px'>
                        <Stack direction='row' gap='10px'>
                            <DatePicker
                                label="Date"
                                value={periodDate}
                                onChange={setPeriodDate}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={periodDateError}
                                        helperText={periodDateError ? 'Ce champ est obligatoire' : ''}
                                        required
                                    />
                                }
                            />
                            <FormControl sx={{minWidth: '200px'}} required>
                                <InputLabel id="display-mode-select">Mode d'affichage</InputLabel>
                                <Select
                                    labelId="display-mode-selector-label"
                                    id="display-mode-select"
                                    label="Mode d'affichage"
                                    value={periodMode}
                                    onChange={(event) => setPeriodMode(event.target.value)}
                                >
                                    <MenuItem value={DATE_MODE.DAY}>Jour</MenuItem>
                                    <MenuItem value={DATE_MODE.WEEK}>Semaine</MenuItem>
                                    <MenuItem value={DATE_MODE.MONTH}>Mois</MenuItem>
                                    <MenuItem value={DATE_MODE.QUARTER}>Trimestre</MenuItem>
                                    <MenuItem value={DATE_MODE.HALF}>Bimestre</MenuItem>
                                    <MenuItem value={DATE_MODE.YEAR}>Année</MenuItem>
                                </Select>
                            </FormControl>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    bgcolor: grey[200],
                                    p:2,
                                    flexGrow: '1'
                                }}
                                variant='outlined'
                            >
                                <Box sx={{position: 'absolute', fontSize: '0.6rem', transform: 'translateY(-150%)'}}>
                                    Prévisualisation d'affichage
                                </Box>
                                <div>
                                    {
                                        moment(periodDate).isValid() && periodDate
                                            ? DateExtended.displayDate(moment(periodDate).format('YYYY-MM-DD'), periodMode)
                                            : <span style={{fontStyle: 'italic', color: 'var(--danger)'}}>Date ou Mode d'affichage invalide</span>
                                    }
                                </div>
                            </Paper>

                        </Stack>
                        <DialogContentText sx={{fontStyle: 'italic', mx: 4, mt: 2}}>
                            Vous pouvez utiliser les tag dans le champ 'Description'
                            en utilisant le symbole hashtag (<b>#</b>).
                        </DialogContentText>
                        <FormControl>
                            <FormLabel>
                                <span>Description*</span>
                                {textError && <FormHelperText sx={{display: 'inline'}} error>Ce champ est obligatoire</FormHelperText>}
                            </FormLabel>
                            <InputText
                                textValue={text}
                                updateTextValueCallback={(newValue) => setText(newValue)}
                                isRichText={richText}
                                toggleRichTextModeCallback={() => setRichText(!richText)}
                                placeholder='Description'
                                error={textError}
                                withTags
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={dismissModal}>Annuler</Button>
                    <Button variant='outlined' onClick={handleAddButtonClick}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

/**
 * Builds user available actions on edit mode
 * @param path {Array<String|Number>} Planning path inside Redux pool reportData
 */
function ActionColumn({path}) {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);

    const handleDelete = () => {
        const cpPath = [...path];
        dispatch(actions.deleteFromReportData({
            deleteIndex: cpPath.splice(-1),
            path: cpPath,
            component: 'planning'
        }));
    }

    return (
        <td className={styles.ActionsCol} style={{verticalAlign: 'middle', width: 'fit-content'}}>
            {isEditing &&
                <IconButton aria-label="delete-row" onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </td>
    )
}