import { configureStore } from '@reduxjs/toolkit';
import poolReducer from 'Redux/poolSlice';
import pageReducer from 'Redux/pageSlice';
import meReducer from 'Redux/meSlice';
import notificationReducer from 'Redux/Notification/notificationSlice';

export default configureStore({
    reducer: {
        me: meReducer,
        pool: poolReducer,
        page: pageReducer,
        notification: notificationReducer
    }
});