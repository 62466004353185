import {useSelected} from "slate-react";
import {Box, Chip} from "@mui/material";
import ImageViewer from "feature/FileExplorer/ImageViewer";
import React, {useState} from "react";
import {Transforms} from "slate";
import PhotoIcon from '@mui/icons-material/Photo';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const SLATE_ATTACHEMENT_TAG_NAME = 'AttachmentTag';

export default function AttachmentTag ({ children, attributes, element }) {
    const selected = useSelected();
    const [showPreview, setShowPreview] = useState(false);

    return (
        <>
            {children}
            <Box sx={{display: 'inline-flex',  alignItems: 'center', height: '0px'}} component='span'>
                <Chip
                    label={`${element.tagObject.title}`}
                    {...attributes}
                    contentEditable={false}
                    component='span'
                    clickable
                    icon={element.tagObject.type === 'pdf'
                        ? <InsertDriveFileIcon fontSize='inherit'/>
                        : <PhotoIcon  fontSize='inherit'/>
                    }
                    onClick={() => setShowPreview(true)}
                    sx={{
                        fontFamily: '"DinPro", sans-serif',
                        fontSize: '0.7em',
                        fontStyle: 'italic',
                        height: 'auto',
                        border: '2px solid transparent',
                        borderColor: selected ? 'primary.main' : 'transparent'
                    }}
                />
                {showPreview &&
                    <ImageViewer
                        file={element.tagObject}
                        fileRequestPath={`/attachment/${element.tagObject.filename}`}
                        dismiss={() => setShowPreview(false)}
                    />
                }
            </Box>
        </>
    )
}

export const withAttachmentTag = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
        return element.type === SLATE_ATTACHEMENT_TAG_NAME ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === SLATE_ATTACHEMENT_TAG_NAME ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return element.type === SLATE_ATTACHEMENT_TAG_NAME || markableVoid(element)
    }

    return editor
}

export const insertAttachmentTag = (editor, target, tagObject) => {
    const tag = {
        type: SLATE_ATTACHEMENT_TAG_NAME,
        tagObject,
        children: [{ text: '' }],
    }
    Transforms.select(editor, target)
    Transforms.insertNodes(editor, tag)
    Transforms.move(editor)
}

