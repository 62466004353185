
import waitGif from './assets/loading.gif'
import waitGifEgg from './assets/loadingEgg.gif'

import styles from './Loading.module.css';
import {useSelector} from "react-redux";

/**
 * Create a component with a loading symbol
 */
function EasterEggLoading() {
    return (
        <div className={styles.Loading} style={{backgroundColor: 'rgb(255, 199, 73)'}}>
            <img src={waitGifEgg} alt='A chicken searching' className={styles.waitGif}/>
            <div className={styles.title}>
                Veuillez Patienter
                <div className={styles.subtitle}>
                    Poolidor,
                    <br/>
                    notre <strong>Data Poule</strong> de compétition, cherche vos données...
                </div>
            </div>
        </div>
    );
}

/**
 * Create a component with a loading symbol
 */
function NormalLoading() {
    return (
        <div className={styles.Loading}>
            <img src={waitGif} alt='loading' className={styles.waitGif}/>
            <div className={styles.title}>
                Chargement des données en cours...
            </div>
        </div>
    );
}

/**
 * Create a component with a loading symbol
 */
function Loading() {
    const isCtrlAndAltPressed = useSelector(state => state.page.data);
    return isCtrlAndAltPressed ? <EasterEggLoading /> : <NormalLoading />
}

export default Loading;