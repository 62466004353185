// noinspection JSUnusedAssignment

import {createSlice} from "@reduxjs/toolkit";

export const page = {
    AttachmentManager: 'AttachmentManager',
    UnloadedContent: 'UnloadedContent',
    Loading: 'Loading',
    Report: 'Report',
    RightManager: 'RightManager',
    Workflow: 'Workflow'
};

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        page: page.UnloadedContent,
        data: null
    },
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload.page;
            state.data = action.payload.data;
        },
        updateData: (state, action) => {
            state.data = action.payload
        }
}
});

export const actions = pageSlice.actions;

export default pageSlice.reducer;