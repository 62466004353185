import {createContext, useContext, useMemo, useRef, useState} from 'react';
import useToggle from "customHook/useToggle";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "Redux/poolSlice";
import DateExtended from "utilities/DateExtended";
import ApiSyncManager from "utilities/ApiSyncManager";
import ModulesIcons from "assets/img/ModulesIcons/ModulesIcons";
import Form, {UserForm} from "./ParticipantForm";

import styles from './Participant.module.scss';

const DragContext = createContext({
    isDragging: false,
    setIsDragging: () => {}
});

/**
 * Build the participant container
 */
export default function Participant() {
    const [isDragging, setIsDragging] = useState(false);
    const { fullView } = useSelector(state => state.page.data);
    const { present, absent, called } = useSelector(state => {
        const participants = state.pool.reportParticipantList;
        const present = participants.filter(({attendance}) => attendance === 1 || attendance === 2);
        const absent = participants.filter(({attendance}) => attendance === 3);
        const called = participants.filter(({is_called_next_report}) => is_called_next_report);

        return {present: present.length, absent: absent.length, called: called.length};
    });
    const [isOpen, toggle] = useToggle(false);

    return (
        <div className={styles.Container}>
            <div className={styles.ContainerHeader}>
                <ModulesIcons iconName='participant' />
                <div className={styles.Resume}>
                    <h5>INTERVENANTS</h5>
                    <div className={styles.Indicators}>
                        <ResumeIndicator tag='présents' value={present}/>
                        <ResumeIndicator tag='absents' value={absent}/>
                        <ResumeIndicator tag='convoqué à la prochaine réunion' value={called}/>
                    </div>
                    {!fullView && (isOpen ? <RetractButton onClick={toggle}/> : <ExpandMoreButton onClick={toggle} />)}
                </div>
            </div>
            <DragContext.Provider value={{ isDragging, setIsDragging }}>
                {(fullView || isOpen) && <ParticipantList />}
            </DragContext.Provider>
        </div>
    );
}

/**
 * Create indicator in top container
 * @param value {number} the number to display
 * @param tag {string} associated value tag to display
 */
function ResumeIndicator({value, tag}) {
    return (
        <div className={styles.ResumeIndicator}>
            <div className={styles.ResumeValue}>{value}</div>
            <div className={styles.ResumeTag}>{tag.toUpperCase()}</div>
        </div>
    )
}

function ExpandMoreButton({onClick}) {
    return (
        <div className={styles.showMoreButton} onClick={onClick}>
            <span className='material-icons'>expand_more</span>
            <span>Voir la liste des intervenants</span>
        </div>
    )
}

function RetractButton({onClick}) {
    return (
        <div className={styles.showMoreButton} onClick={onClick}>
            <span className='material-icons'>expand_less</span>
            <span>Replier</span>
        </div>
    )
}

function ParticipantList()  {
    const participants = useSelector(state => state.pool.reportParticipantList);
    return (
        <div>
            <AddButton atPosition={0}/>
            <DragDropZone position={0}/>
            {participants.map((participant, index) =>
                <ParticipantCard
                    key={participant.mail}
                    participant={participant}
                    previousParticipant={index > 0 ? participants[index-1] : null}
                    position={index}
                />
            )}
        </div>
    )
}

function ParticipantCard({participant, previousParticipant, position}) {
    const { setIsDragging, isDragging } = useContext(DragContext);
    const isEditing = useSelector(state => state.pool.isEditing);
    const isDifferentRole = useMemo(() => {
            return previousParticipant === null || participant.role_name !== previousParticipant.role_name
    }, [participant, previousParticipant]);
    const initials = useMemo(() => {
        return participant.firstname.charAt(0) + participant.lastname.charAt(0);
    }, [participant]);

    const startDrag = event => {
        if(!isDragging) {
            setIsDragging(true);
            event.dataTransfer.setData("text/plain", participant.participant_id);
        }
    }

    const endDrag = () => {
        setIsDragging(false);
    }

    return (
        <div>
            {isDifferentRole && <RoleName role={participant.role_name}/>}
            <div className={`${styles.ParticipantCard} ${ isEditing ? 'Draggable' : ''}`} draggable={isEditing} onDragStart={startDrag} onDragEnd={endDrag}>
                <OrderActionBar participant={participant} />
                <UserPicture initials={initials} />
                <User user={participant} />
                <div>
                    <UserDetail value={participant.phone} iconName='phone'/>
                    <UserDetail value={participant.mail} iconName='mail'/>
                    <AttendanceBar
                        userId={participant.participant_id}
                        attendance={participant.attendance}
                        isCalledNextReport={participant.is_called_next_report}
                        isNotified={participant.must_be_notified}
                    />
                </div>
                <ActionBar index={position} user={participant}/>
            </div>
            <AddButton atPosition={position + 1}/>
            <DragDropZone position={position}/>
        </div>
    )
}

function RoleName({role}) {
    return (
        <div className={styles.RoleBox}>
            <span className={styles.RoleName}>{role}</span>
        </div>
    )
}

function OrderActionBar({participant}) {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const [position, setPosition] = useState(participant.display_order)
    const [isFocus, toggle] = useToggle(false);

    const handleInputChange = (event) => {
        const value = parseInt(event.target.value);
        if(value !== participant.display_order && Number.isInteger(value)) {
            dispatch(actions.reorderReportParticipantList({
                id: participant.participant_id,
                setAtPosition: value
            }));
        }
        else {
            setPosition(participant.display_order);
        }
        toggle();
    };

    const upParticipant = () => {
            dispatch(actions.reorderReportParticipantList({
                id: participant.participant_id,
                setAtPosition: participant.display_order - 1
            }));
    }

    const downParticipant = () => {
        dispatch(actions.reorderReportParticipantList({
            id: participant.participant_id,
            setAtPosition: participant.display_order + 1
        }));
    }

    return isEditing
        ? (
            <div className={styles.OrderActionBar}>
                <div className='material-icons Clickable' onClick={upParticipant} >expand_less</div>
                {
                    isFocus
                    ? <input
                    className='Input'
                    value={position}
                    onChange={(event) => setPosition(event.target.value)}
                    onBlur={handleInputChange}
                    />
                    : <input
                    className='Input'
                    value={participant.display_order}
                    onClick={toggle}
                    readOnly
                    />
                }

                <div className='material-icons Clickable' onClick={downParticipant}>expand_more</div>
            </div>
        )
        : (<div></div>)
}

/**
 *
 * @param initials {String}
 * @returns {JSX.Element}
 * @constructor
 */
function UserPicture({initials}) {
    const hueColor = useMemo(() => ((initials.charCodeAt(0) + initials.charCodeAt(1)) * 9) % 360, [initials]);

    return (
        <div className={styles.UserPictureFrame} style={{backgroundColor: `hsl(${hueColor}deg, 63%, 42%)`}}>
            <span>{initials}</span>
        </div>
    )
}

function User({user}) {
    const isEditing = useSelector(state => state.pool.isEditing);

    const displayedEnterprise = useMemo(() => {
        const selectedEnterprise = user.enterprises.find(ets => ets.id === user.enterprise_id);
        return selectedEnterprise ? selectedEnterprise.name.toUpperCase() : '';
    }, [user])

    return (
        <div className={styles.UserDetailsBox}>
            <div className={styles.UserName}>
                <span>{user.firstname}</span>
                <span className={styles.Lastname}>{user.lastname ? user.lastname.toUpperCase() : ''}</span>
            </div>
            {isEditing && user.enterprises.length > 1
                ? <ParticipantEnterpriseSelect user={user} />
                : <div className={styles.EnterpriseName}>{displayedEnterprise}</div>
            }
        </div>
    )
}

function ParticipantEnterpriseSelect({user}) {
    const dispatch = useDispatch();
    const updateEnterprise = (event) => {
        dispatch(actions.updateInReportParticipantList({
            id: user.participant_id,
            updates: [
                {key: 'enterprise_id', value: parseInt(event.target.value)}
            ]
        }));
    }
    return (
        <div className={`${styles.EnterpriseOption} Clickable`}>
            <select value={user.enterprise_id} onChange={updateEnterprise}>
                {user.enterprises.map(ets => (
                    <option key={ets.id} value={ets.id}>
                        {ets.name.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    )
}

function UserDetail({value, iconName}) {
    return value && (
        <div className={styles.UserDetail}>
            <div className={`material-icons ${styles.Icon}`}>{iconName}</div>
            <div className={styles.Value}>{value}</div>
        </div>
    )
}

function AttendanceBar({userId, attendance, isCalledNextReport, isNotified}) {
    const dispatch = useDispatch();

    const { report_details } = useSelector(state => state.pool.reportData);
    const calledActiveText = useMemo(() => {
        const nextMeetingDate = report_details.next_meeting_date !== null
            ? DateExtended.formatInputDateToFRString(report_details.next_meeting_date)
            : null;
        return nextMeetingDate ? `Convoqué le ${nextMeetingDate}` : 'Convoqué à la prochaine réunion'
    }, [report_details]);

    const setAttendance = (value) => {
        const newValue = attendance === value ? 0 : value;
        dispatch(actions.updateInReportParticipantList({
            id: userId,
            updates: [
                {key: 'attendance', value: newValue}
            ]
        }));
    }

    const toggleNotified = () => {
        dispatch(actions.updateInReportParticipantList({
            id: userId,
            updates: [
                {key: 'must_be_notified', value: !isNotified}
            ]
        }));
    }

    const toggleCalled = () => {
        dispatch(actions.updateInReportParticipantList({
            id: userId,
            updates: [
                {key: 'is_called_next_report', value: !isCalledNextReport}
            ]
        }));
    }

    return (
        <div className={styles.AttendanceBox}>
            <AttendanceButton
                isActive={isNotified}
                value='Diffusion'
                color='#FEFEFE'
                onClick={toggleNotified}
            />
            <AttendanceButton
                isActive={attendance === 1}
                value='Présent'
                color='#EBF4DF'
                onClick={() => setAttendance(1)}
            />
            <AttendanceButton
                isActive={attendance === 2}
                value='Représenté'
                color='#D3E3FA'
                onClick={() => setAttendance(2)}
            />
            <AttendanceButton
                isActive={attendance === 3}
                value='Absent'
                color='#FADCD3'
                onClick={() => setAttendance(3)}
            />
            <AttendanceButton
                isActive={isCalledNextReport}
                value='Convoquer'
                activeValue={calledActiveText}
                color='#F4EEDF'
                onClick={toggleCalled}
            />
        </div>
    )
}

function AttendanceButton({value, isActive, activeValue, color, onClick}) {
    const isEditing = useSelector(state => state.pool.isEditing);
    const [isHover, setIsHover] = useState(false);
    const style = useMemo(() => {
        return {
            backgroundColor: (!isActive && isHover) || (isActive && !isHover)  ? color : 'transparent',
            borderStyle: (isActive || isHover)  ? 'solid' : 'dashed',
            borderColor: color,
            color: (isActive || isHover) ? 'var(--black)' : 'var(--grey)'
        }
    }, [isActive, isHover, color]);
    return (isEditing || isActive) && (
        <div
            className={`${styles.AttendanceButton} ${isEditing ? styles.isEditing : ''}`}
            onMouseEnter={() => setIsHover(isEditing)}
            onMouseLeave={() => setIsHover(false)}
            style={style}
            onClick={isEditing ? onClick : undefined}
        >
            {(activeValue && isActive) ? activeValue : value}
        </div>
    )
}

function ActionBar({index, user}) {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);
    const {report_details} = useSelector(state => state.pool.reportData);
    const [showModal, setShowModal] = useState(false);

    const handleRemove = () => {
        dispatch(actions.deleteFromReportParticipantList({deleteIndex: index}))
    }

    const handleUpdate = async (updatedUser) => {
        const enterprise_id = updatedUser.enterprises.some(ets => ets.id === user.enterprise_id)
            ? user.enterprise_id
            : updatedUser.enterprises[0].id;
        dispatch(actions.deleteFromReportParticipantList({deleteIndex: index}))
        const newParticipant = await ApiSyncManager.fetchJson(`/report/${report_details.report_id}/participant/${updatedUser.id}`, {
            method: 'POST',
            body: {
                roleId: user.role_id,
                displayOrder: user.display_order,
                enterpriseId: enterprise_id
            }
        })
        dispatch(actions.addInReportParticipantList({object: newParticipant}));
        setShowModal(false);
    }

    return (
        <div className={styles.ActionBar}>
            {isEditing && <div className={`material-icons ${styles.ButtonIcon}`} onClick={() => setShowModal(true)}>edit</div>}
            {isEditing && <div className={`material-icons ${styles.ButtonIcon}`} onClick={handleRemove}>delete</div>}

            {showModal && <UserForm updateUser={user} setCreatedUser={handleUpdate} dismissModal={() => setShowModal(false)}/>}
        </div>
    );
}

function AddButton({atPosition}) {
    const {isDragging} = useContext(DragContext);
    const isEditing = useSelector(state => state.pool.isEditing);
    const [showModal, toggle] = useToggle(false);

    return (
        <>
            {(isEditing && !isDragging) && (
                <div className={styles.AddBtn} onClick={toggle}>
                    <span className='material-icons'>add</span>
                    <span>Ajouter un intervenant</span>
                </div>
            )}
            {showModal && <Form dismissModal={toggle} addAtPosition={atPosition}/>}
        </>
    )
}

function DragDropZone({position}) {
    const {isDragging} = useContext(DragContext);
    const dispatch = useDispatch();
    const ref = useRef();

    const onDragEnter = (event) => {
        ref.current.style.height = '120px';
        event.dataTransfer.dropEffect = "move";
    }

    const onDragLeave = () => {
        ref.current.style.height = 'auto';
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    const onDrop = (event) => {
        event.preventDefault();
        dispatch(actions.reorderReportParticipantList({
            id: parseInt(event.dataTransfer.getData("text/plain")),
            setAtPosition: position
        }));
    }

    return isDragging && (
        <div ref={ref}
             className={styles.DragDropZone}
             onDragEnter={onDragEnter}
             onDragLeave={onDragLeave}
             onDragOver={onDragOver}
             onDrop={onDrop}
             data-position={position}>
            Déposez ici
        </div>
    )
}