import {useMemo} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {actions as poolActions} from "Redux/poolSlice";
import {actions, page} from "Redux/pageSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import ApiSyncManager from 'utilities/ApiSyncManager';
import DateExtended from 'utilities/DateExtended';

import styles from './ReportNav.module.scss';

/**
 * Create a project in navigation
 * @param report {{
 *     id: Number,
 *     title: String|Null,
 *     number: Number,
 *     meeting_place: String|Null,
 *     writing_date: String|Null,
 *     next_meeting_date: String|Null,
 *     is_closed: Boolean,
 *     is_published: Boolean,
 *     pdf_url: String|Null,
 *     id_meeting: Number
 * }} Report's Data use to build components
 */
function ReportNav({report}) {
    const dispatch = useDispatch();
    const loadedReport = useSelector(state => state.pool.reportData);
    const icon = useMemo(() => {
        if(report.is_closed && report.is_published) {
            return 'description';
        }
        else if(report.is_closed) {
            return 'published_with_changes';
        }
        else {
            return 'edit';
        }
    }, [report]);
    const date = useMemo(() => {
        const dateObject = new DateExtended(report.writing_date);
        if(report.writing_date) {
            return ` du ${dateObject.toFRDateString()}`;
        }
        else {
            return '';
        }
    }, [report]);
    const isLoaded = useMemo(() => {
            return loadedReport.report_details && loadedReport.report_details.report_id === report.id
        }, [loadedReport, report]);

    async function loadReport(event) {
        try {
            await dispatch(actions.setPage({page: page.Loading, data: (event.ctrlKey && event.altKey)}));
            const reportData = await ApiSyncManager.fetchJson(`/report/${report.id}`, {method: 'GET'});
            await dispatch(poolActions.setReportData(reportData));
            await dispatch(actions.setPage({page: page.Report, data: {fullView: false}}));
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Impossible de Récupérer le CR',
                message: 'Une erreur s\'est produite lors de la récupération du compte compte rendu.',
                log: error.stack.toString()
            }));
        }

    }

    return (
        <div className={`${styles.Report} ${isLoaded && styles.Loaded}`}>
            <div className={styles.ReportClickable} onClick={loadReport}>
                <div className={styles.IconFrame}>
                    <span className='material-icons'>{icon}</span>
                </div>
                <div className={styles.ReportName}>
                    CR n°{report.number}{date}
                </div>
            </div>
        </div>
    )
}

export default ReportNav;

/**
 * The component displayed when user can create a new report
 * @param fromReport {Object} The report on with is based the brand-new one
 */
export function CreateReport({fromReport}) {
    const dispatch = useDispatch();
    const create = async (event) => {
        const apiSyncManager = new ApiSyncManager();
        try {
            dispatch(actions.setPage({page: page.Loading, data: (event.ctrlKey && event.altKey)}));
            await apiSyncManager.createReportBasedOn(fromReport.id, dispatch);
            dispatch(actions.setPage({page: page.Report, data:{fullView: false}}));
        }
        catch (error) {
            dispatch(createNotification({
                type: NOTIFICATION_TYPE.ERROR,
                title: 'Impossible de Créer le CR',
                message: 'Une erreur s\'est produite lors de la création du nouveau compte compte rendu.',
                log: error.stack.toString()
            }));
        }
    };

    return (
        <div className={styles.Report}>
            <div className={styles.ReportClickable} onClick={create}>
                <div className={styles.IconFrame}>
                    <span className='material-icons'>add_circle</span>
                </div>
                <div className={styles.ReportName}>Créer un nouveau CR</div>
            </div>
        </div>
    )
}

/**
 * The component displayed when the list of report is long
 * @param onClick {Function} Delegate onClick action to parent component
 * @returns {JSX.Element}
 */
export function ShowMore({onClick}) {
    return (
        <div className={`${styles.Report} ${styles.ReportShowMore}`}>
            <div className={styles.ReportClickable} onClick={onClick}>
                <div className={styles.IconFrame}>
                    <span className='material-icons'>expand_more</span>
                </div>
                <div className={styles.ReportName}>Voir Plus</div>
            </div>
        </div>
    )
}