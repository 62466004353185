export const apiConfig = {
    host: window._env_ ? window._env_.API_HOST : process.env.REACT_APP_API_HOST
};

export const msalConfig = {
    auth: {
        clientId: window._env_ ? window._env_.APP_CLIENT_ID : process.env.REACT_APP_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${window._env_ ? window._env_.APP_TENANT_ID  : process.env.REACT_APP_APP_TENANT_ID}`
    }
};

export const loginRequest = {
    scopes: [`api://${window._env_ ? window._env_.API_CLIENT_ID : process.env.REACT_APP_API_CLIENT_ID}/Data.ReadWrite`]
};

export const MaxInputYearOffset = 20;

export const acceptedFiles = {
    image: [
        'png',
        'webp',
        'jpg',
        'jpeg',
        'heic',
        'tif',
        'tiff',
        'jpe',
    ],
    other: [
        'pdf'
    ],
    mimeType: [
        'image/heic',
        'image/jpeg',
        'image/png',
        'image/x-png',
        'image/tiff',
        'image/webp',
        'application/x-pdf',
        'application/pdf'
    ]
};
