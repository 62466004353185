import { createSlice } from '@reduxjs/toolkit';


export const meSlice = createSlice({
    name: 'me',
    initialState: {
        favoriteProjects: [],
        grantedMeetings: [],
        details: null
    },
    reducers: {
        init: (state,actions) => {
            state.favoriteProjects = actions.payload.favoriteProjects;
            state.grantedMeetings = actions.payload.grantedMeetings;
            state.details = actions.payload.details;
        },
        updateFavorite: (state,actions) => {
            state.favoriteProjects = actions.payload;
        },
        updateGrantedMeetings: (state,actions) => {
            state.grantedMeetings = actions.payload;
        },
    }
});

export const actions = meSlice.actions;

export default meSlice.reducer;