import { apiConfig, loginRequest, msalConfig } from "config";
import { actions as poolActions } from 'Redux/poolSlice';
import { PublicClientApplication } from "@azure/msal-browser";

class ApiSyncManager {
    #accessToken = null;
    #defaultGetRequest() {
        return {
            method: 'GET',
            headers:
                {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.#accessToken}`
                },
            mode: 'cors'
        }
    }
    #defaultPostRequest() {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.#accessToken}`
            },
            mode: 'cors'
        }
    }
    #defaultPutRequest() {
        return {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.#accessToken}`
            },
            mode: 'cors'
        }
    }
    #defaultDeleteRequest() {
        return {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.#accessToken}`
            },
            mode: 'cors'
        }
    };

    async #getToken() {
        if(this.#accessToken === null) {
            const authContext = new PublicClientApplication(msalConfig);
            const authResponse = await authContext.acquireTokenSilent(loginRequest);
            this.#accessToken = authResponse.accessToken;
        }
    }

    async getMeetingGrant(meeting_id) {
        const apiPath = `${apiConfig.host}/meeting/${meeting_id}/grant`;
        await this.#getToken();
        return this.#fetch(apiPath, this.#defaultGetRequest());
    }

    async grantWriterRight(meeting_id, user_id) {
        const apiPath = `${apiConfig.host}/meeting/${meeting_id}/grant/${user_id}`;
        await this.#getToken();
        return await this.#fetch(apiPath, this.#defaultPutRequest());
    }

    async deleteGrant(meeting_id, user_id) {
        const apiPath = `${apiConfig.host}/meeting/${meeting_id}/grant/${user_id}`;
        await this.#getToken();
        return await this.#fetch(apiPath, this.#defaultDeleteRequest());
    }

    async getEnterpriseList(dispatch) {
        const apiPath = `${apiConfig.host}/enterprise/list`;
        await this.#getToken();
        const res = await this.#fetch(apiPath, this.#defaultGetRequest(), dispatch);

        dispatch(poolActions.setEnterpriseList(res));
    }

    async getParticipantList(dispatch) {
        const apiPath = `${apiConfig.host}/participant/list`;
        await this.#getToken();
        const res = await this.#fetch(apiPath, this.#defaultGetRequest(), dispatch);

        dispatch(poolActions.setParticipantList(res));
    }

    async getParticipantRoleList(dispatch) {
        const apiPath = `${apiConfig.host}/participantRole/list`;
        await this.#getToken();
        const res = await this.#fetch(apiPath, this.#defaultGetRequest(), dispatch);

        dispatch(poolActions.setParticipantRoleList(res));

    }

    async getProjectDetails(dispatch) {
        const apiPath = `${apiConfig.host}/project/detailedList`;
        await this.#getToken();
        const res = await this.#fetch(apiPath, this.#defaultGetRequest(), dispatch);

        dispatch(poolActions.setProjectList(res));

    }

    async getTaskCategoryList(dispatch) {
        const apiPath = `${apiConfig.host}/taskCategory/list`;
        await this.#getToken();
        const res = await this.#fetch(apiPath, this.#defaultGetRequest(), dispatch);

        dispatch(poolActions.setTaskCategoryList(res));

    }

    async createReportBasedOn(reportId, dispatch) {
        if(!isNaN(reportId) && reportId > 0) {
            const apiPath = `${apiConfig.host}/report/createFrom`;
            await this.#getToken();
            const request = this.#defaultPostRequest();
            request.body = {report_id: reportId};
            const res = await this.#fetch(apiPath, request);
            dispatch(poolActions.setReportData(res));
            await this.getProjectDetails(dispatch);

        }
    }

    async addParticipant(requestBody, dispatch) {
        const apiPath = `${apiConfig.host}/report/addParticipant`;
        await this.#getToken();
        const request = this.#defaultPostRequest();
        request.body = requestBody;
        const res = await this.#fetch(apiPath, request, dispatch);
        dispatch(poolActions.createInReportData({
            path: ['participants'],
            object: res
        }));
        await this.getParticipantRoleList(dispatch);
    }

    #fetch(path, request) {
        request.body = JSON.stringify(request.body);
        return fetch(path, request)
            .then(res => res.json())
    }

    /**
     * Method that allow to fetch application API endpoint. Handle the Bearer and json parsing
     * @param path {RequestInfo|URL} Endpoint to fetch
     * @param request {RequestInit} Request parameters. method is Required
     * @returns {Promise<JSON>}
     */
    static async fetchJson(path, request) {
        const authContext = new PublicClientApplication(msalConfig);
        const authResponse = await authContext.acquireTokenSilent(loginRequest);
        request.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authResponse.accessToken}`
        };

        request.body = JSON.stringify(request.body);

        const response = await fetch(`${apiConfig.host}${path}`, request)
        if(response.ok) {
            return await response.json();
        }
        else {
            const error = new Error(`${apiConfig.host}${path} respond with status code ${response.status}`);
            error.name = 'Fetch failed';
            error.cause = response;

            throw error;
        }
    }

    /**
     * Method that allow to fetch application API endpoint. Handle the Bearer and json parsing
     * @param path {RequestInfo|URL} Endpoint to fetch
     * @param request {RequestInit} Request parameters. method is Required
     * @param filename {String} The given filename on download
     * @returns {Promise<JSON>}
     */
    static async fetchPdf(path, request, filename) {
        const authContext = new PublicClientApplication(msalConfig);
        const authResponse = await authContext.acquireTokenSilent(loginRequest);

        request.headers = {
            'Accept': 'application/pdf',
            'Authorization': `Bearer ${authResponse.accessToken}`
        };

        request.body = JSON.stringify(request.body);

        const res = await fetch(`${apiConfig.host}${path}`, request);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
    }

    static async sendMultipart(path, request) {
        const authContext = new PublicClientApplication(msalConfig);
        const authResponse = await authContext.acquireTokenSilent(loginRequest);

        request.headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authResponse.accessToken}`
        };

        const response = await fetch(`${apiConfig.host}${path}`, request)
        if(response.ok) {
            return await response.json();
        }
        else {
            const error = new Error(`${apiConfig.host}${path} respond with status code ${response.status}`);
            error.name = 'Fetch failed';
            error.cause = response;

            throw error;
        }
    }

    static async getAttachment(path, request) {
        const authContext = new PublicClientApplication(msalConfig);
        const authResponse = await authContext.acquireTokenSilent(loginRequest);

        request.headers = {
            'Accept': "*",
            'Authorization': `Bearer ${authResponse.accessToken}`
        };

        return await fetch(`${apiConfig.host}${path}`, request).then(res => res.blob());
    }

}

export default ApiSyncManager;