import {createContext, createElement, useContext} from "react";
import {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useToggle from "customHook/useToggle";
import {actions, getElement} from "Redux/poolSlice";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";

import Planning from './Planning';
import List from './List';
import Text from './Text';
import Task from './Task';

import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Portal,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import ApiSyncManager from "utilities/ApiSyncManager";
import {Edit, ExpandLess, ExpandMore} from "@mui/icons-material";
import ModulesIcons from "assets/img/ModulesIcons/ModulesIcons";

import styles from './Modules.module.scss';

const REACT_COMPONENTS = {
    Container: Container,
    Planning: Planning,
    List: List,
    Text: Text,
    Task: Task
};
export const ContainerContext = createContext(null);

/**
 * Build containers list
 * @param level {Number} The container level in the data tree
 * @param path {Array<String|Number>} Container path inside Redux pool reportData
 */
function Container({level, path}) {
    const { fullView } = useSelector(state => state.page.data);
    const containers = useSelector(state => getElement(state.pool.reportData, path));
    const Container = useMemo(() => (level === 0) ? MainContainerRow : ContainerRow, [level]);
    const [selectedContainer, setSelectedContainer] = useState(null);

    return (
        fullView
            ? (
                containers.map((c, index) =>
                    <div style={{marginBottom: level !== 0 ? '0px' : '30px', width: '100%', backgroundColor: 'var(--yellowBackground)'}} key={index}>
                        <FullViewContainer
                            key={c.id}
                            level={level}
                            path={[...path, index]}
                        />
                    </div>
                )
            )
            : (
                <>
                    {containers.map((c, index) =>
                        <Container
                            key={c.id}
                            level={level}
                            path={[...path, index]}
                            selected={selectedContainer === index}
                            onClick={() => setSelectedContainer(index)}
                        />)}
                    {(level === 0 && selectedContainer !== null) && <MainContainerContent path={[...path, selectedContainer]}/>}
                </>
            )
    );
}
export default Container;

/**
 * Create a stylized button where user can click to display module
 * @param level {Number} The container level in the data tree
 * @param path {Array<String|Number>} Container path inside Redux pool reportData
 * @param selected {Boolean} True if the container is selected in order to be displayed
 * @param onClick {Function} Function prototype run on button click
 */
function MainContainerRow({level, path, selected, onClick}) {
    const {title, icon_name} = useSelector(state => getElement(state.pool.reportData, path));

    return(
        <div className={`${styles.MainContainer} ${selected ? styles.Selected : ''}`}  onClick={onClick}>
            <div className={styles.ContainerHeader} style={{paddingLeft: level * 20}}>
                <ModulesIcons iconName={icon_name}/>
                <div>
                    <h5>{title ? title.toUpperCase() : ''}</h5>
                    <div
                        className={`material-icons ${styles.ExpandSymbol}`}
                        style={{color: selected ? 'var(--secondaryValeco)' : 'transparent'}}
                    >
                        expand_more
                    </div>
                </div>
            </div>
        </div>
    );
}

/**
 * Build a main container of level 1 with specific style
 * @param path {Array<String|Number>} Container path inside Redux pool reportData
 */
function MainContainerContent ({ path }) {
    const container = useSelector(state => getElement(state.pool.reportData, path));

    return (
        <ContainerContext.Provider value={{container: container, reportDataPath: path}}>
            <div style={{flexBasis: '100%', padding: '0 20px 20px'}}>
                {createElement(REACT_COMPONENTS[container.react_name], {level: 1, path: [...path, 'content']})}
                <CreateListButton />
            </div>
        </ContainerContext.Provider>
    )
}

/**
 * Build container with default style
 * @param level {Number} The container level in the data tree
 * @param path {Array<String|Number>} Container path inside Redux pool reportData
 */
const ICON_SIZE = '14px';

function ContainerRow({level, path}) {
    const {
        container,
        title,
        componentName
    } = useSelector(state => {
        const container = getElement(state.pool.reportData, path);
        return {
            container: container,
            title: container.title ? container.title.toUpperCase() : '',
            componentName: REACT_COMPONENTS[container.react_name],
        }
    });
    const [isOpen, toggle] = useToggle(!container.is_foldable);

    return(
        <div className={`${styles.Container} ${level > 0 ? styles.SubContainer : ''}`}>
            <Box sx={{mb: 1}}>
                <div className={styles.ContainerHeader} style={{paddingLeft: (level - 1) * 20}}>
                    <ContainerName name={title} isOpen={isOpen} toggle={container.is_foldable ? toggle : undefined}/>
                    <ContainerActions container={container} path={path}/>
                </div>
                <Divider />
            </Box>

            <ContainerContext.Provider value={{container: container, reportDataPath: path}}>
                {isOpen && createElement(componentName,{level: (level + 1), path: [...path, 'content']})}
                <CreateListButton />
            </ContainerContext.Provider>
        </div>
    );
}

function ContainerName({name, isOpen, toggle}) {
    const FoldIcon = useMemo(() => isOpen ? ExpandLess : ExpandMore,[isOpen]);
    return (
        toggle
        ? (
            <Button startIcon={<FoldIcon />} onClick={toggle} sx={{minWidth: 'fit-content'}}>
                <h5>{name}</h5>
            </Button>

        )
        : (
            <Box sx={{padding: '6px 8px 6px 32px', lineHeight: '31.5px'}}>
                <h5>{name}</h5>
            </Box>
        )
    )
}

function ContainerActions({container, path}) {
    const isEditing = useSelector(state => state.pool.isEditing);
    const [showForm, setShowForm] = useState(false);
    return (isEditing &&
        <>
            <IconButton aria-label="edit" size='small' onClick={() => setShowForm(true)}>
                <Edit sx={{height: ICON_SIZE, width: ICON_SIZE}}/>
            </IconButton>
            {showForm && <ContainerForm dismiss={() => setShowForm(false)} container={container} path={path}/>}
        </>
    )
}

function ContainerForm({dismiss, container, path}) {
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(true);
    const [selected, setSelected] = useState(0);
    const [newName, setNewName] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);

    const updateName = () => {
        if (activeButton && newName !== '') {
            setActiveButton(false);
            dispatch(actions.updateReportData({
                path: path,
                component: 'container',
                updates: [
                    {key: 'id', value: container.id},
                    {key: 'title', value: newName}
                ]
            }));
            dismiss();
        }
    }

    const handleDelete = () => {
        if (activeButton && confirmDelete) {
            setActiveButton(false);
            ApiSyncManager.fetchJson(`/container/${container.id}`, {method: 'DELETE'})
                .then(({deleted}) => {
                    if(deleted) {
                        dispatch(actions.deleteFromReportData({
                            deleteIndex: path.splice(-1),
                            path: path,
                        }));
                    }
                    else {
                        dispatch(createNotification({
                            type: NOTIFICATION_TYPE.ERROR,
                            title: 'Échec de Suppression',
                            message: `Le bloc ${container.title} n'a pas été supprimé`,
                            log: `Request do nothing on database: '/container/${container.id}', {method: 'DELETE'}`
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        title: 'Échec de Suppression',
                        message: `Le bloc ${container.title} n'a pas été supprimé`,
                        log: error.stack.toString()
                    }));
                });
            dismiss();
        }
    }

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'xl'}>
                <DialogTitle>
                    GESTION DE {container.title.toUpperCase()}
                </DialogTitle>
                <DialogContent>
                    <Tabs
                        value={selected}
                        onChange={(_, newValue) =>  setSelected(newValue)}
                        aria-label="basic tabs example"
                        sx={{mb: 4, minWidth: '33vw'}}
                    >
                        <Tab label="Modifier le nom" id={`action-${0}`} aria-controls={`action-${0}`}/>
                        <Tab label="Supprimer le bloc" id={`action-${1}`} aria-controls={`action-${1}`}/>
                    </Tabs>
                    <div hidden={selected !== 0}>
                        <TextField
                            label="Nouveau Nom"
                            value={newName}
                            onChange={(event) => setNewName(event.target.value.toUpperCase())}
                            helperText='Le texte est automatiquement mis en majuscule'
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div hidden={selected !== 1}>
                        <Alert severity="error">
                            Cette action va entrainer la <span style={{fontWeight: 'bold'}}>suppression irreversible</span> du contenu de ce bloc.
                        </Alert>
                        <FormGroup sx={{mt: 2}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color='error'
                                        checked={confirmDelete}
                                        onChange={(event) => setConfirmDelete(event.target.checked)}/>
                                }
                                label="Êtes-vous sûr de vouloir continuer ?"
                                labelPlacement='start'
                                sx={{justifyContent: 'center'}}
                            />
                        </FormGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={dismiss}>
                        Annuler
                    </Button>
                    {selected === 0 &&
                        <Button variant='contained' color='primary' disabled={!activeButton || newName === ''} onClick={updateName}>
                            Modifier le Nom
                        </Button>
                    }
                    {selected === 1 &&
                        <Button variant='contained' color='error' disabled={!activeButton || !confirmDelete} onClick={handleDelete}>
                            Supprimer {container.title.toUpperCase()}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

function CreateListButton() {
    const dispatch = useDispatch();
    const {container, reportDataPath} = useContext(ContainerContext);
    const isEditing = useSelector(state => state.pool.isEditing);
    const showButton = useMemo(() => isEditing && container.component_type === 'container', [container, isEditing]);
    const [showForm, toggleShowForm] = useToggle(false);

    const createList = async (title) => {
        const newContainer = await ApiSyncManager.fetchJson(`/container/createIn/${container.id}`, {
            method: 'POST',
            body: {
                title: title,
                component_id: 3,
                container_id: container.id
            }
        });
        dispatch(actions.createInReportData({
            path: [...reportDataPath, 'content'],
            object: newContainer
        }));
    }

    return ( showButton &&
        <Stack direction='row' alignItems='center'>
            <Box sx={{ m: 1 }}>
                <div className={styles.AddBtn} onClick={toggleShowForm}>
                    <span className='material-icons'>add</span>
                    <span>Ajouter une liste</span>
                </div>
            </Box>
            {showForm && <TitleForm onSubmit={createList} dismissModal={toggleShowForm}/>}
        </Stack>
    );
}

function TitleForm({onSubmit, dismissModal}) {
    const [title, setTitle] = useState('');
    const [activeButton, setActiveButton] = useState(true);

    const handleOnClick = () => {
        if(activeButton && title !== '') {
            setActiveButton(false);
            onSubmit(title);
            dismissModal();
        }
    }

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'xl'}>
                <DialogTitle>
                    TITRE DE LA LISTE
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        label="Titre"
                        value={title}
                        onChange={(event) => setTitle(event.target.value.toUpperCase())}
                        helperText='Le texte est automatiquement mis en majuscule'
                        variant="outlined"
                        sx={{minWidth: '33vw'}}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={dismissModal}>
                        Annuler
                    </Button>
                    <Button variant='contained' color='primary' disabled={!activeButton || title === ''} onClick={handleOnClick}>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

/**
 * Create a stylized Container for display the all report at once
 * @param level {Number} The container level in the data tree
 * @param path {Array<String|Number>} Container path inside Redux pool reportData
 */
function FullViewContainer({level, path}) {
    const {title, icon_name} = useSelector(state => getElement(state.pool.reportData, path));
    const container = useSelector(state => getElement(state.pool.reportData, path));

    return (
        <div style={{padding: level === 0 ? '5px 20px 20px 20px' : 0}}>
            <div className={`${styles.Container} ${level === 0 ? styles.FullViewMainContainer : styles.SubContainer}`}>
                <div className={level === 0 ? styles.FullViewContainerHeader : styles.ContainerHeader} style={{padding: '10px 0px 10px ' + level * 20 + 'px'}}>
                    <ModulesIcons iconName={icon_name}/>
                    <h5 className={styles.TitleMainContainer}>{title ? title.toUpperCase() : ''}</h5>
                    {level > 0 && <ContainerActions container={container} path={path}/>}
                </div>
                {level > 0 && <Divider sx={{mb: 1}}/>}
            </div>
            <ContainerContext.Provider value={{container: container, reportDataPath: path}}>
                {createElement(REACT_COMPONENTS[container.react_name], {level: (level + 1), path: [...path, 'content']})}
                <CreateListButton />
            </ContainerContext.Provider>
        </div>
    );
}