import styles from './Notification.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {dismissNotification} from "./notificationSlice";
import {useMsal} from "@azure/msal-react";
import useToggle from "customHook/useToggle";

const ITEM_TYPE = {
    Error: 'error',
    Warning: 'warning',
    Info: 'information',
    Success: 'success'
}

/**
 * Build graphic component used to display notification
 */
export default function Notification() {
    const {unavailableApiList, fatalErrorList, errorList, warningList, informationList, successList}
        = useSelector(state => state.notification)

    return (
        <>
            <div className={styles.NotificationList} >
                {errorList.map(error => <Item key={error.id} type={ITEM_TYPE.Error} data={error}/>)}
                {warningList.map(warning => <Item key={warning.id} type={ITEM_TYPE.Warning} data={warning} />)}
                {informationList.map(information => <Item key={information.id} type={ITEM_TYPE.Info} data={information} />)}
                {successList.map(success => <Item key={success.id} type={ITEM_TYPE.Success} data={success} />)}
            </div>
            {fatalErrorList.map(fatalError => <FatalError key={fatalError.id} data={fatalError}/> )}
            {unavailableApiList.map((data) => <UnavailableApi key={data.id} /> )}
        </>

    )
}

/**
 * Build a full screen error as it's impossible for user to continue using app in this conditions
 * @param data {{
 * type: {ITEM_TYPE},
 * title: {String},
 * message: {String},
 * log: {String}
 * }} datas indicating what's occurring
 */
export function FatalError({data}) {
    const [showDetails, toggleDetails] = useToggle(false);

    const createTicket = () => {
        //TODO Connect Zendesk API
        window.location.reload()
    }
    return (
        <div className={styles.FatalErrorFrame}>
            <div>
                <div className={styles.FatalErrorTitle}>Oups !</div>
                <div className={styles.FatalErrorMessage}>
                    <div>{data.message} Veuillez signaler l'incident en cliquant sur le bouton ci dessous:</div>
                    <div style={{fontStyle: 'italic', fontSize: '0.8rem'}}>Une fois le signalement effectué, l'application se rechargera automatiquement</div>
                </div>
                <button className={styles.FatalErrorCreateTicketButton} onClick={createTicket}>Recharger l'application</button>
                <div className={styles.FatalErrorDetailsTitle} onClick={toggleDetails}>+ Détails concernant l'erreur:</div>
                {showDetails && <div className={styles.FatalErrorDetails}>{data.log.toString()}</div>}
            </div>
        </div>
    )
}

/**
 * Build a specific full screen error for unavailable fetch endpoint request
 */
function UnavailableApi() {
    const {instance} = useMsal();

    const reload = () => {
        window.location.reload()
    }
    const logout = async () => {
        await instance.logoutRedirect();
    }
    return (
        <div className={styles.UnavailableApiFrame}>
            <div>
                <div className={styles.UnavailableApiTitle}>Oups, L'application n'était pas prête ! !</div>
                <div className={styles.UnavailableApiMessage}>
                    <div>Vous rencontrez cette erreur car le service cloud est probablement en train redémarrer.</div>
                    <div>Si ce problème persiste, l'application est sans doute en maintenance.</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                    <button className={styles.UnavailableApiLogoutButton} onClick={reload}>Recharger la page</button>
                    <button className={styles.UnavailableApiLogoutButton} onClick={logout}>Se Déconnecter</button>
                </div>
            </div>
        </div>
    )
}

/**
 * Build a notification according to requested type
 * @param type {ITEM_TYPE} The notification type to build
 * @param data {{
 * type: {ITEM_TYPE},
 * title: {String},
 * message: {String},
 * log: {String}
 * }} datas indicating what's occurring
 */
function Item({type, data}) {
    const dispatch = useDispatch();

    const iconName = {
        'error': 'error',
        'warning': 'warning',
        'information': 'info',
        'success': 'check_circle',
    };

    const dismissItem = () => {
        dispatch(dismissNotification({type, ...data}));
    }

    return (
        <div className={`${styles.NotificationFrame} ${styles[type]}`}>
            <div className={styles.NotificationHead}>
                <span className='material-icons'>{iconName[type]}</span>
                <span className={styles.NotificationTitle}>{data.title}</span>
                <div className={`material-icons ${styles.closeButton}`} onClick={dismissItem}>close</div>
            </div>
            <div className={styles.NotificationMessage}>{data.message}</div>
        </div>
    )
}