
import {useContext, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions, getElement} from "Redux/poolSlice";
import useToggle from "customHook/useToggle";
import {ContainerContext} from "./Container";
import ApiSyncManager from "utilities/ApiSyncManager";
import {createNotification, NOTIFICATION_TYPE} from "Redux/Notification/notificationSlice";
import InputText from "feature/InputText/InputText";
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControl, FormHelperText, FormLabel,
    IconButton,
    Portal,
    Stack, TextField, Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './Modules.module.scss';
import areSameObject from "utilities/areSameObject";

function List({ path }) {
    const list = useSelector(state => getElement(state.pool.reportData, path) || []);
    const orderedList = useMemo(() => {
        return list.map(({id}, index) => {
            return {
                id: id,
                Component : <ListRow key={id} path={[...path, index]}/>
            }
        }).sort((a, b) => a.id - b.id);
    }, [list]);

    return (
        <div className={styles.Table}>
            <AddButton />
            <table style={{width: '100%'}}>
                <tbody>
                    {orderedList.map(({Component}) => Component)}
                </tbody>
            </table>
        </div>
    )
}
export default List;

/**
 * Build a list row with data values
 * @param path {Array<String|Number>} List path inside Redux pool reportData
 */
function ListRow({path}) {
    const dispatch = useDispatch();
    const rowData = useSelector(state => getElement(state.pool.reportData, path) || []);
    const isEditing = useSelector(state => state.pool.isEditing);

    const list = useSelector(state => {
        const list = getElement(state.pool.reportData, path);
        return {
            ...list,
            titleHasChanged: list.parent === null || (list.parent.title !== list.title),
            textHasChanged: list.parent === null || !areSameObject(list.parent.text, list.text)
        }
    });

    const handleTextOnChange = (newValue) => {
        if (isEditing) {
            dispatch(actions.updateReportData({
                path: path,
                component: 'list',
                updates: [
                    {key: 'id', value: rowData.id},
                    {key: 'text', value: newValue},
                ]
            }));
        }
    }

    const setRichText = (newState) => {
        if (isEditing) {
            dispatch(actions.updateReportData({
                path: path,
                component: 'list',
                updates: [
                    {key: 'id', value: rowData.id},
                    {key: 'is_rich_text', value: newState}
                ]
            }));
        }
    }

    return (
        <tr style={{width: '100%'}}>
            <td style={{verticalAlign: 'middle', minWidth: '200px'}}>
                <InputText
                    style={{marginBottom: '24px', padding: '15.5px 0', wordBreak: 'break-word'}}
                    textValue={rowData.title}
                    textValueHasChanged={list.titleHasChanged}
                    isRichText={false}
                    placeholder='Aucun Contenu'
                    withTags
                />
            </td>
            <td
                style={{width: '100%', verticalAlign: 'middle'}}
                colSpan={rowData.progress === null || rowData.progress === '' || isEditing ? 2 : 1}
            >
                <InputText
                    inputStyle={{wordBreak: 'break-word'}}
                    textValue={rowData.text}
                    updateTextValueCallback={(newValue) => handleTextOnChange(newValue)}
                    textValueHasChanged={list.textHasChanged}
                    isRichText={rowData.is_rich_text}
                    toggleRichTextModeCallback={() => setRichText(!rowData.is_rich_text)}
                    placeholder='Aucun Contenu'
                    withTags
                />
            </td>
            <ProgressInput path={path}/>
            <ActionColumn path={path}/>
        </tr>
    )
}

/**
 * Build an add list button handling form modal display
 */
function AddButton() {
    const isEditing = useSelector(state => state.pool.isEditing);
    const [isFormDisplay, toggle] = useToggle(false);

    return ( isEditing &&
        <>
            <div className={styles.AddBtn} onClick={toggle}>
                <span className='material-icons'>add</span>
                <span>Ajouter une ligne</span>
            </div>
            {isFormDisplay && <CreateListForm dismissModal={toggle}/>}
        </>
    );
}

/**
 * The list creation form
 * @param dismissModal {Function} Function callback usable to dismiss the form modal
 */
function CreateListForm({dismissModal}) {
    const context = useContext(ContainerContext);
    const dispatch = useDispatch();
    const reportId = useSelector(state => state.pool.reportData.report_details.report_id);
    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(false);
    const [text, setText] = useState(null);
    const [textError, setTextError] = useState(false);
    const [richText, setRichText] = useState(false);

    const handleAddButtonClick = (event) => {
        event.preventDefault();
        const titleError = title === null || title === '';
        const textError = text === null || text === '';
        setTitleError(titleError);
        setTextError(textError);

        if(!titleError && !textError) {
            ApiSyncManager.fetchJson('/list/create', {
                method: 'POST',
                body: {
                    title: title,
                    text: text,
                    is_rich_text: richText,
                    container_id: context.container.id,
                    report_id: reportId
                }
            })
                .then(({id}) => {
                    dispatch(actions.createInReportData({
                        path: [...context.reportDataPath, 'content'],
                        object: {
                            id: id,
                            title: title,
                            text: text,
                            progress: null,
                            is_rich_text: richText,
                            parent: null
                        }
                    }));
                    dismissModal();
                })
                .catch(error => {
                    dispatch(createNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        title: 'Échec de la création',
                        message: 'La ligne de liste n\'a pas pu être créée',
                        log: error.stack.toString()
                    }));
                    dismissModal();
                })
        }
    }

    return (
        <Portal container={window.document.body}>
            <Dialog open={true} maxWidth={'lg'} onClose={dismissModal}>
                <DialogTitle>AJOUTER UNE LIGNE</DialogTitle>
                <DialogContent sx={{minWidth: '1200px'}}>
                    <DialogContentText sx={{fontStyle: 'italic', mx: 4, mb: 2}}>
                        Vous pouvez utiliser les tag dans les champs 'Titre' et 'Description'
                        en utilisant le symbole hashtag (<b>#</b>).
                    </DialogContentText>
                    <Stack direction='row' alignItems='end' gap='10px'>
                        <FormControl sx={{width: '30%', mb: '24px'}}>
                            <FormLabel>
                                <span>Titre*</span>
                                {titleError && <FormHelperText sx={{display: 'inline'}} error>Ce champ est obligatoire</FormHelperText>}
                            </FormLabel>
                            <InputText
                                textValue={title}
                                updateTextValueCallback={(newValue) => setTitle(newValue)}
                                isRichText={false}
                                placeholder='Titre'
                                error={titleError}
                                withTags
                            />
                        </FormControl>
                        <FormControl sx={{flexGrow: 1}}>
                            <FormLabel>
                                <span>Description*</span>
                                {textError && <FormHelperText sx={{display: 'inline'}} error>Ce champ est obligatoire</FormHelperText>}
                            </FormLabel>
                            <InputText
                                textValue={text}
                                updateTextValueCallback={(newValue) => setText(newValue)}
                                isRichText={richText}
                                toggleRichTextModeCallback={() => setRichText(!richText)}
                                placeholder='Description'
                                error={textError}
                                withTags
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={dismissModal}>Annuler</Button>
                    <Button variant='outlined' onClick={handleAddButtonClick}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </Portal>
    )
}

/**
 * Builds user available actions on edit mode
 * @param path {Array<String|Number>} List path inside Redux pool reportData
 */
function ActionColumn({path}) {
    const dispatch = useDispatch();
    const isEditing = useSelector(state => state.pool.isEditing);

    const handleDelete = () => {
        const cpPath = [...path];
        dispatch(actions.deleteFromReportData({
            deleteIndex: cpPath.splice(-1),
            path: cpPath,
            component: 'list'
        }));
    }

    return (
        <td className={styles.ActionsCol} style={{verticalAlign: 'middle', width: '40px'}}>
            {isEditing &&
                <IconButton aria-label="delete-row" onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </td>
    )
}

/**
 * Builds a progress circle
 * @param value {Number} Value of list item progress
 */
function ProgressBar({ value }) {

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', padding: '5px' }}>
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={50}
                    thickness={7}
                    sx={{color: 'var(--blueRp)'}}
                />
                <CircularProgress
                    variant="determinate"
                    value={value}
                    size={50}
                    thickness={7}
                    sx={{position: 'absolute', left: 0}}
                />
                <Box
                    sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

/**
 * Builds a progress input
 * @param path {Array<String|Number>} List path inside Redux pool reportData
 */
function ProgressInput({ path }) {
    const dispatch = useDispatch();
    const rowData = useSelector(state => getElement(state.pool.reportData, path) || []);
    const isEditing = useSelector(state => state.pool.isEditing);
    const handleProgressOnChange = (newValue) => {
        if (isEditing && !isNaN(newValue) && (newValue >= 0 && newValue <= 100)) {
            dispatch(actions.updateReportData({
                path: path,
                component: 'list',
                updates: [
                    {key: 'id', value: rowData.id},
                    {key: 'progress', value: newValue.trim() === '' ? null : newValue},
                ]
            }));
        }
    }

    return (
        <td style={{width: '100px', verticalAlign: 'middle', textAlign: 'center'}}>
            {isEditing
                ?
                <>
                    <TextField
                        variant="outlined"
                        label="%"
                        value={rowData.progress ?? ''}
                        onChange={event => handleProgressOnChange(event.target.value)}
                        sx={{width: '100px'}}
                    />
                </>
                :
                (rowData.progress !== '' && rowData.progress !== null) && <ProgressBar value={parseInt(rowData.progress)}/>
            }
        </td>
    );
}